import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class LocationService {
    getLocation(): void {
        // if (navigator.geolocation) {
        // 	navigator.geolocation.getCurrentPosition((position) => {
        // 		const longitude = position.coords.longitude;
        // 		const latitude = position.coords.latitude;
        // 		this.callApi(longitude, latitude);
        // 	});
        // } else {
        // 	console.error('No support for geolocation');
        // }
    }

    callApi(Longitude: number, Latitude: number) {
        // call api
    }
}
