import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class OtpInputService {
    constructor() {}

    isEmptySting(string: string) {
        return string === '';
    }

    isLastInput(index, limit) {
        return index === limit;
    }

    getElement(index) {
        return document.querySelector<HTMLInputElement>(`#otp-${index}`);
    }
}
