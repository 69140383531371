import { Component } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

import { InternalNotificationService } from '@app/core/services';
import { environment } from '@environments/environment';

@Component({
    selector: 'app-root-mobile-native-connect-modal',
    templateUrl: './mobile-native-connect-modal.component.html',
    styleUrls: ['./mobile-native-connect-modal.component.scss'],
})
export class MobileNativeConnectModalComponent {
    isMobileConnectDialogVisible = false;
    isMobileConnectBottomSheetVisible = false;

    appStoreUri = environment.appStoreUri;
    playStoreUri = environment.playStoreUri;
    deviceType: string;

    constructor(
        private internalNotificationService: InternalNotificationService,
        private deviceService: DeviceDetectorService,
    ) {
        this.internalNotificationService.mobileConnectDialogVisibleChange.subscribe(
            (value) => {
                this.isMobileConnectDialogVisible = value;
            },
        );

        this.internalNotificationService.mobileConnectBottomSheetVisibleChange.subscribe(
            (value) => {
                this.isMobileConnectBottomSheetVisible = value;
            },
        );

        this.deviceType = this.deviceService.getDeviceInfo().os;
    }

    handleCancel(): void {
        this.isMobileConnectDialogVisible = false;
        this.isMobileConnectBottomSheetVisible = false;
    }
}
