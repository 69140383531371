import { Pipe, PipeTransform } from '@angular/core';
import { NavService } from '@app/home/shared/services/nav.service';
import { Observable, map, of } from 'rxjs';

@Pipe({ name: 'parseTagUser' })
export class ParseTagUser implements PipeTransform {
    constructor(private navService: NavService) {}

    transform(rawUrl: string): Observable<string> {
        if (rawUrl.includes('https://review-ty.com/users')) {
            const reg = /(?<=href=")[^"]*/g;
            const paths = reg.exec(rawUrl)[0].split('/');
            const username = paths[paths.length - 1];

            return this.navService.getProfileByUsername(username).pipe(
                map((data) => {
                    if (data) {
                        return rawUrl.replace(
                            reg,
                            `https://review-ty.com/profile/user-profile/${data.id}`,
                        );
                    }
                    return rawUrl;
                }),
            );
        }

        return of(rawUrl);
    }
}
