import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdsActivityHistoriesInsertInput, CreateAdsActivityHistoriesGQL } from '@app/common/generated-types';
import { OpenAppService } from '@app/core/services/open-app.service';
import * as _ from 'lodash';
import { CookieService } from 'ngx-cookie';
import { DeviceDetectorService } from 'ngx-device-detector';
import * as uuid from 'uuid';

@Component({
  selector: 'app-root-ads',
  standalone: true,
  imports: [],
  templateUrl: './ads.component.html',
  styleUrl: './ads.component.scss'
})
export class AdsComponent implements OnInit {
  constructor(private openAppService: OpenAppService, private cookieService: CookieService, private deviceDetectorService: DeviceDetectorService, private route: ActivatedRoute, private createAdsService: CreateAdsActivityHistoriesGQL) {

  }


  ngOnInit(): void {


    // check if user is log-in or 'me' in localstorage is error
    // const rawMeData = window.localStorage.getItem('me');
    // if (!rawMeData || !this.jsonParseable(rawMeData)) {
    //   console.log('Not login or local storage data has error');
    //   window.location.href = '/';
    //   return;
    // }

    let deviceId: string = this.cookieService.get('Device-Token');
    if (!deviceId) {
      const currentDate = new Date();
      const tokenExpireDate = currentDate.setFullYear(
        currentDate.getFullYear() + 2,
      );
      const newDeviceId = uuid.v4();
      deviceId = newDeviceId;
      this.cookieService.put('Device-Token', newDeviceId, {
        expires: new Date(tokenExpireDate),
      });
    }
    this.getLocation(deviceId);

  }

  getLocation(deviceId: string) {
    // fetch data
    fetch('https://ipinfo.io/json', { method: 'GET' }).then(res => res.json()).then(data => {
      const info = { ...data, deviceId: deviceId, time: new Date().toISOString() };

      // const jsonMeData = JSON.parse(window.localStorage.getItem('me'));

      // get device type
      const deviceType = this.deviceDetectorService.deviceType;
      console.log(deviceType);
      // send to api
      const input: AdsActivityHistoriesInsertInput = {
        location: info.city + ', ' + info.country,
        deviceId: info.deviceId,
        ip: info.ip,
        campaignId: null,
        agency: null,
        parameters: {
          deviceType: deviceType
        },
        userId: null
      };

      // check if user is log-in or 'me' in localstorage is error
      const rawMeData = window.localStorage.getItem('me');
      if (rawMeData && this.jsonParseable(rawMeData)) {
        const jsonMeData = JSON.parse(rawMeData);
        input.userId = jsonMeData.id;
      }

      // check params has campaign_id, agency, user_id or parameters
      const queryParams = this.route.snapshot.queryParams;
      if (queryParams.campaign_id) {
        input['campaignId'] = queryParams.campaign_id;
      }
      if (queryParams.agency) {
        input['agency'] = queryParams.agency;
      }
      if (!_.isEmpty(queryParams)) {
        input.parameters = {
          ...input.parameters,
          ...queryParams
        };
      }
      // call api
      this.createAdsService.mutate({ data: input }).subscribe(
        (res) => {
          if (res.data.createAdsActivityHistories === true) {
            console.log('Insert history successfully');
            const isIosMobile = ['iPhone', 'iPad'].includes(this.deviceDetectorService.getDeviceInfo().device);
            if (!isIosMobile) {
              console.log('Not mobile device or iOS device');
              window.location.href = '/';
              return;
            }

            // mo app 
            // window.open('com.review-ty.reviewty://', '_self');

            this.openAppService.openApp();

            setTimeout(() => {
              window.location.href = '/';
            }, 10 * 1000);

          }
        }
      );
    }).catch(err => {
      console.log(err);
    });
  }

  jsonParseable(input: string) {
    try {
      JSON.parse(input);
      return true;
    } catch (error) {
      return false;
    }
  }
}
