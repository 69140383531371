import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs';

import { GraphQLResponse } from '@app/core/services';
import {
    CartProductInsertInput,
    CartProductUpdateInput,
    CartProductWhereInput,
    CartProductsOfShopQuery,
    CreateCartProductV2Mutation,
    DeleteCartProductMutation,
    TotalCartProductsOfShopQuery,
    UpdateCartProductMutation,
} from '@app/common/generated-types';
import {
    ADD_PRODUCT_TO_CART,
    DELETE_CART_PRODUCT,
    GET_CART_PRODUCTS_BY_SHOPS,
    GET_TOTAL_CART_PRODUCTS_BY_SHOPS,
    UPDATE_CART_PRODUCT,
} from '../gql-queries/cart';

@Injectable({
    providedIn: 'root',
})
export class CartService {
    constructor(private apollo: Apollo) {}

    getCartProductsByShops() {
        return this.apollo
            .query<
                GraphQLResponse<
                    'cartProductsOfShop',
                    CartProductsOfShopQuery['cartProductsOfShop']
                >
            >({
                query: GET_CART_PRODUCTS_BY_SHOPS,
                fetchPolicy: 'no-cache',
            })
            .pipe(map((response) => response.data.cartProductsOfShop));
    }

    getTotalProductsInCart() {
        return this.apollo
            .query<
                GraphQLResponse<
                    'cartProductsOfShopConnection',
                    TotalCartProductsOfShopQuery['cartProductsOfShopConnection']
                >
            >({
                query: GET_TOTAL_CART_PRODUCTS_BY_SHOPS,
                fetchPolicy: 'no-cache',
            })
            .pipe(
                map(
                    (response) =>
                        response.data?.cartProductsOfShopConnection?.aggregate
                            ?.countProductsOfCart,
                ),
            );
    }

    addProductToCard(data: CartProductInsertInput) {
        return this.apollo
            .mutate<
                GraphQLResponse<
                    'createCartProductV2',
                    CreateCartProductV2Mutation['createCartProductV2']
                >
            >({
                mutation: ADD_PRODUCT_TO_CART,
                variables: { data },
            })
            .pipe(map((response) => response.data.createCartProductV2));
    }

    updateCartProduct(params: {
        where: CartProductWhereInput;
        data: CartProductUpdateInput;
    }) {
        return this.apollo
            .mutate<
                GraphQLResponse<
                    'updateCartProduct',
                    UpdateCartProductMutation['updateCartProduct']
                >
            >({
                mutation: UPDATE_CART_PRODUCT,
                variables: { where: params.where, data: params.data },
            })
            .pipe(map((response) => response.data.updateCartProduct));
    }

    deleteCartProduct(where: CartProductWhereInput) {
        return this.apollo
            .mutate<
                GraphQLResponse<
                    'deleteCartProduct',
                    DeleteCartProductMutation['deleteCartProduct']
                >
            >({
                mutation: DELETE_CART_PRODUCT,
                variables: { where },
            })
            .pipe(map((response) => response.data.deleteCartProduct));
    }
}
