import { Component, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

import { InternalNotificationService } from '@app/core/services';

@Component({
    selector: 'app-root-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
    @Input() isSearchBarEnable = true;

    isMobile: boolean;

    // For vertical scroll detect
    isShowNavBar = true;
    currentPosition = 0;

    @HostListener('window:scroll')
    checkScroll() {
        /*
      Check if user is scrolling
    */

        const scrollPosition =
            window.scrollY ||
            document?.documentElement?.scrollTop ||
            document.body.scrollTop ||
            0;

        if (scrollPosition > 114 && scrollPosition > this.currentPosition) {
            this.isShowNavBar = false;
        } else {
            this.isShowNavBar = true;
        }
        this.currentPosition = scrollPosition;
    }

    constructor(
        private internalNotificationService: InternalNotificationService,
        private router: Router,
        private deviceService: DeviceDetectorService,
    ) {
        this.isMobile = this.deviceService.isMobile();
    }

    showMobileNativeConnectModal() {
        this.internalNotificationService.showMobileConnectDialog(true);
    }
}
