import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie';

import { environment } from '@environments/environment';

@Injectable({
    providedIn: 'root',
})
export class UuidInterceptor implements HttpInterceptor {
    constructor(private cookieService: CookieService) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        const isApiUrl = request.url.startsWith(environment.graphqlUri);
        if (isApiUrl) {
            request = request.clone({
                setHeaders: {
                    'Device-Token': this.cookieService.get('Device-Token'),
                },
            });
        }

        return next.handle(request);
    }
}
