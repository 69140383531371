<div
	class="px-3 py-1 d-flex items-center badge-wrapper {{
		generateCurrentLevelName(currentLevel)
	}}"
	*ngIf="currentLevel"
>
	<div class="d-flex items-center mr-2">
		<svg
			data-name="crown / tier"
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 0 16 16"
		>
			<path
				data-name="Path 19"
				d="M8 0a8 8 0 1 0 8 8 8.009 8.009 0 0 0-8-8zm4.659 6.7-.725 4.222a.5.5 0 0 1-.493.415H4.558a.5.5 0 0 1-.493-.415L3.341 6.7a.5.5 0 0 1 .712-.534l1.929.934 1.581-2.843a.52.52 0 0 1 .874 0L10.019 7.1l1.929-.939a.5.5 0 0 1 .711.534z"
				style="fill: #fff"
			/>
		</svg>
	</div>

	<div class="badge-wrapper-text">
		{{ 'profile.level.' + generateCurrentLevelName(currentLevel) | translate }}
	</div>
</div>
