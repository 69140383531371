import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[appRootDragScrollDirective]',
})
export class DragScrollDirectiveDirective {
    private isDragging: boolean = false;
    private startX: number = 0;
    private startY: number = 0;

    constructor(private el: ElementRef) {}

    @HostListener('mousedown', ['$event'])
    onMouseDown(event: MouseEvent) {
        this.isDragging = true;
        this.startX = event.clientX - this.el.nativeElement.scrollLeft;
        this.startY = event.clientY + this.el.nativeElement.scrollTop;
        this.el.nativeElement.style.cursor = 'grabbing';
    }

    @HostListener('mousemove', ['$event'])
    onMouseMove(event: MouseEvent) {
        if (!this.isDragging) return;
        event.preventDefault();

        const x = event.clientX - this.startX;
        const y = this.startY - event.clientY;

        this.el.nativeElement.scrollLeft = x;
        this.el.nativeElement.scrollTop = y;
    }

    @HostListener('mouseup', ['$event'])
    onMouseUp(event: MouseEvent) {
        this.el.nativeElement.style.cursor = 'grab';

        this.isDragging = false;
    }
}
