import { Injectable, Injector, NgZone } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InternalNotificationService {
    modalService: NzModalService;

    mobileConnectDialogVisibleChange: Subject<boolean> = new Subject<boolean>();
    mobileConnectBottomSheetVisibleChange: Subject<boolean> =
        new Subject<boolean>();
    mobileConnectNotificationBoxVisibleChange: Subject<boolean> =
        new Subject<boolean>();

    constructor(
        private zone: NgZone,
        private injector: Injector,
    ) {
        this.modalService = this.injector.get(NzModalService);
    }

    setIsMobileUserIgnoreNotification(isLoaded: boolean) {
        sessionStorage.setItem(
            'isMobileUserIgnoreNotification',
            isLoaded.toString(),
        );
    }

    getIsMobileUserIgnoreNotification() {
        if (sessionStorage.getItem('isMobileUserIgnoreNotification') === null) {
            this.setIsMobileUserIgnoreNotification(false);
            return false;
        }
        return (
            sessionStorage.getItem('isMobileUserIgnoreNotification') === 'true'
        );
    }

    showSuccess(message: string): void {
        // this.zone.run(() => {
        // 	this.modalService.success({
        // 		nzTitle: 'Success!',
        // 		nzContent: message,
        // 	});
        // });
    }

    showError(message: string): void {
        // this.zone.run(() => {
        // 	this.modalService.success({
        // 		nzTitle: 'Oops...',
        // 		nzContent: message,
        // 	});
        // });
    }

    showMobileConnectDialog(isVisible: boolean) {
        // this.mobileConnectDialogVisibleChange.next(isVisible);
    }

    showMobileConnectBottomSheet(isVisible: boolean) {
        this.mobileConnectBottomSheetVisibleChange.next(isVisible);
    }

    showMobileConnectNotificationBox(isVisible: boolean) {
        this.mobileConnectNotificationBoxVisibleChange.next(isVisible);
    }
}
