<div class="rt-m-t">
  <div class="topnav">
    <button (click)="goBack()">
      <img src="assets/icons/back.svg" alt="Back to previous page">
    </button>
  </div>
</div>

<div class="rt-dt">
  <div class="topnav"></div>
</div>
