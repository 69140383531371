import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ValidationMsgService {
    public getValidationMsg(validationId: string): string {
        return this.errorMessages[
            validationId as keyof typeof this.errorMessages
        ];
    }

    private errorMessages = {
        'example-required-msg': commonMsg.required,
        'example-minlength-msg': minlengthMsg(8),
        'example-maxlength-msg': maxlengthMsg(50),
        'example-pattern-msg': commonMsg.pattern,
        'fullname-required-msg': commonMsg.required,
        'phoneNumber-pattern-msg': commonMsg.phoneNumberPattern,
        'phoneNumber-required-msg': commonMsg.required,
    };
}

const commonMsg = {
    required: 'Không được để trống',
    pattern: 'Không đúng định dạng',
    phoneNumberPattern: 'Số điện thoại không hợp lệ',
};

const minlengthMsg = (num: number) => `Phải có tối thiểu ${num} ký tự`;
const maxlengthMsg = (num: number) => `Chỉ được tối đa ${num} kí tự`;
