<section class="video-wrapper">
    <!-- longnt -->
    <div
        *ngIf="isPlayOverlayVisible && !isShowLoading && isMuted"
        class="unmute-toggle"
        (click)="$event.preventDefault(); toggleSound()"
    >
        <img src="assets/icons/mute.svg" alt="Post mute button" />
        <div class="circles">
            <div class="circle1"></div>
            <div class="circle2"></div>
            <div class="circle3"></div>
        </div>
    </div>
    <!-- end longnt -->
    <div class="ping" *ngIf="showPing">
        <div
            [ngStyle]="{
                width: '10px',
                height: '10px',
                'border-radius': '50%',
                'background-color':
                    ping >= 50 ? (ping <= 100 ? 'yellow' : 'red') : 'green'
            }"
        ></div>
        <p style="margin-left: 5px; font-size: 10px">{{ ping }}</p>
    </div>
    <video id="{{ playerId }}" [ngStyle]="videoStyle" playsinline></video>
    <span class="loader {{ !isShowLoading && 'd-none' }}"></span>
    <div
        class="play-overlay {{ !isPlayOverlayVisible && 'd-none' }}"
        (click)="togglePlay()"
    ></div>
    <button class="video-btn mute-btn d-none" (click)="toggleSound()">
        <span *ngIf="isMuted">
            <img src="assets/icons/mute.svg" alt="Post mute button" />
        </span>
        <span *ngIf="!isMuted">
            <img src="assets/icons/unmute.svg" alt="Post mute button" />
        </span>
    </button>
</section>

<!-- <section class="video-wrapper">
  <video id="{{playerId}}" class="video-js vjs-default-skin" playsInline autoPlay muted>
    <source [src]="videoUrl" type="application/x-mpegURL" />
  </video>
  <span class="loader {{!isShowLoading && 'd-none'}}"></span>
  <div class="play-overlay {{!isPlayOverlayVisible && 'd-none'}}" (click)="togglePlay()"></div>
  <button class="video-btn mute-btn {{!isMuteBtnVisible && 'd-none'}}" (click)="toggleSound()">
    <img src="assets/icons/{{isMuted ? 'mute' : 'unmute'}}.svg" alt="Post mute button">
  </button>
</section> -->
