import { Component } from '@angular/core';

import {
    BackgroundTask,
    BackgroundTaskService,
} from '@app/core/services/background-task.service';

@Component({
    selector: 'app-root-background-task',
    templateUrl: './background-task.component.html',
    styleUrl: './background-task.component.scss',
})
export class BackgroundTaskComponent {
    backgroundTasks: BackgroundTask[];

    constructor(private backgroundTaskService: BackgroundTaskService) {
        this.backgroundTaskService.backgroundTasksubject.subscribe((data) => {
            this.backgroundTasks = data;

            // Run task
            data.map((item) => {
                if (item.state === 'PENDING') {
                    this.backgroundTaskService.changeTaskState(
                        item.key,
                        'RUNNING',
                    );

                    item.task.subscribe({
                        next: () => {
                            this.backgroundTaskService.changeTaskState(
                                item.key,
                                'COMPLETE',
                            );
                            setTimeout(() => {
                                this.backgroundTaskService.deleteTask(item.key);
                            }, 2000);
                        },
                        error: () => {
                            this.backgroundTaskService.changeTaskState(
                                item.key,
                                'ERROR',
                            );
                            setTimeout(() => {
                                this.backgroundTaskService.deleteTask(item.key);
                            }, 2000);
                        },
                    });
                }
            });
        });
    }
}
