import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-root-review-rate',

    templateUrl: './review-rate.component.html',
    styleUrl: './review-rate.component.scss',
})
export class ReviewRateComponent {
    renderFilterStatus;
    @Input() isQuickRating: boolean;
    constructor() {
        this.renderFilterStatus = [
            {
                label: 'product.rateWorst',

                iconUrl: 'assets/icons/state-bad.svg',
                color: '#DB62BA',
            },
            {
                label: 'product.rateBad',

                iconUrl: 'assets/icons/state-awful.svg',
                color: '#F88B79',
            },

            {
                label: 'product.rateSoso',

                iconUrl: 'assets/icons/state-normal.svg',
                color: '#FFB400',
            },
            {
                label: 'product.rateGood',

                iconUrl: 'assets/icons/state-good.svg',
                color: '#F15A24',
            },

            {
                label: 'product.rateBest',
                iconUrl: 'assets/icons/state-great.svg',
                color: '#D0021B',
            },
        ];
    }
    @Input() rate: number;
}
