<form [formGroup]="searchForm">
  <div class="rt-search" [ngStyle]="{'height': isUseSmallSearchBar ? '36px' : '52px'}">
    <img class="icon" src="assets/icons/explore-search.svg" alt="Search">
    <input
      #inputSearch
      name="searchFormControl"
      formControlName="searchFormControl"
      class="input-field body-regular col-12 col-s-10"
      type="text"
      placeholder="{{'productSearch.searchBarLabel' | translate}}"
      (input)="onTyping()"
      (keydown.enter)="onSubmit()"
      autocomplete="off"
      autocapitalize="none"
      [readonly]="isDisabled"
    >
    <button
      *ngIf="searchForm.controls.searchFormControl.value"
      type="button"
      class="btn-clear-search"
      (click)="onClearSearch()"
    >
      <img src="assets/icons/clear-search.svg" alt="Clear search">
    </button>
  </div>
</form>
