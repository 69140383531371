import { Directive, Input, HostBinding, HostListener } from '@angular/core';

@Directive({
    selector: 'img[appImgFallback]',
})
export class FallbackImgDirective {
    @Input()
    @HostBinding('src')
    src: string;

    @Input() appImgFallback: string;

    @HostListener('error')
    onError() {
        this.src = this.appImgFallback;
    }
}
