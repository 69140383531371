import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DEFAULT_SEO_SOCIAL_SHARE_METADATA } from '../constants/seo';

@Injectable({
    providedIn: 'root',
})
export class SeoService {
    constructor(
        @Inject(DOCUMENT) private _document: Document,
        private metaService: Meta,
        private titleService: Title,
    ) {}

    /**
     * Set JSON-LD Microdata on the Document Body.
     *
     * @param renderer2             The Angular Renderer
     * @param data                  The data for the JSON-LD script
     * {
     *   "@context": "https://schema.org"
     *   your schema.org microdata goes here
     * }
     * @returns                     Void
     */
    setJsonLd(renderer2: Renderer2, data: any) {
        const script = renderer2.createElement('script');
        script['data-id'] = data['@type'];

        script.type = 'application/ld+json';
        script.text = `${JSON.stringify(data)}`;

        renderer2.appendChild(this._document.body, script);
    }

    clearJsonLdBySchemaType(schemaType: string) {
        const scripts = document.getElementsByTagName('script');

        for (let i = 0; i < scripts.length; i++) {
            if (scripts[i].getAttribute('type') === 'application/ld+json') {
                if (JSON.parse(scripts[i].text)['@type'] === schemaType) {
                    scripts[i].remove();
                }
            }
        }
    }

    setMeta(data: { title: string; description: string }) {
        this.titleService.setTitle(data.title);

        this.metaService.addTags([
            { name: 'title', content: data.title },
            { name: 'description', content: data.description },

            // Twitter
            { name: 'twitter:card', content: 'summary_large_image' },
            { name: 'twitter:title', content: data.title },
            { name: 'twitter:description', content: data.description },
            {
                name: 'twitter:image',
                content:
                    'https://d1ip8wajnedch4.cloudfront.net/2021/04/07/efc8136c-aaec-4d0d-9068-e2fcb2b0b883.png',
            },

            // Facebook
            { name: 'og:type', content: 'website' },
            { name: 'og:title', content: data.title },
            { name: 'og:description', content: data.description },
            {
                name: 'og:image',
                content:
                    'https://d1ip8wajnedch4.cloudfront.net/2021/04/07/efc8136c-aaec-4d0d-9068-e2fcb2b0b883.png',
            },
        ]);
    }

    setDefaultMeta() {
        this.titleService.setTitle(DEFAULT_SEO_SOCIAL_SHARE_METADATA.title);

        this.setMeta(DEFAULT_SEO_SOCIAL_SHARE_METADATA);
    }

    updateMeta(data: { title: string; description: string }) {
        this.titleService.setTitle(data.title);

        this.metaService.updateTag(
            { name: 'title', content: data.title },
            'name=title',
        );
        this.metaService.updateTag(
            { name: 'description', content: data.description },
            'name=description',
        );

        // Twitter
        this.metaService.updateTag(
            {
                name: 'twitter:title',
                content: data.title,
            },
            "name='twitter:title'",
        );
        this.metaService.updateTag(
            { name: 'twitter:description', content: data.description },
            "name='twitter:description'",
        );

        // Facebook
        this.metaService.updateTag(
            { name: 'og:title', content: data.title },
            "name='og:title'",
        );
        this.metaService.updateTag(
            { name: 'og:description', content: data.description },
            "name='og:description'",
        );
    }
}
