import { Pipe, PipeTransform } from '@angular/core';
import { NavService } from '@app/home/shared/services/nav.service';
import { Observable, map, of } from 'rxjs';

@Pipe({ name: 'parseUrl' })
export class ParseUrlPipe implements PipeTransform {
    constructor(private navService: NavService) {}

    transform(rawUrl: string): Observable<string> {
        if (rawUrl.includes('https://review-ty.com')) {
            if (rawUrl.includes('posts')) {
                const paths = rawUrl.split('/');
                const postId = paths[paths.length - 1];

                return of(
                    `https://review-ty.com/post/${postId}/view?contentType=POST`,
                );
            }

            if (rawUrl.includes('shops')) {
                const paths = rawUrl.split('/');
                const shopId = parseInt(
                    paths[paths.length - 1].replace(/\D/g, ''),
                );

                // return this.navService.getShopById(shopId).pipe(
                //     map((data) => {
                //         if (data) {
                //             return `https://review-ty.com/profile/user-profile/${data.user.id}`;
                //         }
                //         return 'https://review-ty.com';
                //     }),
                // );

                return of(
                    `https://review-ty.com/profile/user-profile/${shopId}`,
                );
            }

            if (rawUrl.includes('users')) {
                const paths = rawUrl.split('/');
                const username = paths[paths.length - 1];

                return this.navService.getProfileByUsername(username).pipe(
                    map((data) => {
                        if (data) {
                            return `https://review-ty.com/profile/user-profile/${data.id}`;
                        }
                        return 'https://review-ty.com';
                    }),
                );
            }

            if (rawUrl.includes('products')) {
                const paths = rawUrl.split('/');
                const productId = parseInt(
                    paths[paths.length - 1].replace(/\D/g, ''),
                );

                return of(`https://review-ty.com/product/${productId}/view`);
            }

            if (rawUrl.includes('product_store')) {
                const paths = rawUrl.split('/');
                const productId = parseInt(
                    paths[paths.length - 1].replace(/\D/g, ''),
                );

                return of(
                    `https://review-ty.com/shop-product/${productId}/view`,
                );
            }
        }
        return of(rawUrl);
    }
}
