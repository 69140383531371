import { ElementRef, HostListener, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dragScrollDirective',
})
export class DragScrollDirectivePipe implements PipeTransform {
    transform(value: any, ...args: any[]) {}
    private isDragging: boolean = false;
    private startX: number = 0;
    private startY: number = 0;

    constructor(private el: ElementRef) {}

    @HostListener('mousedown', ['$event'])
    onMouseDown(event: MouseEvent) {
        this.isDragging = true;
        this.startX = event.clientX - this.el.nativeElement.scrollLeft;
        this.startY = event.clientY - this.el.nativeElement.scrollTop;
    }

    @HostListener('mousemove', ['$event'])
    onMouseMove(event: MouseEvent) {
        if (!this.isDragging) return;
        event.preventDefault();

        const x = event.clientX - this.startX;
        const y = event.clientY - this.startY;

        this.el.nativeElement.scrollLeft = x;
        this.el.nativeElement.scrollTop = y;
    }

    @HostListener('mouseup', ['$event'])
    onMouseUp(event: MouseEvent) {
        this.isDragging = false;
    }
}
