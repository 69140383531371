<div class="p-4 bg-white edit-price-product-wrapper">
	<div class="d-flex flex-col justify-between">
		<form nz-form [nzLayout]="'vertical'" [formGroup]="formEditSalePrice">
			<div *ngIf="product?.id">
				<div nz-row [nzGutter]="[15, 30]">
					<div nz-col nzSpan="4">
						<img src="{{ product?.shopProduct?.images?.urls[0]?.url }}" />
					</div>
					<div nz-col nzSpan="20" class="d-flex items-center">
						<div>
							<p class="text-404040 text-4">{{ product?.shopProduct.name }}</p>
						</div>
					</div>
				</div>

				<div class="pt-5">
					<nz-form-item>
						<nz-form-label>
							<label class="text-9b9b9b">
								{{ 'live.live_selling_price' | translate }}
							</label>
						</nz-form-label>
						<nz-form-control nzErrorTip="{{ 'common.required' | translate }}">
							<input
								type="number"
								inputmode="numeric"
								formControlName="salePrice"
								nz-input
								placeholder="Nhập giá bán"
							/>
						</nz-form-control>
					</nz-form-item>
				</div>
				<div>
					<nz-form-item>
						<nz-form-label>
							<label class="text-9b9b9b">
								{{ 'common.original_price' | translate }}
							</label>
						</nz-form-label>
						<nz-form-control nzErrorTip="{{ 'common.required' | translate }}">
							<input
								type="number"
								inputmode="numeric"
								formControlName="currentPrice"
								nz-input
							/>
						</nz-form-control>
					</nz-form-item>
				</div>

				<div>
					<nz-form-item>
						<nz-form-label>
							<label class="text-9b9b9b"
								>{{ 'common.number_of_products_you_want_to_sell' | translate }}
							</label>
						</nz-form-label>
						<nz-form-control>
							<input
								type="number"
								inputmode="numeric"
								formControlName="numOfProductSale"
								nz-input
							/>
						</nz-form-control>

						<p>
							{{ 'live.quantityLeft' | translate }}
							{{ product?.shopProduct?.totalQuantity }}
						</p>
					</nz-form-item>
				</div>
			</div>
		</form>

		<div>
			<button (click)="handleSubmit()" class="btn btn-rectangle__black">
				{{ 'common.save' | translate }}
			</button>
		</div>
	</div>
</div>
