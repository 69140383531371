<div
    *ngIf="dataCreateLiveStreamer?.id"
    #no_data
    class="live-stream-detail-by-user-wrapper"
>
    <ng-container *ngIf="dataCreateLiveStreamer.id">
        <app-root-live-video-wrapper
            class="post-video-wrapper {{ !isShowVideo && 'hide' }}"
            [videoUrl]="dataCreateLiveStreamer.liveUrl"
            [width]="playerWidth"
            [height]="playerHeight"
            [isPlayOverlayVisible]="true"
            (playerReadyEvent)="onPlayerReady($event)"
            (refreshProductEvent)="onRefreshProduct()"
        ></app-root-live-video-wrapper>
    </ng-container>
    <div class="container-wrapper">
        <div class="video-container bg-131518"></div>
        <div class="absolute t-0 text-white card-3 w-full">
            <div class="d-flex justify-between items-start w-full pb-2">
                <div class="show-description" (click)="toggleShowDescription()">
                    <span>{{ dataCreateLiveStreamer.name }}</span>
                    <img
                        class="ml-2 arrow-down"
                        src="assets/icons/live-stream/arrow-down-select-white.svg"
                    />
                    <div
                        class="content {{ isShowDescription ? 'active' : '' }}"
                    >
                        {{ dataCreateLiveStreamer.description }}
                    </div>
                </div>
                <div (click)="handleOffLiveStream()">
                    <img src="/assets/icons/live-stream/off-stream.svg" />
                </div>
            </div>
            <div class="d-flex justify-between pt-2 items-center">
                <div
                    class="d-flex items-center cursor-pointer"
                    [routerLink]="[
                        '/profile/user-profile',
                        dataCreateLiveStreamer?.host?.id
                    ]"
                >
                    <div class="">
                        <img
                            class="rounded-full"
                            width="40"
                            height="40"
                            [appImgFallback]="
                                '/assets/images/fallback-avatar-img.png'
                            "
                            src="{{
                                dataCreateLiveStreamer?.host?.avatar?.url
                            }}"
                        />
                    </div>

                    <div class="pl-2">
                        {{ dataCreateLiveStreamer?.host?.shop?.name }}
                    </div>
                </div>
                <div
                    class="py-1 px-2 d-flex items-center"
                    style="
                        border-radius: 60px;
                        background: rgba(19, 21, 24, 0.5);
                    "
                >
                    <img
                        class="mr-2"
                        src="assets/icons/live-stream/view-white.svg"
                        alt="Post view count"
                    />
                    <span>
                        {{ dataCreateLiveStreamer?.viewerCount || 0 }}
                    </span>
                </div>
            </div>
        </div>
        <div class="absolute bottom-0 text-white w-full card-3">
            <app-root-live-chat
                [live]="dataCreateLiveStreamer"
            ></app-root-live-chat>
            <div *ngIf="isShowPinned && productPinned">
                <app-root-product-live-stream-card
                    [type]="'pin'"
                    [productPinned]="[productPinned]"
                    (toggleShowProductPinned)="handleClosePinnedProduct()"
                >
                </app-root-product-live-stream-card>
            </div>

            <div class="pt-1 d-flex justify-between items-center">
                <div
                    class="pr-3 relative py-2 cursor-pointer"
                    (click)="toggleShowProductLive()"
                >
                    <img src="assets/icons/live-stream/cart.svg" />
                    <span
                        class="absolute text-ba92d9 text-3 cart-product-number"
                        >{{
                            dataCreateLiveStreamer.nbOfLiveStreamProducts
                        }}</span
                    >
                </div>
                <div class="w-full"></div>

                <div class="pl-3 wrapper-go-live-btn cursor-pointer relative">
                    <button
                        [disabled]="isLoadingGoLive"
                        class="go-live-btn"
                        *ngIf="dataCreateLiveStreamer.status === 'CREATED'"
                        (click)="goLive()"
                    >
                        Go Live
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #no_data>
    <div
        style="min-height: 30vh"
        class="d-flex items-center justify-center flex-col"
    >
        <img
            class="icon"
            src="assets/icons/empty-section.svg"
            alt="No results"
        />
        <p class="text-9b9b9b">Oops!</p>
        <p class="text-9b9b9b">{{ 'common.no_reviews' | translate }}</p>
    </div>
</ng-template>

<nz-drawer
    nzWrapClassName="drawer-full-height"
    [nzClosable]="false"
    [nzVisible]="isShowProductLive"
    [nzPlacement]="'bottom'"
    (nzOnClose)="close()"
>
    <ng-container *nzDrawerContent>
        <div class="bg-white drawer-content-wrapper">
            <div class="relative">
                <div
                    class="p-2 text-left text-131518 font-semibold"
                    style="font-size: 18px"
                >
                    {{ 'profile.product-live-list' | translate }}
                </div>

                <div class="absolute d-flex" *ngIf="role === typeRole.VIEWER">
                    <img
                        src="/assets/icons/live-stream/voucher.svg"
                        class="mr-2"
                    />
                    <div class="relative">
                        <img src="/assets/icons/live-stream/cart-black.svg" />
                        <div class="text-3 cart-count">
                            {{ totalProductsInCart }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="p-3 scroll-content" cdkScrollable>
                <div>
                    <div nz-row [nzGutter]="[15, 0]" class="">
                        <div nz-col nzSpan="8" *ngFor="let menu of drawerMenu">
                            <div
                                style="min-height: 100px"
                                class="p-3 text-center d-flex flex-col items-center justify-center"
                                [routerLink]="[menu.routerLink]"
                                [state]="
                                    menu.routerLink === 'product-management'
                                        ? {
                                              currentLiveStream: {
                                                  role,
                                                  dataCreateLiveStreamer
                                              }
                                          }
                                        : {}
                                "
                            >
                                <div>
                                    <img src="{{ menu.src }}" />
                                </div>
                                <div class="text-131518">
                                    {{ menu.text | translate }}
                                </div>
                            </div>
                        </div>

                        <div class="w-full">
                            <app-root-product-live-stream-card
                                *ngIf="
                                    dataCreateLiveStreamer?.liveStreamProducts
                                "
                                [type]="'pick'"
                                [role]="role"
                                [productPinned]="
                                    dataCreateLiveStreamer?.liveStreamProducts
                                "
                                (toggleShowProductPinned)="
                                    handleClosePinnedProduct()
                                "
                                (addProductToCartEvent)="
                                    addProductToCart($event)
                                "
                                (handleDeleteProductEvent)="
                                    handleDeleteProduct($event)
                                "
                                (handlePinProductEvent)="
                                    handlePinProduct($event)
                                "
                                [dataCreateLiveStreamer]="
                                    dataCreateLiveStreamer
                                "
                                (successEditPrice)="handleSuccessEditPrice()"
                                (changePositionSuccess)="
                                    changePositionSuccess($event)
                                "
                            ></app-root-product-live-stream-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</nz-drawer>

<div
    class="custom-modal"
    appRootClickOutside
    *ngIf="
        isShowModalLinkLiveStream &&
        dataCreateLiveStreamer?.status === 'CREATED'
    "
    (clickOutside)="close()"
>
    <label class="text-131518 font-semibold" style="font-size: 18px"
        >Link livestream</label
    >
    <p class="text-9b9b9b pt-1 pb-4">
        {{ 'common.link_description' | translate }}
    </p>

    <nz-input-group [nzSuffix]="suffixTemplate">
        <input nz-input [disabled]="true" [value]="streamUrl" #linkLiveStream />
        <ng-template #suffixTemplate>
            <img
                (click)="copyInputMessage(linkLiveStream)"
                src="assets/icons/live-stream/copy.svg"
            />
        </ng-template>
    </nz-input-group>

    <div class="pt-4">
        <nz-input-group [nzSuffix]="suffixTemplate2">
            <input nz-input [disabled]="true" [value]="roomKey" #roomId />
            <ng-template #suffixTemplate2>
                <img
                    (click)="copyInputMessage(roomId)"
                    src="assets/icons/live-stream/copy.svg"
                />
            </ng-template>
        </nz-input-group>
    </div>

    <div class="pt-6">
        <button class="cursor-pointer" (click)="close()">
            {{ 'common.close' | translate }}
        </button>
    </div>
</div>

<div class="loading-modal" *ngIf="isLoadingGoLive">
    <img src="assets/icons/live-stream/loading.svg" />
    <label>{{ 'common.starting' | translate }}</label>

    <span>{{ 'common.do_you_have_pretty_makeup_yet' | translate }}</span>

    <span>
        <button (click)="handleCancel()">
            {{ 'common.cancel' | translate }}
        </button>
    </span>
</div>
