import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-root-read-more',

    templateUrl: './read-more.component.html',
    styleUrl: './read-more.component.scss',
    styles: [
        `
            div.collapsed {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }
        `,
    ],
})
export class ReadMoreComponent implements OnInit {
    isCollapsed = true;
    showReadMore = false;

    ngOnInit() {
        this.toggleContentVisibility();
    }

    toggleContent() {
        this.isCollapsed = !this.isCollapsed;
    }

    toggleContentVisibility() {
        // Determine if content should be hidden based on screen size
        const contentElement = document.querySelector('.content');
        if (contentElement) {
            const contentHeight = contentElement.clientHeight;

            this.showReadMore = contentHeight > 4 * 22; // Assuming each line height is 22px
        }
    }
}
