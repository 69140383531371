<div class="main-content-wrapper signup-wrapper tab-item">
    <div class="main-content">
        <a class="top-nav" (click)="handleBack()">
            <img
                width="30"
                height="30"
                class="btn-back"
                src="assets/icons/back-to-home.svg"
                alt="Back to home"
            />
        </a>
        <div class="form-content">
            <h1 class="title">Đăng ký</h1>
            <p class="desc">Enter your email and password to sign up!</p>

            <div class="login-with" (click)="facebook()">
                <img
                    src="/assets/icons/facebook-logo.svg"
                    alt="face-book-icon"
                />
                <h3>Đăng nhập với Facebook</h3>
            </div>
            <div class="login-with apple" (click)="apple()">
                <img src="/assets/icons/apple-logo.svg" alt="face-book-icon" />
                <h3>Đăng nhập với Apple ID</h3>
            </div>

            <div class="or">
                <div class="separator"></div>
                <h3 class="title">or</h3>
            </div>

            <nz-alert
                [nzMessage]="error"
                nzType="error"
                [nzShowIcon]="true"
                style="margin-bottom: 24px"
                *ngIf="!submitting && error"
            ></nz-alert>
            <!-- [nzErrorTip]="
            (signupForm.controls['email']?.getError('required')
                ? 'Trường này là bắt buộc nhập'
                : signupForm.controls['email']?.getError('emailAlready')
                ? 'validation.email_already_in_use'
                : ''
            ) | translate
        " -->
            <form
                nz-form
                [formGroup]="signupForm"
                nzLayout="vertical"
                (ngSubmit)="handleSubmit()"
            >
                <div>
                    <nz-form-item>
                        <nz-form-label>Email<span>*</span></nz-form-label>
                        <nz-form-control
                            [nzErrorTip]="
                                validationService.getErrorMessage(
                                    signupForm.get('email')
                                )
                            "
                            [nzValidateStatus]="
                                validationService.getErrorMessage(
                                    signupForm.get('email')
                                ) && !signupForm.get('email').untouched
                                    ? 'error'
                                    : ''
                            "
                        >
                            <input
                                nz-input
                                placeholder="Email"
                                formControlName="email"
                            />
                        </nz-form-control>
                    </nz-form-item>
                </div>

                <div *ngFor="let item of data">
                    <nz-form-item>
                        <nz-form-label nzRequired>{{
                            item.label | translate
                        }}</nz-form-label>
                        <nz-form-control
                            [nzErrorTip]="
                                validationService.getErrorMessage(
                                    signupForm.get(item.value),
                                    item.label | translate
                                )
                            "
                            [nzValidateStatus]="
                                validationService.getErrorMessage(
                                    signupForm.get(item.value),
                                    item.label | translate
                                ) && !signupForm.get(item.value).untouched
                                    ? 'error'
                                    : ''
                            "
                        >
                            <nz-input-group [nzSuffix]="suffixTemplate">
                                <input
                                    [formControlName]="item.value"
                                    [type]="
                                        item.showPassword ? 'text' : 'password'
                                    "
                                    nz-input
                                    [placeholder]="
                                        'Nhập' +
                                        ' ' +
                                        (item.label | translate).toLowerCase()
                                    "
                                />
                            </nz-input-group>
                            <ng-template #suffixTemplate>
                                <span
                                    nz-icon
                                    [nzType]="
                                        item.showPassword
                                            ? 'eye-invisible'
                                            : 'eye'
                                    "
                                    (click)="
                                        item.showPassword = !item.showPassword
                                    "
                                ></span>
                            </ng-template>
                        </nz-form-control>
                    </nz-form-item>
                </div>

                <button class="submit">Đăng ký</button>
            </form>
        </div>

        <p class="d-flex justify-end pt-2 pb-12" (click)="handleBack()">
            Bạn đã có tài khoản ?&nbsp;<a class="underline">Đăng nhập ngay</a>
        </p>

        <h3 class="coppyright">© 2023 Reviewty. All Rights Reserved.</h3>
    </div>
    <nz-drawer
        nzWrapClassName=""
        className="{{ isToggleSignup ? 'drawer-open' : 'drawer-close' }}"
        [nzClosable]="false"
        [nzVisible]="isShowVerifyEmail"
        [nzPlacement]="'bottom'"
        (nzOnClose)="close()"
        [nzHeight]="'100%'"
        [nzBodyStyle]="{
            'max-width': '600px',
            background: '#fff',
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
            margin: 'auto',
            'border-top-left-radius': '10px',
            'border-top-right-radius': '10px'
        }"
    >
        <ng-container *nzDrawerContent>
            <app-root-verify-account
                [email]="signupForm.get('email').value"
            ></app-root-verify-account>
        </ng-container>
    </nz-drawer>
</div>
