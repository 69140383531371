import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-root-badge',
    templateUrl: './badge.component.html',
    styleUrl: './badge.component.scss',
})
export class BadgeComponent {
    @Input() currentLevel: number;

    generateCurrentLevelName(id) {
        switch (id) {
            case 1:
            case 2:
                // return 'assets/images/badge.png';
                return 'novice';
            case 3:
            case 4:
                return 'advanced_beginner';
            case 5:
            case 6:
                return 'competent';
            case 7:
            case 8:
                return 'proficient';

            default:
                return 'expert';
        }
    }
}
