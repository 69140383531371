import { Component, TemplateRef, ViewChild } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';

import { InternalNotificationService } from '@app/core/services';
import { environment } from '@environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
    selector: 'app-root-mobile-native-connect-notification-box',
    templateUrl: './mobile-native-connect-notification-box.component.html',
    styleUrls: ['./mobile-native-connect-notification-box.component.scss'],
})
export class MobileNativeConnectNotificationBoxComponent {
    @ViewChild(TemplateRef, { static: false }) template?: TemplateRef<any>;

    appStoreUri = environment.appStoreUri;
    playStoreUri = environment.playStoreUri;
    deviceType: string;

    constructor(
        private internalNotificationService: InternalNotificationService,
        private notificationService: NzNotificationService,
        private deviceService: DeviceDetectorService,
    ) {
        this.internalNotificationService.mobileConnectNotificationBoxVisibleChange.subscribe(
            (value) => {
                if (value == true) {
                    this.notificationService.remove();

                    const notification = this.notificationService.template(
                        this.template,
                        {
                            nzPlacement: 'bottom',
                            nzDuration: 0,
                        },
                    );

                    notification.onClose.subscribe(() => {
                        this.internalNotificationService.setIsMobileUserIgnoreNotification(
                            true,
                        );
                    });
                }
            },
        );

        this.deviceType = this.deviceService.getDeviceInfo().os;
    }

    onClick() {
        window.open(
            this.deviceType === 'iOS' ? this.appStoreUri : this.playStoreUri,
            '_blank',
        );
    }
}
