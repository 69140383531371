import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'app-root-view-html-dialog',
    templateUrl: './view-html-dialog.component.html',
    styleUrls: ['./view-html-dialog.component.scss'],
})
export class ViewHtmlDialogComponent implements OnInit {
    renderHtmlEncap: SafeHtml;

    constructor(
        private sanitizer: DomSanitizer,
        @Inject(MAT_DIALOG_DATA) public data: { injectHtmlStr: string },
        public dialogRef: MatDialogRef<ViewHtmlDialogComponent>,
    ) {}

    ngOnInit(): void {
        this.renderHtmlEncap = this.sanitizer.bypassSecurityTrustHtml(
            this.data.injectHtmlStr,
        );
    }

    closeDialog() {
        this.dialogRef.close();
    }
}
