<div
    class="card-3 d-flex flex-col items-center justify-center h-full content"
    style="min-height: 500px"
>
    <div class="setting-header sticky-header d-flex justify-between">
        <div></div>
        <div class="setting-title"></div>
    </div>

    <div class="text-center">
        <div class="d-flex justify-center">
            <img
                src="assets/images/paper_airplane.png"
                width="152"
                height="124"
            />
        </div>

        <div>
            <p class="verify-phonenumber-title pt-4">Xác minh email</p>
            <p class="verify-phonenumber-content">
                Mã xác thực đã được gửi đến email
            </p>
            <p class="verify-phonenumber-content mb-6">{{ email }}</p>

            <div class="d-flex justify-center mb-4">
                <app-root-otp-input
                    (otpOut)="handleOtpOut($event)"
                ></app-root-otp-input>
            </div>

            <p
                class="verify-phonenumber-try-sent pb-8 underline cursor-pointer"
                (click)="tryingToSentOTP()"
            >
                Gửi lại mã ({{ timeRemaining$ | async | date: 'mm:ss' }})
            </p>

            <div>
                <button
                    [disabled]="this.otp?.length !== 6"
                    class="btn btn-confirm py-3 cursor-pointer {{
                        this.otp?.length !== 6 ? 'disabled' : ''
                    }}"
                    (click)="verificationOTP()"
                >
                    Xác nhận
                </button>
            </div>
        </div>
    </div>
</div>

<nz-drawer
    [nzClosable]="false"
    nzWrapClassName="drawer-full-height"
    [nzVisible]="isShowEditInformation"
    [nzPlacement]="'bottom'"
    (nzOnClose)="close()"
>
    <ng-container *nzDrawerContent>
        <app-root-tell-us-about-us></app-root-tell-us-about-us>
    </ng-container>
</nz-drawer>
