import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs';

import { GET_LIVE_LIST, PIN_VOUCHER } from '../gql-queries/live';
import {
    LiveStreamsQuery,
    PinLiveVouchersMutation,
    StopLiveStreamV2GQL,
} from '@app/common/generated-types';
import { GraphQLResponse } from '@app/core/services';

@Injectable({
    providedIn: 'root',
})
export class LiveService {
    constructor(
        private apollo: Apollo,
        private stopLiveStreamV2GQL: StopLiveStreamV2GQL,
    ) {}

    getLiveList(first: number = 2, skip: number = 0) {
        return this.apollo
            .query<LiveStreamsQuery>({
                query: GET_LIVE_LIST,
                variables: {
                    where: {
                        status: 'RUNNING',
                    },
                    orderBy: {
                        id: 'DESC',
                    },
                    first,
                    skip,
                },
                fetchPolicy: 'no-cache',
            })
            .pipe(map((response) => response.data));
    }

    stopLiveStream(liveId: number) {
        return this.stopLiveStreamV2GQL.mutate({
            where: {
                id: liveId,
            },
        });
    }

    pinLiveVouchers(
        liveId: number,
        pinnedVoucherId: number,
        voucherIds: number[],
    ) {
        return this.apollo
            .mutate<
                GraphQLResponse<
                    'livePromotionPins',
                    PinLiveVouchersMutation['livePromotionPins']
                >
            >({
                mutation: PIN_VOUCHER,
                variables: {
                    where: { id: liveId },
                    data: {
                        pinnedPromotionId: pinnedVoucherId,
                        promotionIds: voucherIds,
                    },
                },
            })
            .pipe(map((response) => response.data.livePromotionPins));
    }
}
