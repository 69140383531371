import { Component, Input, ViewEncapsulation } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'app-root-view-html',
    templateUrl: './view-html.component.html',
    styleUrls: ['./view-html.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class ViewHtmlComponent {
    @Input() renderHtmlEncap: SafeHtml;
}
