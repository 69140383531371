import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    GetActivityUserQuery,
    LiveStreamStatus,
    ViewProfileUserQuery,
} from '@app/common/generated-types';
import { RouterService } from '@app/core/services';
import { StoreService } from '@app/core/services/store.service';
import { NzTabChangeEvent } from 'ng-zorro-antd/tabs';
import { map, Subject, takeUntil } from 'rxjs';

export enum ESelectTabLiveStream {
    UPCOMING = 'up-comming',
    PROGRESS = 'progress',
    ENDED = 'ended',
}

@Component({
    selector: 'app-root-live-stream-list',

    templateUrl: './live-stream-list.component.html',
    styleUrl: './live-stream-list.component.scss',
})
export class LiveStreamListComponent implements OnInit, OnDestroy {
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private store: StoreService,
        private routerService: RouterService,
    ) {}

    LiveStreamStatus = LiveStreamStatus;
    activityUser: ViewProfileUserQuery | GetActivityUserQuery;

    ESelectTabLiveStream = ESelectTabLiveStream;

    selectedTab: LiveStreamStatus;
    destroy$: Subject<boolean> = new Subject();
    selectedIndex;
    tabs = [
        LiveStreamStatus.CREATED,
        LiveStreamStatus.RUNNING,
        LiveStreamStatus.STOPPED,
    ];
    ngOnInit() {
        setTimeout(() => {
            this.routerService.triggerMobileTransparentLayoutChange(true);
        }, 1);
        this.route.paramMap
            .pipe(map(() => window.history.state))
            .subscribe((data: ViewProfileUserQuery) => {
                this.activityUser = data;
            });
        if (!this.activityUser?.user) {
            this.store.activeUser$.subscribe((data) => {
                if (data?.user) {
                    this.activityUser = data;
                }
            });
        }
        this.route.queryParamMap
            .pipe(takeUntil(this.destroy$))
            .subscribe((paramMap) => {
                this.selectedIndex = this.tabs.findIndex(
                    (tabString) => tabString === paramMap.get('tab'),
                );
                this.selectedTab =
                    (paramMap.get('tab') as LiveStreamStatus) ||
                    LiveStreamStatus.CREATED;

                // this.handleQueryParamsChange({
                // 	tab: this.selectedTab,
                // });
            });
    }

    ngOnDestroy(): void {
        setTimeout(() => {
            this.routerService.triggerMobileTransparentLayoutChange(false);
        }, 1);
    }

    handleChangeTab(data?: NzTabChangeEvent) {
        this.selectedTab = this.tabs[data?.index || 0];

        this.handleQueryParamsChange({ tab: this.selectedTab });
    }

    handleQueryParamsChange(params: { [key: string]: string }): void {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                tab: params.tab,
            },
            queryParamsHandling: 'merge',
        });
    }

    handleCreate() {}
}
