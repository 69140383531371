import { Injectable } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
    REGEX_PHONE_NUMBER,
    REGEX_PHONE_NUMBER_VERIFY_SHOP,
} from '../helpers/contains';

@Injectable({
    providedIn: 'root',
})
export class ValidationService {
    constructor(private translateService: TranslateService) {}

    getErrorMessage(
        control?: AbstractControl | null,
        controlName?: string,
    ): string {
        if (control && control.errors && control.valueChanges) {
            if (control.errors.passwordMismatch) {
                return this.translateService.instant(
                    'validation.confirm_password_not_match',
                );
            }

            if (control.errors.required) {
                return `${this.translateService.instant('validation.required')}`;
            } else if (
                control.errors.minlength?.actualLength <
                control.errors.minlength?.requiredLength
            ) {
                return `${this.translateService.instant(
                    'validation.minLength',
                    {
                        minLength: control.errors.minlength.requiredLength,
                    },
                )}`;
            } else if (control.errors?.maxlength) {
                return `không quá ${control.errors.maxlength.requiredLength} kí tự.`;
            } else if (control.errors.pattern) {
                if (
                    control.errors.pattern.requiredPattern ==
                        REGEX_PHONE_NUMBER ||
                    control.errors.pattern.requiredPattern ==
                        REGEX_PHONE_NUMBER_VERIFY_SHOP
                ) {
                    return 'Số điện thoại không hợp lệ';
                }
                return 'không hợp lệ, phải ít nhất 1 chữ viết hoa, chữ thường, số, không có khoảng trắng và nằm trong khoảng 8 - 24 ký tự';
            } else if (control.errors.email) {
                return this.translateService.instant(
                    'validation.email_invalid',
                );
            } else if (control.errors.empty) {
                return this.translateService.instant(
                    'validation.not_contains_space_between',
                );
            } else if (control.errors?.emailAlready) {
                return this.translateService.instant(
                    'validation.email_already_in_use',
                );
            } else if (control.errors?.notFoundEmail) {
                return this.translateService.instant(
                    'validation.not_found_email',
                );
            }
        }

        return '';
    }

    noSpaceOrSpecialCharValidator(control: FormControl): {
        ['noSpaceOrSpecialChar']: boolean | null;
    } {
        const value = control.value;
        if (value) {
            // Use a regular expression to check for spaces or special characters
            const regex = /^[a-zA-Z0-9]*$/; // Only letters and numbers are allowed
            if (!regex.test(value)) {
                return { noSpaceOrSpecialChar: true }; // Validation fails
            }
        }
        return null;
    }

    // noSpaceOrSpecialCharValidator((control: AbstractControl): { [key: string]: any | null } | null) {

    // 		const value = control.value;
    // 		if (value) {
    // 			// Use a regular expression to check for spaces or special characters
    // 			const regex = /^[a-zA-Z0-9]*$/; // Only letters and numbers are allowed
    // 			if (!regex.test(value)) {
    // 				return { noSpaceOrSpecialChar: true }; // Validation fails
    // 			}
    // 		}
    // 		return null; // Validation passes

    // }
}
