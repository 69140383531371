import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import slugify from 'slugify';

@Injectable({
    providedIn: 'root',
})
export class RouterService {
    userFirstAccessChange: Subject<boolean> = new Subject<boolean>();
    mobileHeaderLiteChange: Subject<boolean> = new Subject<boolean>();
    mobileTransparentLayoutChange: Subject<boolean> = new Subject<boolean>();
    mobileTransparentHeaderChange: Subject<boolean> = new Subject<boolean>();
    mainContentLoadedChange: Subject<boolean> = new Subject<boolean>();
    iframeLayoutChange: Subject<boolean> = new Subject<boolean>();

    triggerUserFirstAccessChange(isChange: boolean) {
        this.userFirstAccessChange.next(isChange);
    }

    triggerMobileHeaderLiteChange(isChange: boolean) {
        this.mobileHeaderLiteChange.next(isChange);
    }

    triggerMobileTransparentLayoutChange(isChange: boolean) {
        this.mobileTransparentLayoutChange.next(isChange);
    }

    triggerMobileTransparentHeaderChange(isChange: boolean) {
        this.mobileTransparentHeaderChange.next(isChange);
    }

    triggerIframeLayoutChange(isChange: boolean) {
        this.iframeLayoutChange.next(isChange);
    }

    setIsAppFirstLoaded(isLoaded: boolean) {
        sessionStorage.setItem('isAppFirstLoaded', isLoaded.toString());
    }

    getIsAppFirstLoaded() {
        if (sessionStorage.getItem('isAppFirstLoaded') === null) {
            this.setIsAppFirstLoaded(true);
            return true;
        }
        return sessionStorage.getItem('isAppFirstLoaded') === 'true';
    }

    triggerMainContentLoadedChange(isChange: boolean) {
        this.mainContentLoadedChange.next(isChange);
    }

    parseSlug(slug: string) {
        return slugify(slug).replace(/[^\w\s]/gi, '-');
    }
}
