<section class="voucher-select-drawer">
    <div class="drawer-header">
        <dl class="info">
            <dt>Danh sách voucher</dt>
            <dd>
                Nhanh chóng thanh toán đơn hàng để nhận những ưu đãi của shop
            </dd>
        </dl>
    </div>
    <div class="drawer-content">
        <div class="voucher-options">
            <div class="option active" *ngFor="let item of vouchers">
                <div
                    *ngIf="item.type === 'FREESHIP'"
                    class="voucher-mark freeship-mark"
                >
                    <img
                        src="assets/icons/admin-freeship.svg"
                        alt="Voucher icon"
                    />
                </div>
                <div
                    *ngIf="item.type === 'DISCOUNT'"
                    class="voucher-mark discount-mark"
                >
                    <img
                        src="assets/icons/admin-discount.svg"
                        alt="Voucher icon"
                    />
                </div>
                <div class="option-body">
                    <div class="option-leading">
                        <h3 class="title">
                            {{
                                'Giảm' + item.type === 'FREESHIP'
                                    ? (item.maximumPrice | currencyByLocale) +
                                      'phí vận chuyển'
                                    : (item.maximumPrice | currencyByLocale)
                            }}
                        </h3>
                    </div>
                    <div class="option-content">
                        <p>
                            Đơn tối thiểu
                            {{ item.minimumPrice | currencyByLocale }} - Giảm
                            tối đa
                            {{ item.maximumPrice | currencyByLocale }}
                        </p>
                    </div>
                    <div class="option-fag">
                        <p class="expire-date">
                            Hạn sử dụng:
                            <span>{{
                                formatDate(item.toTime, 'DD/MM/YYYY')
                            }}</span>
                        </p>
                        <span class="fag d-none">Điều kiện</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
