<section class="vouchers-controls-drawer">
    <div class="drawer-header">
        <dl class="info">
            <dt>Hiển thị voucher</dt>
        </dl>
        <a
            class="add-voucher-btn"
            [routerLink]="
                '/profile/merchant/live-stream-management/demo/' +
                liveId +
                '/vouchers-live-stream/create'
            "
        >
            <img src="assets/icons/live-add-voucher.svg" alt="Add voucher" />
            <span>Tạo mới</span>
        </a>
    </div>
    <div class="drawer-content">
        <div *ngIf="shopVouchers.length !== 0" class="vouchers-section">
            <h3 class="title">Voucher của Shop</h3>
            <div class="vouchers">
                <div class="voucher-item" *ngFor="let item of shopVouchers">
                    <div
                        *ngIf="item.type === 'FREESHIP'"
                        class="voucher-mark freeship-mark"
                    >
                        <img
                            src="assets/icons/admin-freeship.svg"
                            alt="Voucher icon"
                        />
                    </div>
                    <div
                        *ngIf="item.type === 'DISCOUNT'"
                        class="voucher-mark discount-mark"
                    >
                        <img
                            src="assets/icons/admin-discount.svg"
                            alt="Voucher icon"
                        />
                    </div>
                    <dl class="voucher-info">
                        <dt>
                            <span>
                                {{
                                    'Giảm ' +
                                        (item.type === 'FREESHIP'
                                            ? (item.maximumPrice
                                                  | currencyByLocale) +
                                              ' phí vận chuyển'
                                            : item.amount + '%')
                                }},{{
                                    item.type !== 'FREESHIP'
                                        ? 'tối đa' +
                                          (item.maximumPrice | currencyByLocale)
                                        : ''
                                }}
                                cho đơn tối thiểu
                                {{ item.minimumPrice | currencyByLocale }}
                            </span>
                            <button
                                class="voucher-visibility-btn {{
                                    !item.pinOnLivestream && 'active'
                                }}"
                                (click)="!isLoading && pinVoucher(item.id)"
                            >
                                <span>{{
                                    !item.pinOnLivestream
                                        ? 'Hiển thị'
                                        : 'Bỏ hiển thị'
                                }}</span>
                            </button>
                        </dt>
                        <dd
                            *ngIf="item.productList.length === 1"
                            class="linked-product"
                        >
                            <ng-container
                                *ngFor="let item1 of item.productList"
                            >
                                <img
                                    class="thumbnail"
                                    src="{{
                                        item1.shopProduct?.images?.urls[0]
                                            ?.url ??
                                            'assets/images/fallback-post-img.png'
                                    }}"
                                    appImgFallback="assets/images/fallback-post-img.png"
                                    alt="Product thumbnail"
                                    loading="lazy"
                                />
                                <span>{{ item1.shopProduct?.name }}</span>
                            </ng-container>
                        </dd>
                        <dd
                            *ngIf="item.productList.length > 1"
                            class="linked-products-wrapper"
                        >
                            <div class="linked-products">
                                <div
                                    class="product-item"
                                    *ngFor="let item1 of item.productList"
                                >
                                    <img
                                        class="thumbnail"
                                        src="{{
                                            item1.shopProduct?.images?.urls[0]
                                                ?.url ??
                                                'assets/images/fallback-post-img.png'
                                        }}"
                                        appImgFallback="assets/images/fallback-post-img.png"
                                        alt="Product thumbnail"
                                        loading="lazy"
                                    />
                                </div>
                            </div>
                        </dd>
                        <dd class="info-text">Code: {{ item.code }}</dd>
                        <dd class="info-text">
                            Hạn sử dụng:
                            {{ formatDate(item.toTime, 'DD/MM/YYYY') }}
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
        <div *ngIf="shopVouchers.length !== 0" class="divider"></div>
        <div *ngIf="appVouchers.length !== 0" class="vouchers-section">
            <h3 class="title">Voucher của Reviewty</h3>
            <div class="vouchers">
                <div class="voucher-item" *ngFor="let item of appVouchers">
                    <div
                        *ngIf="item.type === 'FREESHIP'"
                        class="voucher-mark freeship-mark"
                    >
                        <img
                            src="assets/icons/admin-freeship.svg"
                            alt="Voucher icon"
                        />
                    </div>
                    <div
                        *ngIf="item.type === 'DISCOUNT'"
                        class="voucher-mark discount-mark"
                    >
                        <img
                            src="assets/icons/admin-discount.svg"
                            alt="Voucher icon"
                        />
                    </div>
                    <dl class="voucher-info">
                        <dt>
                            <span>
                                {{
                                    'Giảm ' +
                                        (item.type === 'FREESHIP'
                                            ? (item.maximumPrice
                                                  | currencyByLocale) +
                                              ' phí vận chuyển'
                                            : item.amount + '%')
                                }},
                                {{
                                    item.type !== 'FREESHIP'
                                        ? 'tối đa' +
                                          (item.maximumPrice | currencyByLocale)
                                        : ''
                                }}
                                cho đơn tối thiểu
                                {{ item.minimumPrice | currencyByLocale }}
                            </span>
                            <button
                                class="voucher-visibility-btn  {{
                                    !item.pinOnLivestream && 'active'
                                }}"
                                (click)="!isLoading && pinVoucher(item.id)"
                            >
                                <span>{{
                                    !item.pinOnLivestream
                                        ? 'Hiển thị'
                                        : 'Bỏ hiển thị'
                                }}</span>
                            </button>
                        </dt>
                        <dd
                            class="voucher-type {{
                                item.type === 'FREESHIP'
                                    ? 'freeship-type'
                                    : 'discount-type'
                            }}"
                        >
                            <span>Live Voucher</span>
                        </dd>
                        <dd class="info-text">Code: {{ item.code }}</dd>
                        <dd class="info-text">
                            Hạn sử dụng:
                            {{ formatDate(item.toTime, 'DD/MM/YYYY') }}
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
    </div>
</section>
