/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';

import {
    collection,
    query,
    where,
    getDocs,
    getFirestore,
    orderBy,
    limit,
    DocumentData,
} from '@angular/fire/firestore';
// import { AngularFireAuth } from 'angularfire2/auth';
import {
    signInWithEmailAndPassword,
    signOut,
    getAuth,
} from '@angular/fire/auth';
import { inject } from '@angular/core';
import { Firestore, collectionData, onSnapshot } from '@angular/fire/firestore';
import {
    deleteDoc,
    doc,
    setDoc,
    Timestamp,
    updateDoc,
} from 'firebase/firestore';
import { nanoid } from 'nanoid';
import { BehaviorSubject } from 'rxjs';

/**
 * content: 'test',
 * createdAt: Timestamp.fromDate(new Date()),
 * senderAvatar: "https://d1ip8wajnedch4.cloudfront.net/2023/11/27/7a7258b6-d119-4aa5-ac27-44ddc2959b0d.png",
 * senderID: 2556,
 * senderName: "support@review-ty.com",
 * type: "NORMAL",
 */
type LiveChat = {
    content: string;
    createdAt: Timestamp;
    senderAvatar: string;
    senderID: number;
    senderName: string;
    type: string;
};

type UserMessage = {
    content: string;
    createdAt: Timestamp;
    image: {
        height: number;
        width: number;
        url: string;
    };
    object: {
        product: {
            id: number;
            measure: string;
            name: string;
            price: number;
            thumbnail: string;
        };
        quantities: number;
    };
    senderID: number;
    type: string;
};

type UserShortInfo = {
    avatarUrl: string;
    id: number;
    name: string;
};

@Injectable()
export class ChatService {
    // private itemsCollection: AngularFirestoreCollection<Mensaje>;

    public userChatList: BehaviorSubject<
        {
            id: string;
            data: DocumentData;
        }[]
    > = new BehaviorSubject([]);

    public userChatMessages = new Map<
        string,
        BehaviorSubject<
            {
                id: string;
                data: DocumentData;
            }[]
        >
    >();

    public roomLiveMessages = new Map<
        number,
        BehaviorSubject<
            {
                id: string;
                data: DocumentData;
            }[]
        >
    >();

    public roomLivePinnedMessages = new Map<
        number,
        BehaviorSubject<
            {
                id: string;
                data: DocumentData;
            }[]
        >
    >();

    firestore: Firestore = inject(Firestore);

    constructor() {
        this.login().then((_userCredential: any) => {
            // Test user chat
            // this.subcribeRoomChatList(921587); // User ID
            // this.subcribeRoomChatMessage(0000) // Room ID
            // this.sendUserMessage(
            // 	{ avatarUrl: '', id: 921334, name: 'abc2k' }, // Sender
            // 	{ avatarUrl: '', id: 921587, name: 'son123321' }, // Receiver
            // 	{
            // 		content: 'xin chao test v3',
            // 		createdAt: Timestamp.fromDate(new Date()),
            // 		image: null,
            // 		object: {
            // 			product: null, // Product ID
            // 			quantities: 0,
            // 		},
            // 		senderID: 921587,
            // 		type: 'TEXT',
            // 	},
            // );
            // Test live chat
            // this.getRoomLiveMessage(176);
            // this.commentLiveChat(176, nanoid(), {
            // 	content: 'test',
            // 	createdAt: Timestamp.fromDate(new Date()),
            // 	senderAvatar:
            // 		'https://d27j9dvp3kyb6u.cloudfront.net/2021/01/30/88a5f51c-8d1b-47d9-bf30-951bef581afd.png',
            // 	senderID: 100849,
            // 	senderName: 'a@a.com8so1',
            // 	type: 'NORMAL',
            // });
        });
    }

    login() {
        const auth = getAuth();
        return signInWithEmailAndPassword(
            auth,
            'reviewty-firebase@review-ty.com',
            'Reviewty2020',
        );
    }

    logout() {
        // this.usuario = {};
        // this.afAuth.auth.signOut();
    }

    async subcribeRoomChatList(userId) {
        const q = query(
            collection(this.firestore, 'chats'),
            where('memberIds', 'array-contains-any', [userId]),
            orderBy('lastMessage.createdAt', 'desc'),
        );

        const _ = onSnapshot(q, (querySnapshot) => {
            const result = [];
            querySnapshot.forEach((doc) => {
                result.push({ id: doc.id, data: doc.data() });
            });
            this.userChatList.next(result);
        });
    }

    async subcribeRoomChatMessage(roomId) {
        const q = query(
            collection(this.firestore, `chats/${roomId}/chatMessages`),
            orderBy('createdAt', 'desc'),
            limit(20),
        );

        const _ = onSnapshot(q, (querySnapshot) => {
            const messages: {
                id: string;
                data: DocumentData;
            }[] = [];

            querySnapshot.forEach((doc) => {
                messages.push({ id: doc.id, data: doc.data() });
            });

            // console.log('messages: ',messages)

            if (this.userChatMessages.has(roomId)) {
                this.userChatMessages.get(roomId).next(messages);
            } else {
                this.userChatMessages.set(
                    roomId,
                    new BehaviorSubject(messages),
                );
            }
        });
    }

    async getRoomChatMessage(roomId) {
        if (this.userChatMessages.has(roomId)) {
            return this.userChatMessages.get(roomId);
        } else {
            this.subcribeRoomChatMessage(roomId);
        }
    }

    async getRoomLiveMessage(liveId) {
        const q = query(
            collection(this.firestore, `rooms/${liveId}/messages`),
            orderBy('createdAt', 'desc'),
            limit(100),
        );

        const _ = onSnapshot(q, (querySnapshot) => {
            const messages: {
                id: string;
                data: DocumentData;
            }[] = [];

            querySnapshot.forEach((doc) => {
                messages.push({ id: doc.id, data: doc.data() });
            });

            if (this.roomLiveMessages.has(liveId)) {
                this.roomLiveMessages.get(liveId).next(messages);
            } else {
                this.roomLiveMessages.set(
                    liveId,
                    new BehaviorSubject(messages),
                );
            }
        });
    }

    async preloadRoomLiveMessage(liveId) {
        const q = query(
            collection(this.firestore, `rooms/${liveId}/messages`),
            orderBy('createdAt', 'desc'),
            limit(100),
        );
        const querySnapshot = await getDocs(q);
        const messageList = [];
        querySnapshot.forEach((doc) => {
            messageList.push({ id: doc.id, data: doc.data() });
        });
        return messageList;
    }

    async getUserChatRoom(
        senderInfo: UserShortInfo,
        receiverInfo: UserShortInfo,
    ) {
        // handle when avatarUrl is undefined
        if (senderInfo.avatarUrl === undefined) {
            senderInfo.avatarUrl = '';
        }

        // handle when avatarUrl is undefined
        if (receiverInfo.avatarUrl === undefined) {
            receiverInfo.avatarUrl = '';
        }
        // Get room
        const q = query(
            collection(this.firestore, 'chats'),
            where('memberIds', 'array-contains-any', [
                senderInfo.id,
                receiverInfo.id,
            ]),
        );
        const querySnapshot = await getDocs(q);
        const roomInfo = [];
        querySnapshot.forEach((doc) => {
            if (
                doc.data().memberIds.includes(senderInfo.id) &&
                doc.data().memberIds.includes(receiverInfo.id)
            ) {
                roomInfo.push({ id: doc.id, data: doc.data() });
            }
        });

        const { roomId, roomInit } = await this.makeRoomInfo(
            roomInfo,
            senderInfo,
            receiverInfo,
            null,
        );

        await setDoc(doc(this.firestore, 'chats', roomId), roomInit);

        return { roomId, roomInit };
    }

    async sendUserMessage(
        senderInfo: UserShortInfo,
        receiverInfo: UserShortInfo,
        message: UserMessage,
    ) {
        // Get room list that contains the ID of sender or reciever
        const q = query(
            collection(this.firestore, 'chats'),
            where('memberIds', 'array-contains-any', [
                senderInfo.id,
                receiverInfo.id,
            ]),
        );
        const querySnapshot = await getDocs(q);
        const roomInfo = [];
        querySnapshot.forEach((doc) => {
            // console.log(doc.id, ' => ', doc.data());
            if (
                doc.data().memberIds.includes(senderInfo.id) &&
                doc.data().memberIds.includes(receiverInfo.id)
            ) {
                roomInfo.push({ id: doc.id, data: doc.data() });
            }
            // return { id: doc.id, data: doc.data() };
        });
        // console.log('roomInfo', roomInfo)
        const { roomId, roomInit } = await this.makeRoomInfo(
            roomInfo,
            senderInfo,
            receiverInfo,
            message,
        );

        // console.log(roomId, roomInit);

        // Update new information for the room
        await setDoc(doc(this.firestore, 'chats', roomId), roomInit);

        // Add a new message
        await setDoc(
            doc(this.firestore, `chats/${roomId}/chatMessages`, nanoid()),
            message,
        );
    }

    async makeRoomInfo(
        roomInfo: any = [],
        senderInfo: UserShortInfo,
        receiverInfo: UserShortInfo,
        message: UserMessage,
    ) {
        let roomId;
        let roomInit;
        const senderId = senderInfo.id;
        const receiverId = receiverInfo.id;
        // console.log(senderId, receiverId)
        if (roomInfo.length === 0) {
            roomId = nanoid();
            roomInit = {
                counter: {
                    [senderId]: {
                        numberOfReceivedMessages: 0,
                        numberOfSentMessages: 0,
                    },
                    [receiverId]: {
                        numberOfReceivedMessages: 0,
                        numberOfSentMessages: 0,
                    },
                },
                createdAt: Timestamp.fromDate(new Date()),
                lastMessage: message,
                memberIds: [senderId, receiverId],
                updatedAt: Timestamp.fromDate(new Date()),
                users: {
                    senderId: senderInfo,
                    receiverId: receiverInfo,
                },
            };
        } else {
            roomId = roomInfo[0]['id'];
            roomInit = roomInfo[0]['data'];

            roomInit.counter[senderId] = roomInit.counter[senderId]
                ? roomInit.counter[senderId]
                : {};
            roomInit.counter[senderId].numberOfSentMessages = roomInit.counter[
                senderId
            ]?.numberOfSentMessages
                ? roomInit.counter[senderId].numberOfSentMessages + 1
                : 1;

            roomInit.updatedAt = Timestamp.fromDate(new Date());
            roomInit.lastMessage = message;
        }

        return { roomId, roomInit };
    }

    async updateNumberOfReceivedMessages(
        roomId,
        receiverId,
        numberOfReceivedMessages,
    ) {
        const data = {
            counter: {
                [receiverId]: {
                    numberOfReceivedMessages: numberOfReceivedMessages,
                },
            },
        };

        await updateDoc(doc(this.firestore, 'chats', roomId), data);
    }

    async commentLiveChat(roomId, messageId, docData: LiveChat) {
        // console.log('commentLiveChat', roomId, messageId);
        await setDoc(doc(this.firestore, 'rooms', `${roomId}`), {
            id: roomId,
        });

        await setDoc(
            doc(this.firestore, `rooms/${roomId}/messages`, messageId),
            docData,
        );
    }

    async getRoomById(liveId) {
        const _q = query(
            collection(this.firestore, 'rooms'),
            where('id', '==', liveId as number),
            limit(1),
        );

        // const querySnapshot = await getDocs(_q);

        // const data = [];
        // querySnapshot.forEach((doc) => {
        // 	console.log('Doc::',doc);
        // 	data.push({ id: doc.id, data: doc.data() });
        // });

        // console.log('getRoomById', data)

        const _ = onSnapshot(_q, (querySnapshot) => {
            querySnapshot.forEach((doc) => {
                console.log('doc', doc.data());
                this.getPinnedMessage(liveId, doc.data().pinnedMessageId);
            });
        });
    }

    async getPinnedMessage(liveId, messageId) {
        const q = query(
            collection(this.firestore, `rooms/${liveId}/messages`),
            where('__name__', '==', messageId),
            limit(1),
        );

        const querySnapshot = await getDocs(q);

        const messages: {
            id: string;
            data: DocumentData;
        }[] = [];
        querySnapshot.forEach((doc) => {
            messages.push({ id: doc.id, data: doc.data() });
        });
        // console.log('messages', messages);
        if (this.roomLivePinnedMessages.has(liveId)) {
            this.roomLivePinnedMessages.get(liveId).next(messages);
        } else {
            this.roomLivePinnedMessages.set(
                liveId,
                new BehaviorSubject(messages),
            );
        }
    }

    async getRoomLivePinnedMessage(liveId) {
        console.log('Get room by Id: ', liveId);

        await this.getRoomById(liveId);
    }

    async pinLiveChat(roomId, messageId, docData: LiveChat) {
        // console.log('pinLiveChat', roomId, messageId);

        await setDoc(
            doc(this.firestore, `rooms/${roomId}/pinMessages`, messageId),
            docData,
        );
        await updateDoc(doc(this.firestore, 'rooms', `${roomId}`), {
            id: roomId,
            pinnedMessageId: messageId,
        });
    }

    async removePinLiveChat(roomId, messageId) {
        await deleteDoc(
            doc(this.firestore, `rooms/${roomId}/pinMessages`, messageId),
        );

        await updateDoc(doc(this.firestore, 'rooms', roomId), {
            pinnedMessageId: '',
        });
    }

    async getUnreadCount(userId: number): Promise<string> {
        let total: number = 0;
        const q = query(
            collection(this.firestore, 'chats'),
            where('memberIds', 'array-contains', userId),
            orderBy('lastMessage.createdAt', 'desc'),
            limit(10),
        );

        const docs = await getDocs(q);
        docs.forEach((doc) => {
            const data = doc.data();

            // check send to other, NOT to self
            if (data?.counter && Object.keys(data?.counter)?.length > 1) {
                //    get receiver and sender id
                const senderId = data?.users?.senderId?.id;
                const receiverId = data?.users?.receiverId?.id;

                // if receiver and sender exist
                if (senderId && receiverId) {
                    // if receiver is user id
                    if (receiverId == userId) {
                        // get sender sent msgs + receiver received msgs
                        const diff =
                            data?.counter?.[senderId]?.numberOfSentMessages -
                            data?.counter?.[receiverId]
                                ?.numberOfReceivedMessages;
                        total += diff;
                    }
                }
            }
        });
        return total > 10 ? '10+' : total.toString();
    }
}
