import {
    AfterViewInit,
    Component,
    HostListener,
    OnInit,
    Renderer2,
    ViewChild,
} from '@angular/core';
import {
    ActivatedRoute,
    NavigationEnd,
    Router,
    RouterOutlet,
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie';
import * as uuid from 'uuid';
import { disableFragmentWarnings } from 'graphql-tag';
disableFragmentWarnings();

import {
    LocationService,
    IpService,
    RouterService,
    ScrollDetectService,
    SeoService,
} from '@app/core/services';
import { LANG } from './core/helpers/contains';
import { AuthService } from './auth/shared/services/auth.service';
import { environment } from '@environments/environment';
import { StoreService } from './core/services/store.service';
import {
    DEFAULT_JSON_LD_ORGANIZATION,
    DEFAULT_JSON_LD_WEBSITE,
    DEFAULT_SEO_SOCIAL_SHARE_METADATA,
} from './core/constants/seo';
import { AppVersionManagerService } from './core/services/version-manager/version-manager.service';
import { SwUpdate } from '@angular/service-worker';

// import RouterOutlet;

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
    @ViewChild('refreshForm', { static: false }) refreshForm;
    isUsingHeaderLite = false;
    isUsingMobileTransparentLayout = false;
    isUsingIframeLayout = false;
    isMinView = false;
    currentUrl: string = '';

    private scrollStopTimeout = null;

    @HostListener('window:scroll')
    checkScroll() {
        // Check if user is scrolling
        this.scrollDetectService.triggerWindowFrameStopChange(true);
        clearTimeout(this.scrollStopTimeout);
        this.scrollStopTimeout = setTimeout(() => {
            this.scrollDetectService.triggerWindowFrameStopChange(false);
        }, 300);

        // Check if frame is not top
        const scrollPosition =
            window.scrollY ||
            document?.documentElement?.scrollTop ||
            document.body.scrollTop ||
            0;
        if (scrollPosition === 0) {
            this.scrollDetectService.triggerWindowFrameChange(false);
        } else {
            this.scrollDetectService.triggerWindowFrameChange(true);
        }

        // Check if user scrolled to bottom
        if (window.innerHeight + window.scrollY >= document.body.scrollHeight) {
            //
        } else {
            //
        }
    }
    loading: boolean = true;
    constructor(
        private authService: AuthService,
        private route: ActivatedRoute,
        private router: Router,
        private translateService: TranslateService,
        private locationService: LocationService,
        private ipService: IpService,
        private routerService: RouterService,
        private cookieService: CookieService,
        private scrollDetectService: ScrollDetectService,
        private store: StoreService,
        private seoService: SeoService,
        private renderer2: Renderer2,
    ) {
        // purge cache
        caches.keys().then((res) => {
            res.forEach((k) => caches.delete(k));
        });
        navigator.serviceWorker.getRegistrations().then((registrations) => {
            registrations.forEach((resg) => {
                resg.unregister();
            });
        });

        if (localStorage.getItem(environment.tokenKey)) {
            this.authService.getMe().subscribe((authMeResult) => {
                if (authMeResult?.data?.me) {
                    this.store.setUserProfile(authMeResult?.data?.me);
                    this.authService
                        .getActivityUser(authMeResult?.data?.me?.id)
                        .subscribe((activityUser) => {
                            this.store.setActiveUser(activityUser.data);
                        });

                    this.loading = false;
                }
            });
        } else {
            this.loading = false;
        }

        this.routerService.mobileTransparentLayoutChange.subscribe((value) => {
            this.isUsingMobileTransparentLayout = value;
        });
        this.routerService.iframeLayoutChange.subscribe((value) => {
            this.isUsingIframeLayout = value;
        });

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.currentUrl = event.url;
            }
        });

        localStorage.setItem(LANG, 'vi');
        this.translateService.setDefaultLang('vi');

        if (!localStorage.getItem(LANG)) {
            localStorage.setItem(LANG, 'vi');

            this.translateService.setDefaultLang('vi');
        } else {
            this.translateService.use(localStorage.getItem(LANG));
        }
        this.locationService.getLocation();
        this.ipService.getIpClient();

        if (!this.cookieService.get('Device-Token')) {
            const currentDate = new Date();
            const tokenExpireDate = currentDate.setFullYear(
                currentDate.getFullYear() + 2,
            );

            this.cookieService.put('Device-Token', uuid.v4(), {
                expires: new Date(tokenExpireDate),
            });
        }
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            if (params['view'] === 'min') {
                this.routerService.triggerMobileTransparentHeaderChange(true);

                this.isMinView = true;
            }
        });

        this.seoService.setMeta(DEFAULT_SEO_SOCIAL_SHARE_METADATA);
        this.seoService.setJsonLd(this.renderer2, {
            '@context': 'http://schema.org',
            '@type': 'WebSite',
            ...DEFAULT_JSON_LD_WEBSITE,
        });
        this.seoService.setJsonLd(this.renderer2, {
            '@context': 'http://schema.org',
            '@type': 'Organization',
            ...DEFAULT_JSON_LD_ORGANIZATION,
        });
    }

    ngAfterViewInit(): void {}

    close() {
        this.store.setShowLogin(false);
    }
}
