import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
} from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription, first } from 'rxjs';
import { PingService } from '@app/core/services/ping.service';
import { ActivatedRoute, Route } from '@angular/router';

declare let IVSPlayer;
const PlayerState = IVSPlayer.PlayerState;
const PlayerEventType = IVSPlayer.PlayerEventType;

@Component({
    selector: 'app-root-live-video-wrapper',
    templateUrl: './live-video-wrapper.component.html',
    styleUrl: './live-video-wrapper.component.scss',
})
export class LiveVideoWrapperComponent
    implements AfterViewInit, OnDestroy, OnChanges {
    @Input() playerId: string = 'video-stream';
    @Input() videoUrl: string;
    @Input() width: number = window.innerWidth;
    @Input() height: number = window.innerHeight;
    @Input() isPlayOverlayVisible: boolean = false;
    @Input() isMuteBtnVisible: boolean = false;
    @Input() isMuted = false;

    @Output() playerReadyEvent = new EventEmitter<boolean>();
    @Output() viewerCountEvent = new EventEmitter<number>();
    @Output() refreshEvent = new EventEmitter<boolean>();
    @Output() refreshProductEvent = new EventEmitter<unknown>();
    @Output() stopLiveEvent = new EventEmitter<unknown>();
    @Output() buyerInfoEvent = new EventEmitter<unknown>();

    isMobile = false;
    isShowLoading = false;
    ping = 0;

    private videoJSplayer;

    playTimeout = null;
    playInterval = null;
    round = 0;
    videoStyle = {
        'width.px': 0,
        'height.px': 0,
        'background-color': 'black',
    };
    showPing: boolean = false;
    subscribe: Subscription;

    constructor(private deviceService: DeviceDetectorService, private pingService: PingService, private router: ActivatedRoute) {
        this.isMobile = this.deviceService.isMobile();
        this.viewerCountEvent.emit(0);
        this.playerReadyEvent.emit(false);
        this.isShowLoading = true;

        if (window.location.pathname.includes('/live') && !this.router.snapshot.queryParamMap.get('isShowMiniPlayer')) {
            this.showPing = true;

        }
    }

    ngAfterViewInit() {
        if (this.showPing) {
            this.pingService.sub();
            this.subscribe = this.pingService.pingStream.subscribe(ping => this.ping = ping > 0 ? Math.floor(ping) : 0);

        }

        this.loadVideoStream();
        // this.videoJSplayer = videojs(
        // 	this.playerId,
        // 	{
        // 		width: this.width,
        // 		height: this.height,
        // 		liveui: true,
        // 		muted: true,
        // 		controls: false,
        // 		errorDisplay: false,
        // 		loadingSpinner: false,
        // 	},
        // 	() => {
        // 		this.playerReadyEvent.emit(true);
        // 	},
        // );

        // this.videoJSplayer.on(['waiting', 'pause'], () => {
        // 	this.playerReadyEvent.emit(false);
        // 	this.isShowLoading = true;
        // });

        // this.videoJSplayer.on('playing', () => {
        // 	this.playerReadyEvent.emit(true);
        // 	this.isShowLoading = false;

        // 	clearTimeout(this.playTimeout);
        // });

        // this.videoJSplayer.on('error', () => {
        // 	this.isShowLoading = true;

        // 	this.round++;
        // 	if (this.round <= 5) {
        // 		clearTimeout(this.playTimeout);
        // 		this.playTimeout = setTimeout(() => {
        // 			this.videoJSplayer.load();

        // 			this.videoJSplayer.ready(() => {
        // 				this.videoJSplayer.play();
        // 			});
        // 		}, 1000 * this.round);
        // 	}
        // });

        // setInterval(() => {
        //     this.buyerInfoEvent.emit({ 'type': 'BUYER_INFO', 'channelId': 'Yws8XFeTbAWA', 'buyers': ['Ln12', 'nv_review_01', 'LILA02', 'Ly test1'] })
        // }, 5000);
    }

    loadVideoStream() {
        this.videoStyle = {
            'width.px': this.width,
            'height.px': this.height,
            'background-color': 'black',
        };

        const self = this;

        const videoPlayer = document.getElementById(this.playerId);

        this.videoJSplayer = IVSPlayer.create();
        this.videoJSplayer.attachHTMLVideoElement(videoPlayer);

        this.videoJSplayer.addEventListener(
            PlayerEventType.AUDIO_BLOCKED,
            () => {
                console.log('AUDIO_BLOCKED', self.isMuted);
                // self.isMuted = true;
                // this.videoJSplayer.setMuted(true);
                self.toggleSound();
            },
        );
        this.videoJSplayer.addEventListener(PlayerEventType.BUFFER_UPDATE, () => {
            // this._http.get('b84b36d5db31.ap-northeast-2.playback.live-video.net')
            // console.log(this.getNetworkLatency());
        });

        this.videoJSplayer.addEventListener(PlayerState.PLAYING, () => {
            console.log('Player State - PLAYING');
            this.playerReadyEvent?.emit(true);
            this.isShowLoading = false;

            this.videoJSplayer.setMuted(this.isMuted);
        });

        this.videoJSplayer.addEventListener(PlayerState.ENDED, function () {
            console.log('Player State - ENDED');

            self.playerReadyEvent.emit(false);
            self.isShowLoading = true;

            if (!self.playInterval) {
                self.playInterval = setInterval(() => {
                    if (
                        self.isShowLoading ||
                        self.videoJSplayer.getState() == 'Buffering'
                    ) {
                        self.reloadSource();
                    }
                }, 2000);
            }
        });

        this.videoJSplayer.addEventListener(PlayerState.READY, () => {
            console.log('Player State - READY');
        });

        this.videoJSplayer.addEventListener(
            PlayerEventType.ERROR,
            function (err) {
                console.warn('Player Event - ERROR:', err);
                self.playerReadyEvent.emit(false);
                self.isShowLoading = true;
                setTimeout(function () {
                    self.reloadSource();
                }, 2000);
            },
        );

        this.videoJSplayer.addEventListener(
            PlayerEventType.TEXT_METADATA_CUE,
            function (cue) {
                const metadataText = JSON.parse(cue.text);
                // const position = this.videoJSplayer.getPosition().toFixed(2);
                console.log(
                    `Player Event - TEXT_METADATA_CUE: "${cue.text}". Observed after playback started.`,
                );
                if (metadataText.type == 'LIVE_INFORMATION') {
                    self.viewerCountEvent.emit(metadataText.viewerCount);
                }

                if (
                    metadataText.type == 'PIN_PRODUCT' ||
                    metadataText.type == 'EDIT_PRODUCT' ||
                    metadataText.type == 'PIN_PROMOTION'
                ) {
                    self.refreshProductEvent.emit(true);
                }

                if (metadataText.type == 'STOP_LIVE') {
                    self.stopLiveEvent.emit(metadataText);
                }

                if (metadataText.type == 'BUYER_INFO') {
                    self.buyerInfoEvent.emit(metadataText);
                }
            },
        );

        this.videoJSplayer.load(this.videoUrl);
        this.videoJSplayer.setAutoplay(true);
        this.videoJSplayer.setVolume(1);
    }

    reloadSource() {
        this.videoJSplayer.load(this.videoUrl);
        this.videoJSplayer.setAutoplay(true);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.videoJSplayer) {
            if (changes.videoUrl?.currentValue) {
                this.videoJSplayer.load(changes.videoUrl.currentValue);
            }
        }
    }

    ngOnDestroy(): void {
        if (this.playInterval) {
            clearInterval(this.playInterval);
        }
        this.videoJSplayer.delete();
        if (this.showPing) {
            this.subscribe.unsubscribe();
            this.pingService.unsub();
        }
    }

    togglePlay() {
        if (this.videoJSplayer.isPaused()) {
            this.videoJSplayer.play();
        } else {
            this.videoJSplayer.pause();
        }
    }

    toggleSound() {
        this.isMuted = !this.isMuted;
        this.videoJSplayer.setMuted(this.isMuted);
        console.log(
            'this.videoJSplayer.isMuted',
            this.isMuted,
            this.videoJSplayer.isMuted(),
        );
    }
}
