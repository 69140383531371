import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { StoreService } from '@app/core/services/store.service';
import { ProfileService } from '@app/profile/services/profile.service';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { map, takeWhile, timer } from 'rxjs';
import { AuthService } from '../shared/services/auth.service';

@Component({
    selector: 'app-root-verify-account',

    templateUrl: './verify-account.component.html',
    styleUrl: './verify-account.component.scss',
})
export class VerifyAccountComponent implements OnChanges, AfterViewInit {
    constructor(
        private profileService: ProfileService,
        private router: Router,
        private authService: AuthService,
        private store: StoreService,
        private msgService: NzMessageService,
        private translateService: TranslateService,
    ) {}
    seconds = 60 * 5;
    otp;
    isShowEditInformation = false;

    // @Input:

    resendOTPEmail;
    password2;

    @Input() email?: string;

    @Output() isBack = new EventEmitter();

    @Output() successCallback = new EventEmitter();

    timeRemaining$ = timer(0, 1000).pipe(
        map((n) => (this.seconds - n) * 1000),
        takeWhile((n) => n >= 0),
    );
    handleOtpOut(otp: number) {
        this.otp = otp;
    }

    ngAfterViewInit() {
        this.store.bodySaveEmailPassword$.subscribe((data) => {
            if (data?.email) {
                this.resendOTPEmail = data.email;
            }
        });
    }

    tryingToSentOTP() {
        this.authService
            .generateOTP({
                email: this.resendOTPEmail,
            })
            .subscribe((data) => {
                if (data.data.generateOTP) {
                    this.msgService.success(
                        this.translateService.instant(
                            'notification.create_success',
                        ),
                    );
                }
                return;
            });
    }
    backClicked() {
        this.isBack.emit();
        // window.history.go(-1);
    }

    verificationOTP() {
        if (this.otp.length === 0) return;

        this.authService.verifyOTP(
            { otp: Number(this.otp), email: this.email },
            () => {
                this.isShowEditInformation = true;
            },
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.email !== changes.email?.previousValue) {
            this.timeRemaining$ = timer(0, 1000).pipe(
                map((n) => (60 * 5 - n) * 1000),
                takeWhile((n) => n >= 0),
            );
        }
    }

    close() {
        this.isShowEditInformation = false;
    }
}
