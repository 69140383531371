import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, interval } from 'rxjs';

@Injectable()
export class PingService {
    pingStream: Subject<number> = new Subject<number>();
    ping: number = 0;
    url: string = 'https://b84b36d5db31.ap-northeast-2.playback.live-video.net';
    subscription;
    constructor(private _http: HttpClient) {

    }

    sub() {
        this.subscription = interval(5000)
            .subscribe((data) => {
                const timeStart: number = performance.now();
                this._http.get(this.url)
                    .subscribe((data) => {
                        const timeEnd: number = performance.now();

                        const ping: number = timeEnd - timeStart;
                        this.ping = ping;
                        this.pingStream.next(ping);
                    });
            });
    }

    unsub() {
        this.subscription.unsubscribe();
    }


}