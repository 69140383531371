import { gql } from 'apollo-angular';

export const GET_CATEGORIES = gql`
    query readAllCategories(
        $where: CategoryWhereInput
        $first: Int = 100
        $orderBy: CategoryOrderByInput = { id: ASC }
        $idFilters: [Int!]
    ) {
        categories(
            where: $where
            first: $first
            orderBy: $orderBy
            idFilters: $idFilters
        ) {
            __typename
            id
            status
            parent {
                __typename
                id
            }
            translations(where: { language: VI }) {
                __typename
                name
            }
            activeLogo {
                __typename
                url
            }
            inactiveLogo {
                __typename
                url
            }
            children(
                first: $first
                orderBy: { id: ASC }
                where: { status: ACTIVE }
            ) {
                __typename
                id
                status
                parent {
                    __typename
                    id
                }
                translations(where: { language: VI }) {
                    __typename
                    name
                }
                activeLogo {
                    __typename
                    url
                }
                inactiveLogo {
                    __typename
                    url
                }
                children(first: $first, where: { status: ACTIVE }) {
                    __typename
                    id
                    status
                    parent {
                        __typename
                        id
                    }
                    translations(where: { language: VI }) {
                        __typename
                        name
                    }
                    activeLogo {
                        __typename
                        url
                    }
                    inactiveLogo {
                        __typename
                        url
                    }
                }
            }
        }
    }
`;

export const SEARCH_CATEGORIES_BY_NAME = gql`
    query searchCategoriesByName(
        $skip: Int = 0
        $first: Int = 20
        $text: String!
    ) {
        categorySearch(skip: $skip, first: $first, text: $text) {
            total
            categories {
                __typename
                id
                status
                parent {
                    __typename
                    id
                }
                translations(where: { language: VI }) {
                    __typename
                    name
                }
                activeLogo {
                    __typename
                    url
                }
                inactiveLogo {
                    __typename
                    url
                }
            }
        }
    }
`;
