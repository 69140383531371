import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
    selector: 'app-root-transparent-header',
    templateUrl: './transparent-header.component.html',
    styleUrls: ['./transparent-header.component.scss'],
})
export class TransparentHeaderComponent {
    constructor(private _location: Location) {}

    goBack() {
        this._location.back();
    }
}
