<div class="body  {{ isToggleSignup ? 'translateY' : '' }}">
    <div class="main-content-wrapper tab-item">
        <div class="main-content hidden-scrollbar">
            <a class="top-nav" routerLink="/">
                <img src="assets/icons/back-to-home.svg" alt="Back to home" />
                <h3>Back to home</h3>
            </a>
            <div class="form-content">
                <h1 class="title">Đăng nhập</h1>
                <p class="desc">Enter your email and password to sign in!</p>

                <div class="login-with" (click)="facebook()">
                    <img
                        src="/assets/icons/facebook-logo.svg"
                        alt="face-book-icon"
                    />
                    <h3>Đăng nhập với Facebook</h3>
                </div>
                <div class="login-with apple" (click)="apple()">
                    <img
                        src="/assets/icons/apple-logo.svg"
                        alt="face-book-icon"
                    />
                    <h3>Đăng nhập với Apple ID</h3>
                </div>

                <div class="or">
                    <div class="separator"></div>
                    <h3 class="title">or</h3>
                </div>

                <nz-alert
                    [nzMessage]="error"
                    nzType="error"
                    [nzShowIcon]="true"
                    style="margin-bottom: 24px"
                    *ngIf="!submitting && error"
                ></nz-alert>

                <form
                    nz-form
                    [formGroup]="loginForm"
                    nzLayout="vertical"
                    (ngSubmit)="handleSubmit()"
                >
                    <nz-form-item>
                        <nz-form-label>Email<span>*</span></nz-form-label>
                        <nz-form-control
                            [nzErrorTip]="
                                loginForm.get('email')?.getError('required')
                                    ? 'Trường này là bắt buộc nhập'
                                    : loginForm
                                            .get('email')
                                            ?.getError('invalid')
                                      ? 'Sai tên tài khoản hoặc mật khẩu'
                                      : ''
                            "
                        >
                            <input
                                nz-input
                                placeholder="Email"
                                formControlName="email"
                            />
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label>Password<span>*</span></nz-form-label>
                        <nz-form-control
                            [nzErrorTip]="
                                loginForm.get('password')?.getError('required')
                                    ? 'Trường này là bắt buộc nhập'
                                    : loginForm
                                            .get('password')
                                            ?.getError('invalid')
                                      ? 'Sai tên tài khoản hoặc mật khẩu'
                                      : ''
                            "
                        >
                            <nz-input-group [nzSuffix]="suffixTemplate">
                                <input
                                    formControlName="password"
                                    [type]="
                                        passwordVisible ? 'text' : 'password'
                                    "
                                    nz-input
                                    placeholder="Password"
                                />
                                <div class="empty"></div>
                            </nz-input-group>
                            <ng-template #suffixTemplate>
                                <span
                                    nz-icon
                                    [nzType]="
                                        passwordVisible
                                            ? 'eye-invisible'
                                            : 'eye'
                                    "
                                    (click)="passwordVisible = !passwordVisible"
                                ></span>
                            </ng-template>
                        </nz-form-control>
                    </nz-form-item>

                    <div class="more">
                        <div class="remember-me">
                            <input type="checkbox" formControlName="remember" />
                            <h3>Giữ đăng nhập</h3>
                        </div>

                        <a
                            class="underline"
                            [routerLink]="['/auth/forgot-password']"
                            >Quên mật khẩu ?</a
                        >
                    </div>

                    <button class="submit">Đăng nhập</button>
                </form>
            </div>

            <p class="d-flex justify-end pt-2" (click)="toggleSignupAccount()">
                Bạn chưa có tài khoản ?&nbsp;<a class="underline"
                    >Đăng ký ngay</a
                >
            </p>

            <h3 class="coppyright">© 2023 Reviewty. All Rights Reserved.</h3>
        </div>
    </div>
    <div class="main-content-wrapper tab-item hidden-scrollbar">
        <app-root-signup (toggleTab)="toggleSignupAccount()"></app-root-signup>
    </div>
</div>

<div *ngIf="loading" class="loading-status">
    <mat-spinner
        [diameter]="50"
        [strokeWidth]="5"
        [color]="'#BA92D9'"
    ></mat-spinner>
</div>
<!-- <app-root-tell-us-about-us></app-root-tell-us-about-us> -->
