import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'calendarTime',
})
export class CalendarTimePipe implements PipeTransform {
    constructor(private translateService: TranslateService) {}
    transform(time: string): string {
        const now = dayjs();
        const then = dayjs(time);

        const yearsCount = now.diff(then, 'year');
        if (yearsCount !== 0) {
            return `${yearsCount} ${this.translateService.instant('common.yearAgo')}`;
        }

        const monthsCount = now.diff(then, 'month');
        if (monthsCount !== 0) {
            return `${monthsCount} ${this.translateService.instant(
                'common.monthAgo',
            )}`;
        }

        const daysCount = now.diff(then, 'day');
        if (daysCount === 0) {
            return this.translateService.instant('common.today');
        } else if (daysCount === 1) {
            return this.translateService.instant('common.yesterday');
        } else if (daysCount < 4) {
            return `${daysCount} ${this.translateService.instant('common.dayAgo')}`;
        } else {
            return `${then.format('DD.MM.YYYY')}`;
        }
    }
}
