<div class="rt-m-t">
  <div #topnav class="topnav {{!isShowNavBar && 'nav-up'}}">
    <!-- Header content -->
  </div>
</div>

<div class="rt-dt">
  <div #topnav class="topnav">
    <!-- Header content -->
  </div>
</div>
