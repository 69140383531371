import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    DeleteLiveStreamProductGQL,
    EcommercePromotion,
    Exact,
    GetActivityUserQuery,
    GetLiveStreamProductsGQL,
    GetShopProductsQuery,
    IsPinLiveStreamProductGQL,
    LiveStreamGQL,
    LiveStreamQuery,
    LiveStreamStatus,
    LiveStreamWhereUniqueInput,
    PromotionAmountUnitType,
    PromotionEntityType,
    StartLiveStreamV2GQL,
    StopLiveStreamV2GQL,
} from '@app/common/generated-types';
import {
    RouterService,
    UtilsService,
    WebsocketService,
} from '@app/core/services';
import { StoreService } from '@app/core/services/store.service';
import { CartService } from '@app/home/shared/services/cart.service';
import { TranslateService } from '@ngx-translate/core';
import { QueryOptionsAlone } from 'apollo-angular/types';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { DeviceDetectorService } from 'ngx-device-detector';
// import { clearInterval } from 'timers';
import { environment } from '@environments/environment';
import { ESelectTabLiveStream } from '../live-stream-list/live-stream-list.component';

export enum ERole {
    HOST = 'host',
    VIEWER = 'viewer',
}
export interface ILiveStream {
    __typename?: 'LiveStream';
    id: number;
    name: string;
    description?: string;
    roomId?: string;
    streamUrl?: string;
    liveUrl?: string;
    channelId?: string;
    status: LiveStreamStatus;
    createdAt: any;
    updatedAt: any;
    startedAt?: any;
    savedByViewer?: boolean;
    nbOfLiveStreamProducts?: number;
    liveStreamProducts?: Array<{
        __typename?: 'LiveStreamProduct';
        id: number;
        isCurrentLive: boolean;
        code: string;
        shopProduct?: {
            __typename?: 'ShopProduct';
            totalQuantity?: number;
            totalSoldQuantity?: number;
            id: number;
            name?: string;
            images?: {
                __typename?: 'Images';
                urls?: Array<{ __typename?: 'Image'; url: string }>;
            };
            productVariants?: Array<{
                __typename?: 'ProductVariant';
                id: number;
                originalPrice: number;
                price: number;
                quantity: number;
            }>;
            shop: { __typename?: 'Shop'; id: number };
            flashsale?: {
                id: number;
                entity?: number;
                entityType?: PromotionEntityType;
                title?: string;
                amount?: number;
                amountUnit?: PromotionAmountUnitType;
                minimumPrice?: number;
                maximumPrice?: number;
            }[];
            promotions?: EcommercePromotion[]
        };
    }>;
    host: {
        __typename?: 'User';
        id: number;
        account: string;
        avatar?: { __typename?: 'Image'; url: string };
        shop?: { __typename?: 'Shop'; id: number; name: string };
    };
    background?: { __typename?: 'Image'; url: string };
}

export type LiveAddFieldViewerCount = ILiveStream & {
    viewerCount?: number;
};

@Component({
    selector: 'app-root-live-stream-detail-by-user',

    templateUrl: './live-stream-detail-by-user.component.html',
    styleUrl: './live-stream-detail-by-user.component.scss',
})
export class LiveStreamDetailByUserComponent implements OnInit, OnDestroy {
    isLoadingGoLive = false;
    isShowDescription = false;
    isShowVideo = false;
    isShowModalLinkLiveStream = false;
    isShowProductLive = false;
    isDisabledScroll = false;
    isMobile = false;

    isShowPinned = true;
    constructor(
        private router: Router,
        private store: StoreService,
        private routerService: RouterService,
        private msgService: NzMessageService,
        private route: ActivatedRoute,
        private getLiveStreamer: LiveStreamGQL,
        private startLive: StartLiveStreamV2GQL,
        private pinLiveStreamProductGQL: IsPinLiveStreamProductGQL,
        private turnOffLiveStream: StopLiveStreamV2GQL,
        private modalService: NzModalService,
        private translateService: TranslateService,
        private cartService: CartService,
        private nzMessageService: NzMessageService,
        private deleteProduct: DeleteLiveStreamProductGQL,
        private deviceService: DeviceDetectorService,
        private websocketService: WebsocketService,
        private liveStreamProducts: GetLiveStreamProductsGQL,
        private utilsService: UtilsService,
    ) {
        this.websocketService.connect(`${environment.websocketUrl}`);
    }

    streamUrl;
    totalProductsInCart = 0;
    timeShowPinnedProduct = 20;
    pageSize = 50;
    pageIndex = 1;

    playerWidth: number;
    playerHeight: number;

    typeRole = ERole;
    role: ERole;
    productPinned?: ILiveStream['liveStreamProducts'][0];
    shops: GetShopProductsQuery['shopProducts'] = [];
    Math = Math;
    ESelectedTab = ESelectTabLiveStream;
    activeUser: GetActivityUserQuery;

    drawerMenu = [
        {
            text: 'products.products-management',
            src: 'assets/icons/live-stream/product-management.svg',
            routerLink: 'product-management',
        },
        {
            text: 'profile.vouchers.voucher_management',
            src: 'assets/icons/live-stream/voucher-management.svg',
            routerLink: 'vouchers-live-stream',
        },
    ];

    streamerId: number;
    roomKey;
    dataCreateLiveStreamer?: LiveAddFieldViewerCount;
    interval;
    ngOnInit() {
        if (!this?.activeUser?.user?.id) {
            this.store.activeUser$.subscribe((data) => {
                if (data?.user?.id) {
                    this.activeUser = data;
                }
            });
        }
        this.streamerId = Number(this.route.snapshot.params['id']);

        if (!this.streamerId) {
            return;
        }
        if (!this.dataCreateLiveStreamer) {
            this.handleGetLivestream();
        }

        setTimeout(() => {
            this.routerService.triggerMobileTransparentLayoutChange(true);
            this.isShowModalLinkLiveStream = true;
        }, 1);

        this.websocketService.messageReceived.subscribe((message: string) => {
            const msgObj = JSON.parse(message);

            if (msgObj.type === 'LIVE_MAP') {
                this.dataCreateLiveStreamer = {
                    ...this.dataCreateLiveStreamer,
                    viewerCount:
                        msgObj.data[this.dataCreateLiveStreamer?.channelId]
                            ?.viewerCount,
                };
            }
        });
    }

    ngOnDestroy(): void {
        this.websocketService.closeConnection();

        this.toggleLockScroll(false);
    }

    handleGetLivestream(
        options?: QueryOptionsAlone<
            Exact<{ where: LiveStreamWhereUniqueInput }>,
            LiveStreamQuery
        >,
    ) {
        this.getLiveStreamer
            .fetch(
                {
                    where: {
                        id: this.streamerId,
                    },
                },
                options,
            )
            .subscribe(({ data }) => {
                if (data?.liveStream.__typename === 'LiveStream') {
                    this.dataCreateLiveStreamer = data.liveStream as any;
                    this.streamUrl = data.liveStream.streamUrl;
                    this.roomKey = data.liveStream.roomId;
                    this.getTotalProductsInCart();
                    this.handleShowPinProduct();

                    this.isMobile = this.deviceService.isMobile();

                    this.playerWidth = this.isMobile ? window.innerWidth : 600;
                    this.playerHeight = window.innerHeight;
                    this.store.activeUser$.subscribe((data) => {
                        if (
                            data?.user &&
                            this.dataCreateLiveStreamer?.host?.id
                        ) {
                            if (
                                this.dataCreateLiveStreamer?.host?.id ===
                                data.user?.id
                            ) {
                                this.role = ERole.HOST;
                            } else {
                                this.router.navigateByUrl(
                                    '/profile/merchant/live-stream-management',
                                );
                                this.role = ERole.VIEWER;
                            }
                        }
                    });
                }
            });

        this.websocketService.messageReceived.subscribe((message: string) => {
            const msgObj = JSON.parse(message);

            if (msgObj.type === 'LIVE_INFORMATION') {
                this.dataCreateLiveStreamer = {
                    ...this.dataCreateLiveStreamer,
                    viewerCount: msgObj.viewerCount,
                };
            }
        });
    }

    onRefreshProduct() {
        this.getLiveStreamer
            .fetch(
                {
                    where: {
                        id: this.streamerId,
                    },
                },
                {
                    fetchPolicy: 'no-cache',
                },
            )
            .subscribe(({ data }) => {
                if (data.liveStream.__typename === 'LiveStream') {
                    this.dataCreateLiveStreamer = data.liveStream;
                    this.handleShowPinProduct();
                }
            });
    }

    handleShowPinProduct() {
        clearInterval(this.interval);
        if (this.dataCreateLiveStreamer.liveStreamProducts?.length === 0) {
            return;
        }

        const productPinned =
            this.dataCreateLiveStreamer.liveStreamProducts.filter(
                (liveStreamProduct) => liveStreamProduct.isCurrentLive === true,
            )[0];

        if (!productPinned?.id) {
            this.productPinned =
                this.dataCreateLiveStreamer.liveStreamProducts[0];
            this.interval = setInterval(() => {
                this.isShowPinned = false;
                setTimeout(() => {
                    this.isShowPinned = true;
                }, 3 * 1000);
            }, this.timeShowPinnedProduct * 1000);

            setTimeout(() => {
                clearInterval(this.interval);
                // clearInterval(interval);
                this.timeShowPinnedProduct = 10;
                this.interval = setInterval(() => {
                    this.isShowPinned = false;
                    setTimeout(() => {
                        this.isShowPinned = true;
                    }, 3 * 1000);
                }, this.timeShowPinnedProduct * 1000);
            }, this.timeShowPinnedProduct * 1000);
        } else {
            this.isShowPinned = true;
            this.productPinned = productPinned;
        }
    }

    close() {
        this.isShowProductLive = false;
        this.isShowModalLinkLiveStream = false;

        this.toggleLockScroll(false);
    }

    toggleShowProductLive() {
        this.isShowProductLive = !this.isShowProductLive;

        this.toggleLockScroll(this.isShowProductLive);
    }

    handleOffLiveStream() {
        this.modalService.confirm({
            nzClosable: false,
            nzClassName: 'normal-modal',
            nzTitle: this.translateService.instant('live.confirm_end_live'),
            nzContent: this.translateService.instant(
                'live.description_end_live',
            ),
            nzCentered: true,
            nzOkType: 'primary',
            nzOkText: this.translateService.instant('live.end_now'),
            nzOnOk: () => {
                this.turnOffLiveStream
                    .mutate({
                        where: {
                            id: this.streamerId,
                        },
                    })
                    .subscribe(({ data }) => {
                        if (data.stopLiveStreamV2.__typename === 'LiveStream') {
                            this.msgService.success(
                                this.translateService.instant(
                                    'live.live_streaming_turned_off',
                                ),
                            );

                            this.router.navigateByUrl(
                                '/profile/merchant/live-stream-management',
                            );
                        }
                        if (
                            data.stopLiveStreamV2.__typename ===
                                'LiveStreamError' ||
                            data.stopLiveStreamV2.__typename === 'CommonError'
                        ) {
                            this.msgService.error(
                                this.translateService.instant(
                                    data.stopLiveStreamV2.message ||
                                        'common.error_500',
                                ),
                            );
                        }
                    });
            },
            nzCancelText: this.translateService.instant('common.cancel'),
        });
    }

    handleClosePinnedProduct() {
        this.isShowPinned = false;
        clearInterval(this.interval);

        // setTimeout(() => {
        this.handleShowPinProduct();
        // }, 1);
    }

    copyInputMessage(inputElement) {
        inputElement.select();
        inputElement.setSelectionRange(0, 99999); // For mobile devices
        navigator.clipboard.writeText(inputElement.value);
        this.msgService.success(this.translateService.instant('common.copied'));
    }
    goLive() {
        if (this.dataCreateLiveStreamer.status === LiveStreamStatus.CREATED) {
            this.isLoadingGoLive = true;
            this.startLive
                .mutate({ where: { id: this.streamerId } })
                .subscribe(({ data }) => {
                    if (data.startLiveStreamV2.__typename === 'LiveStream') {
                        setTimeout(() => {
                            this.router.navigate(['/live'], {
                                queryParams: {
                                    contentId: this.streamerId,
                                    tt: 7,
                                },
                            });
                        }, 2 * 1000);
                        return;
                    }
                });
        } else {
            this.msgService.error(
                this.translateService.instant('live.error_go_live'),
            );
        }
    }
    handleCancel() {
        this.isLoadingGoLive = false;
    }

    handlePinProduct(product: ILiveStream['liveStreamProducts'][0]) {
        if (product.id && !product.isCurrentLive && this.role === ERole.HOST) {
            this.productPinned = product;
            clearInterval(this.interval);

            this.pinLiveStreamProductGQL
                .mutate({
                    where: {
                        id: product.id,
                        liveStreamId: this.streamerId,
                    },
                })
                .subscribe(({ data }) => {
                    if (
                        data.pinLiveStreamProduct.__typename ===
                        'LiveStreamProduct'
                    ) {
                        this.handleGetLivestream({ fetchPolicy: 'no-cache' });
                    }
                });
        } else {
            // handle buy now
        }
    }

    addProductToCart(product: ILiveStream['liveStreamProducts'][0]) {
        this.cartService
            .addProductToCard({
                shopId: product.shopProduct.shop.id,
                variantId: product.shopProduct.productVariants[0].id,
                quantity: 1,
            })
            .subscribe({
                error: () => this.nzMessageService.error('common.error_500'),
                complete: () => {
                    this.getTotalProductsInCart();

                    this.nzMessageService.success(
                        this.translateService.instant('cart.added'),
                    );
                },
            });
    }

    getTotalProductsInCart() {
        this.cartService.getTotalProductsInCart().subscribe((data) => {
            this.totalProductsInCart = data;
        });
    }

    handleDeleteProduct(product: ILiveStream['liveStreamProducts'][0]) {
        this.deleteProduct
            .mutate({
                where: {
                    id: product.id,
                },
            })
            .subscribe(({ data }) => {
                if (
                    data.deleteLiveStreamProduct.__typename ===
                    'LiveStreamProduct'
                ) {
                    this.dataCreateLiveStreamer = {
                        ...this.dataCreateLiveStreamer,
                        liveStreamProducts:
                            this.dataCreateLiveStreamer.liveStreamProducts.filter(
                                (liveStreamProduct) =>
                                    liveStreamProduct.id !== product.id,
                            ),
                    };
                    this.handleShowPinProduct();

                    this.nzMessageService.success(
                        this.translateService.instant('action.delete_success'),
                    );
                }
            });
    }

    toggleShowDescription() {
        this.isShowDescription = !this.isShowDescription;
    }

    onPlayerReady(isPlayable: boolean) {
        if (isPlayable) {
            this.isShowVideo = true;
        }
    }

    handleSuccessEditPrice() {
        this.handleGetLivestream({ fetchPolicy: 'no-cache' });
    }

    toggleLockScroll(isLock: boolean) {
        this.utilsService.toggleLockScroll(isLock);
    }

    changePositionSuccess(data) {
        this.dataCreateLiveStreamer.liveStreamProducts = data;
        this.handleShowPinProduct();
    }
}
