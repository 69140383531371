import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-root-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild('inputSearch') inputSearch: ElementRef;

    @Input() isDisabled = false;
    @Input() isUseSmallSearchBar = false;
    @Input() keyword = '';

    @Output() searchEvent = new EventEmitter<string>();
    @Output() enterEvent = new EventEmitter<string>();

    timeout: any;

    searchForm: FormGroup | undefined;

    ngOnInit(): void {
        this.searchForm = new FormGroup({
            searchFormControl: new FormControl(this.keyword),
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.searchForm &&
            this.searchForm.patchValue({
                searchFormControl: changes.keyword.currentValue,
            });
    }

    ngOnDestroy(): void {
        clearTimeout(this.timeout);
    }

    setSearchValue(value: string) {
        this.searchForm.controls.searchFormControl.setValue(value);
    }

    onTyping() {
        clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
            this.searchEvent.next(
                this.searchForm.controls.searchFormControl.value,
            );
        }, 300);
    }

    onSubmit() {
        clearTimeout(this.timeout);

        this.inputSearch.nativeElement.blur();
        this.enterEvent.next(this.searchForm.controls.searchFormControl.value);
    }

    onClearSearch() {
        this.searchForm.controls.searchFormControl.setValue('');

        this.focus();
    }

    focus() {
        this.inputSearch.nativeElement.focus();
    }
}
