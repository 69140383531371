import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
    HttpClientModule,
    HTTP_INTERCEPTORS,
    HttpClient,
} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { provideMarkdown } from 'ngx-markdown';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CookieModule } from 'ngx-cookie';
import { ServiceWorkerModule } from '@angular/service-worker';

// config angular i18n
import { registerLocaleData } from '@angular/common';
import { NZ_I18N, en_US, vi_VN } from 'ng-zorro-antd/i18n';
import en from '@angular/common/locales/en';
import vi from '@angular/common/locales/vi';
registerLocaleData(en);
registerLocaleData(vi);

import { GlobalErrorHandler } from '@app/core/handlers';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import {
    JwtInterceptor,
    ErrorInterceptor,
    UuidInterceptor,
    CacheInterceptor,
} from '@app/core/http-interceptors';
import { GraphQLModule } from './graphql.module';
import { environment } from '@environments/environment';
import { SharedModule } from '@app/shared/shared.module';
import { BackgroundTaskService } from './core/services/background-task.service';
import { AuthService } from './auth/shared/services/auth.service';
import { AuthModule } from './auth/auth.module';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { RouterModule, RouterOutlet } from '@angular/router';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

export function basicLoader(auth: AuthService) {
    return async () => auth.getMe();
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        GraphQLModule,
        RouterOutlet,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        CookieModule.withOptions(),
        SharedModule,
        AuthModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        }),
    ],
    providers: [
        provideMarkdown(),
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: UuidInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: NZ_I18N, useValue: en_US },
        { provide: NZ_I18N, useValue: vi_VN },
        DeviceDetectorService,
        BackgroundTaskService,
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideFirestore(() => getFirestore()),
        provideMessaging(() => getMessaging()),
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
