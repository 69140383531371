import { Injectable } from '@angular/core';
import { GetActivityUserQuery, GetMeQuery } from '@app/common/generated-types';
import { BehaviorSubject, map } from 'rxjs';

interface IBodySaveEmailPasswordSignup {
    email: string;
    password: string;
}
interface ApplicationState {
    userProfile: GetMeQuery['me'] | null;
    activeUser?: GetActivityUserQuery;
    bodySaveEmailPassword?: IBodySaveEmailPasswordSignup;
    isShowLogin: boolean;
}
const initialState: ApplicationState = {
    userProfile: null,
    activeUser: null,
    bodySaveEmailPassword: undefined,
    isShowLogin: false,
};
@Injectable({
    providedIn: 'root',
})
export class StoreService {
    private readonly store$ = new BehaviorSubject<ApplicationState>(
        initialState,
    );
    readonly userProfile$ = this.store$.pipe(map((state) => state.userProfile));
    isShowLogin$ = this.store$.pipe(map((state) => state.isShowLogin));
    readonly activeUser$ = this.store$.pipe(map((state) => state.activeUser));
    readonly bodySaveEmailPassword$ = this.store$.pipe(
        map((state) => state.bodySaveEmailPassword),
    );
    // readonly countView
    constructor() {}

    get me() {
        return this.store$.value.userProfile;
    }
    get activeUser() {
        return this.store$.value.activeUser;
    }
    get emailPasswordSignup() {
        return this.store$.value.bodySaveEmailPassword;
    }
    get showLogin() {
        return this.store$.value.isShowLogin;
    }
    get userProfile() {
        return this.store$.value.userProfile;
    }

    clear() {
        this.store$.next({
            ...this.store$.value,
            userProfile: undefined,
            activeUser: undefined,
            bodySaveEmailPassword: undefined,
        });
    }

    setUserProfile(userProfile: GetMeQuery['me']) {
        this.store$.next({
            ...this.store$.value,
            userProfile,
        });
    }
    setActiveUser(activeUser: GetActivityUserQuery) {
        this.store$.next({
            ...this.store$.value,
            activeUser,
        });
    }

    setEmailPasswordSignup(
        bodySaveEmailPassword: IBodySaveEmailPasswordSignup,
    ) {
        this.store$.next({
            ...this.store$.value,
            bodySaveEmailPassword,
        });
    }

    setShowLogin(isShowLogin: boolean) {
        this.store$.next({
            ...this.store$.value,
            isShowLogin,
        });
    }
}
