import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface BackgroundTask {
    key: string;
    name: string;
    state: 'PENDING' | 'RUNNING' | 'COMPLETE' | 'ERROR';
    task: Observable<unknown>;
    destinationUrlPath?: string;
}

@Injectable({
    providedIn: 'root',
})
export class BackgroundTaskService {
    backgroundTasksubject: BehaviorSubject<BackgroundTask[]> =
        new BehaviorSubject<BackgroundTask[]>([]);

    addTask(task: BackgroundTask): void {
        this.backgroundTasksubject.next(
            this.backgroundTasksubject.value.concat(task),
        );
    }

    deleteTask(key: string) {
        this.backgroundTasksubject.next(
            this.backgroundTasksubject.value.filter((item) => item.key !== key),
        );
    }

    changeTaskState(
        key: string,
        state: 'PENDING' | 'RUNNING' | 'COMPLETE' | 'ERROR',
    ) {
        this.backgroundTasksubject.next(
            this.backgroundTasksubject.value.map((item) => {
                if (item.key === key) {
                    return { ...item, state };
                }
                return item;
            }),
        );
    }
}
