<div class="card-3">
	<div class="setting-header sticky-header">
		<div (click)="backClicked()" class="arow-left">
			<img src="assets/icons/left-arrow1.svg" alt="" />
		</div>
		<div class="setting-title"></div>
	</div>

	<div class="text-center">
		<div class="d-flex justify-center">
			<img src="assets/images/paper_airplane.png" width="152" height="124" />
		</div>

		<div>
			<p class="verify-phonenumber-title pt-4">Xác minh số điện thoại</p>
			<p class="verify-phonenumber-content">
				Mã xác thực đã được gửi đến số điện thoại
			</p>
			<p class="verify-phonenumber-content mb-6">{{ phoneNumber }}</p>

			<div class="d-flex justify-center mb-4">
				<app-root-otp-input
					(otpOut)="handleOtpOut($event)"
				></app-root-otp-input>
			</div>

			<p class="verify-phonenumber-try-sent pb-8" (click)="tryingToSentOTP()">
				Gửi lại mã ({{ timeRemaining$ | async | date : 'mm:ss' }})
			</p>

			<div>
				<button
					class="btn btn-confirm py-3 cursor-pointer {{
						this.otp?.length !== 6 ? 'disabled' : ''
					}}"
					(click)="verificationOTP()"
				>
					Xác nhận
				</button>
			</div>
		</div>
	</div>
</div>
