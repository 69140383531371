import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    DeleteEcommercePromotionGQL,
    EcommerceFlashSaleQuery,
} from '@app/common/generated-types';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
    selector: 'app-root-flash-sale-card',
    templateUrl: './flash-sale-card.component.html',
    styleUrl: './flash-sale-card.component.scss',
})
export class FlashSaleCardComponent implements OnInit {
    constructor(
        private deleteEcommercePromotionGQL: DeleteEcommercePromotionGQL,
        private msgService: NzMessageService,
        private translateService: TranslateService,
    ) {}
    maxCount = 5;
    data: EcommerceFlashSaleQuery['ecommerceFlashSale'][0]['promotions'];
    @Input() voucher: EcommerceFlashSaleQuery['ecommerceFlashSale'][0];
    @Output() deleteSuccess = new EventEmitter();
    handleDisplayAmountHighest(
        voucher: EcommerceFlashSaleQuery['ecommerceFlashSale'][0],
    ) {
        return (
            (voucher.promotions.reduce((prev, current) =>
                prev && prev.amount > current.amount ? prev : current,
            )?.amount || 0) + '%'
        );
    }

    ngOnInit(): void {
        this.data = this.voucher.promotions.slice(0, this.maxCount);
    }

    isFlashSaleInprogress(
        voucher: EcommerceFlashSaleQuery['ecommerceFlashSale'][0],
    ) {
        const newDate = new Date();
        if (
            new Date(voucher.fromTime) <= newDate &&
            new Date(voucher.toTime) > newDate
        ) {
            return 'inprogress';
        } else if (
            new Date(voucher.fromTime) > newDate &&
            newDate < new Date(voucher.toTime)
        ) {
            return 'upcomming';
        } else {
            return 'ended';
        }
    }

    handleRouterLink(
        voucher: EcommerceFlashSaleQuery['ecommerceFlashSale'][0],
    ) {
        if (new Date() > new Date(voucher.promotions?.[0]?.fromTime)) {
            return;
        }
        return [voucher.promotions?.[0]?.id];
    }

    handleCheckUpcoming(date) {
        return new Date(date) < new Date() ? true : false;
    }

    handleDelete(code: string) {
        if (!code) return;
        this.deleteEcommercePromotionGQL
            .mutate({
                where: {
                    code,
                },
            })
            .subscribe(({ data }) => {
                if (data.deleteEcommercePromotion) {
                    this.msgService.success(
                        this.translateService.instant(
                            'notification.create_success',
                        ),
                    );
                    this.deleteSuccess.emit(code);
                    // this.data = this.data.filter(
                    // 	(voucher) => voucher.promotions[0].id !== id,
                    // );
                }
            });
    }
}
