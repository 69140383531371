import { Component } from '@angular/core';

import { ScrollDetectService } from '@app/core/services';
import { UtilsService } from '@app/core/services/utils.service';

@Component({
    selector: 'app-root-scroll-to-top-button',
    templateUrl: './scroll-to-top-button.component.html',
    styleUrls: ['./scroll-to-top-button.component.scss'],
})
export class ScrollToTopButtonComponent {
    isShow: boolean;

    constructor(
        private scrollDetectService: ScrollDetectService,
        private utilsService: UtilsService,
    ) {
        this.scrollDetectService.windowFrameChange.subscribe((value) => {
            this.isShow = value;
        });
    }

    gotoTop() {
        this.utilsService.scrollPosToTop();
    }
}
