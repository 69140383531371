import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import {
    ERole,
    ILiveStream,
} from '../live-stream-detail-by-user/live-stream-detail-by-user.component';
import { ActivatedRoute, Router } from '@angular/router';
import { CartService } from '@app/home/shared/services/cart.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { TranslateService } from '@ngx-translate/core';
import { ChangePositionLiveStreamProductV2GQL } from '@app/common/generated-types';
import { CdkDrag, CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
@Component({
    selector: 'app-root-product-live-stream-card',

    templateUrl: './product-live-stream-card.component.html',
    styleUrl: './product-live-stream-card.component.scss',
})
export class ProductLiveStreamCardComponent implements OnInit, OnChanges {
    @Input() productPinned: (ILiveStream['liveStreamProducts'][number] & {
        discountPercent?: number;
        discountPrice?: number;
    })[] = undefined;
    @Input() dataCreateLiveStreamer: ILiveStream = undefined;
    @Input() role: ERole;
    @Input() type: 'pick' | 'pin';

    @Output() toggleShowProductPinned = new EventEmitter();
    @Output() handleDeleteProductEvent = new EventEmitter();
    @Output() addProductToCartEvent = new EventEmitter();
    @Output() handlePinProductEvent = new EventEmitter();
    @Output() successEditPrice = new EventEmitter();
    @Output() changePositionSuccess = new EventEmitter();
    isShowProductLive = false;
    isShowCustomProduct = false;
    Math = Math;
    Number = Number;

    typeRole = ERole;
    id: number;
    selectedProduct: ILiveStream['liveStreamProducts'][0];

    constructor(
        private modalService: NzModalService,
        private changePositionProduct: ChangePositionLiveStreamProductV2GQL,
        private router: Router,
        private route: ActivatedRoute,
        private cartService: CartService,
        private nzMessageService: NzMessageService,
        private translateService: TranslateService,
    ) {
        const id = this.route.snapshot.params?.id;

        if (id) {
            this.id = Number(id);
        }
        // this.id = this.route.snapshot
    }

    canDrop() {
        if (this.role) return this.role === ERole.HOST;
    }

    drop(event) {
        if (this.role !== ERole.HOST) return;
        moveItemInArray(
            this.productPinned,
            event.previousIndex,
            event.currentIndex,
        );
    }

    ngOnInit(): void {}

    ngOnChanges(): void {
        this.productPinned = this.productPinned.map((product) => {
            const flashSalePrice =
                product.shopProduct.productVariants[0].price -
                (product.shopProduct.flashsale?.length === 0
                    ? 0
                    : (product.shopProduct.productVariants[0].price / 100) *
                      product.shopProduct.flashsale[0].amount);

            let activePromotion = null;
            let tempMaxPrice = 0;
            const tempPrice =
                product.shopProduct.productVariants[0].price -
                (product.shopProduct.flashsale?.length === 0
                    ? 0
                    : (product.shopProduct.productVariants[0].price / 100) *
                      product.shopProduct.flashsale[0].amount);

            product.shopProduct.promotions &&
                product.shopProduct.promotions.map((item) => {
                    if (
                        tempPrice >= item.minimumPrice &&
                        item.minimumPrice > tempMaxPrice
                    ) {
                        activePromotion = item;

                        tempMaxPrice = item.minimumPrice;
                    }
                });
            const tempUnit = activePromotion
                ? this.getMaxDiscountOfVoucher(
                      activePromotion.amount,
                      activePromotion.maximumPrice,
                      flashSalePrice,
                  )
                : 0;

            const discountPercent =
                100 -
                (flashSalePrice /
                    product.shopProduct.productVariants[0].originalPrice) *
                    100 -
                (tempUnit / flashSalePrice) * 100;

            const discountPrice = flashSalePrice - tempUnit;

            return {
                ...product,
                discountPercent: discountPercent ?? 0,
                discountPrice: discountPrice ?? 0,
            };
        });
    }

    getMaxDiscountOfVoucher(
        voucherDiscount: number,
        voucherMaxDiscount: number,
        totalPrices: number,
    ) {
        const isFinalCostDeductGreaterThanVoucherMaxDiscount =
            (totalPrices / 100) * voucherDiscount > voucherMaxDiscount;

        return isFinalCostDeductGreaterThanVoucherMaxDiscount
            ? voucherMaxDiscount
            : (totalPrices / 100) * voucherDiscount;
    }

    handleClosePinnedProduct() {
        this.toggleShowProductPinned.emit();
        // this.isShowPinned = false;
        // clearInterval(this.interval);
    }

    handleDeleteProduct(product: ILiveStream['liveStreamProducts'][0]) {
        this.modalService.error({
            nzClosable: false,
            nzClassName: 'normal-modal',
            nzTitle: this.translateService.instant(
                'action.confirm_delete_product',
            ),
            nzContent: this.translateService.instant(
                'action.confirm_delete_product_description',
            ),
            nzCentered: true,
            nzOkType: 'primary',
            nzOkText: this.translateService.instant('cart.delete'),
            nzOkDanger: true,
            nzOnOk: () => {
                this.handleDeleteProductEvent.emit(product);

                // this.router.('/auth/login');
            },
            nzCancelText: this.translateService.instant('common.cancel'),
        });
    }
    addProductToCart(product: ILiveStream['liveStreamProducts'][0]) {
        this.addProductToCartEvent.emit(product);
    }
    handlePinProduct(product: ILiveStream['liveStreamProducts'][0]) {
        this.handlePinProductEvent.emit(product);
    }

    buyProduct(
        product: ILiveStream['liveStreamProducts'][0],
        vouchers?: {
            voucherId: number;
            voucherType: string;
            voucherOrigin: string;
        }[],
    ) {
        this.cartService
            .addProductToCard({
                shopId: product.shopProduct.shop.id,
                variantId: product.shopProduct.productVariants[0].id,
                quantity: 1,
            })
            .subscribe({
                error: () => this.nzMessageService.error('Có lỗi sảy ra.'),
                complete: () => {
                    sessionStorage.setItem(
                        'quickBuyInit',
                        JSON.stringify({
                            shopId: product.shopProduct.shop.id,
                            productId: product.shopProduct.id,
                            quantity: 1,
                        }),
                    );
                    vouchers &&
                        sessionStorage.setItem(
                            'voucherInit',
                            JSON.stringify(vouchers),
                        );
                    this.router.navigate(['/cart'], {
                        queryParams: {
                            skip: true,
                        },
                    });
                },
            });
    }

    handleEditPrice(product: ILiveStream['liveStreamProducts'][0]) {
        this.isShowCustomProduct = true;
        document.body.classList.add('lock-container-scroll');

        this.selectedProduct = product;
    }

    handleCloseShowCustomProduct() {
        this.isShowCustomProduct = false;
        document.body.classList.remove('lock-container-scroll');
    }

    handleSuccess(data: {
        variantId: number;
        price: number;
        originalPrice: number;
        quantity: number;
    }) {
        this.productPinned = this.productPinned.map((product) => {
            const idsVariant = product.shopProduct.productVariants.map(
                (variant) => variant.id,
            );

            if (idsVariant.includes(data.variantId)) {
                return {
                    ...product,
                    shopProduct: {
                        ...product.shopProduct,
                        productVariants: [
                            {
                                ...product.shopProduct.productVariants[0],
                                originalPrice: data.originalPrice,
                                price: data.price,
                            },
                        ],
                    },
                };
            }

            return product;
        });

        this.isShowCustomProduct = false;

        this.successEditPrice.emit();
    }

    changePosition(index: number, event: Event) {
        event.preventDefault();

        event.stopPropagation();
        if (index === 0) return;

        if (this.productPinned[index - 1].isCurrentLive) {
            return;
        }
        const data = this.productPinned[index - 1];

        this.productPinned.splice(index - 1, 1);
        this.productPinned.splice(index, 0, data);

        this.changePositionProduct
            .mutate({
                where: {
                    id: this.id,
                },
                data: this.productPinned.map((product) => ({
                    code: product.code,
                })),
            })
            .subscribe((data) => {
                if (
                    data.data.changePositionLiveStreamProductV2.__typename ===
                    'LiveStream'
                ) {
                    this.changePositionSuccess.emit(this.productPinned);
                }
            });
        // debugger;
        // debugger;
        // this.ids.emit({
        // 	selected: this.setOfCheckedId,
        // 	rootData: this.productPinned,
        // });
    }
}
