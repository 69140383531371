import { Component, Input } from '@angular/core';
import * as moment from 'moment';

@Component({
    selector: 'app-root-live-voucher-list',
    templateUrl: './live-voucher-list.component.html',
    styleUrl: './live-voucher-list.component.scss',
})
export class LiveVoucherListComponent {
    @Input() vouchers = [];

    formatDate(dateStr: string, format: string) {
        return moment(dateStr).format(format);
    }
}
