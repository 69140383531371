import { gql } from 'apollo-angular';

export const GET_CART_PRODUCTS_BY_SHOPS = gql`
    query CartProductsOfShop(
        $where: CartProductWhereInput
        $orderBy: CartProductOrderByInput
        $offset: Int
        $limit: Int
    ) {
        cartProductsOfShop(
            where: $where
            orderBy: $orderBy
            skip: $offset
            first: $limit
        ) {
            __typename
            shop {
                __typename
                id
                name
                cover {
                    __typename
                    url
                }
                user {
                    __typename
                    id
                    account
                    avatar {
                        __typename
                        url
                    }
                }
            }
            cartProducts {
                __typename
                id
                variant {
                    __typename
                    id
                    productId
                    isVisible
                    shopProduct {
                        __typename
                        id
                        reviewtyProductId
                        name
                        isBlocked
                        description
                        images {
                            __typename
                            urls {
                                __typename
                                url
                            }
                        }
                        flashsale {
                            id
                            entity
                            entityType
                            title
                            amount
                            amountUnit
                            minimumPrice
                            maximumPrice
                        }
                        reviewtyProduct {
                            measure
                        }
                    }
                    quantity
                    price
                    originalPrice
                    soldQuantity
                    attributes {
                        __typename
                        weight
                        store
                    }
                }
                quantity
            }
        }
        cartProductsOfShopConnection(where: $where) {
            __typename
            aggregate {
                __typename
                countProductsOfCart
            }
        }
    }
`;

export const GET_TOTAL_CART_PRODUCTS_BY_SHOPS = gql`
    query totalCartProductsOfShop($where: CartProductWhereInput) {
        cartProductsOfShopConnection(where: $where) {
            __typename
            aggregate {
                __typename
                countProductsOfCart
            }
        }
    }
`;

export const ADD_PRODUCT_TO_CART = gql`
    mutation createCartProductV2($data: CartProductInsertInput!) {
        createCartProductV2(data: $data) {
            ... on CartProduct {
                id
                quantity
            }
            ... on CartProductCreateError {
                code
                message
                status
            }
            ... on CommonError {
                message
                status
            }
        }
    }
`;

export const UPDATE_CART_PRODUCT = gql`
    mutation updateCartProduct(
        $data: CartProductUpdateInput!
        $where: CartProductWhereInput!
    ) {
        updateCartProduct(data: $data, where: $where) {
            id
        }
    }
`;

export const DELETE_CART_PRODUCT = gql`
    mutation deleteCartProduct($where: CartProductWhereInput!) {
        deleteCartProduct(where: $where) {
            id
        }
    }
`;
