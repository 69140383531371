<nz-modal
  [(nzVisible)]="isMobileConnectDialogVisible"
  (nzOnCancel)="handleCancel()"
  nzClosable="false"
  nzMaskClosable = "true"
  [nzFooter]="null"
  nzCentered="true"
  nzWidth="auto"
  [nzBodyStyle]="{'border-radius': '16px'}"
>
  <ng-container *nzModalContent>
    <ng-template *ngTemplateOutlet="mobileConnectTemplate"></ng-template>
  </ng-container>
</nz-modal>

<nz-drawer
  [nzClosable]="false"
  [nzVisible]="isMobileConnectBottomSheetVisible"
  [nzPlacement]="'bottom'"
  (nzOnClose)="handleCancel()"
>
  <ng-container *nzDrawerContent>
    <div class="mobile-connect-bottom-sheet">
      <ng-template *ngTemplateOutlet="mobileConnectTemplate"></ng-template>
    </div>
  </ng-container>
</nz-drawer>

<ng-template #mobileConnectTemplate>
  <div class="mobile-connect">
    <img class="mobile-logo" src="assets/icons/mobile-logo.svg" alt="Logo Reviewty">
    <h2 class="title body-semiBold">Trải nghiệm đầy đủ trên ứng dụng</h2>
    <h3 class="text caption2-regular">Khám phá những review/ chia sẻ chân thật từ những người dùng thông thái và sáng tạo nội dung của riêng bạn</h3>
    <div class="qr">
      <img class="qr-img" src="assets/images/app-qr.png" alt="Quét mã QR để tải app">
      <h3 class="qr-alt caption1-regular">Quét mã QR để tải app</h3>
    </div>
    <a *ngIf="deviceType !== 'Windows'" href="{{deviceType === 'iOS' ? appStoreUri : playStoreUri}}" target="_blank" class="col-12">
      <button class="btn-download-now body-semiBold">Tải app ngay</button>
    </a>
    <div class="app-link" *ngIf="deviceType === 'Windows'">
      <a href="{{appStoreUri}}" target="_blank">
        <img src="assets/icons/app-store.svg" alt="Download on the App Store">
      </a>
      <a href="{{playStoreUri}}" target="_blank">
        <img src="assets/icons/google-play.svg" alt="Get it on the Google Play">
      </a>
    </div>
    <button class="btn-download-later caption2-regular" (click)="handleCancel()">Để sau</button>
  </div>
</ng-template>
