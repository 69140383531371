import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ChatService } from '@app/shared/providers/chat.service';

@Injectable({
    providedIn: 'root',
})
export class UtilsService {
    constructor(
        @Inject(DOCUMENT) private document: Document,
        private router: Router,
        private chatService: ChatService,
        private metaService: Meta,
    ) {}

    toggleLockScroll(isLock: boolean) {
        isLock
            ? this.document.body.classList.add('lock-scroll')
            : this.document.body.classList.remove('lock-scroll');
    }

    toggleLockZoom(isLock: boolean) {
        this.metaService.removeTag('name="viewport"');

        if (isLock) {
            this.metaService.addTag({
                name: 'viewport',
                content: 'width=device-width, initial-scale=1, maximum-scale=1',
            });
        } else {
            this.metaService.addTag({
                name: 'viewport',
                content: 'width=device-width, initial-scale=1',
            });
        }
    }

    scrollPosToTop(behavior: ScrollBehavior = 'smooth') {
        window.scroll({
            top: 0,
            left: 0,
            behavior,
        });
    }

    scrollPosToBottom(behavior: ScrollBehavior = 'smooth') {
        window.scroll({
            top: document.body.scrollHeight,
            left: 0,
            behavior,
        });
    }

    scrollToPos(
        scrollPosKey: string = '',
        callBack: () => void,
        framePos: ScrollLogicalPosition = 'center',
        behavior: ScrollBehavior = 'instant',
    ) {
        if (!scrollPosKey) {
            return;
        }

        return setTimeout(() => {
            const elements = document.getElementsByClassName(scrollPosKey);
            if (elements.length == 0) {
                return;
            }

            elements[0].scrollIntoView({
                block: framePos,
                behavior: behavior,
            });

            callBack();
        }, 150);
    }

    hashtagsToHtmlTags(str: string) {
        return str.replace(
            /\B(#[a-zA-Z]+\b)(?!;)/g,
            '<span style="color:#0078FF;cursor:pointer;" contenteditable="false">$1</span>',
        );
    }

    aliasesToHtmlTags(str: string) {
        return str.replace(
            /\B(@[a-zA-Z]+\b)(?!;)/g,
            '<span style="color:#BA92D9;font-weight:600;cursor:pointer;" contenteditable="false">$1</span>',
        );
    }

    removeHtmlTagsFromStr(str: string) {
        return str.replace(/<[^>]*>/g, '');
    }

    removeImgTags(str: string) {
        return str.replace(/<img .*?>/g, '');
    }

    linksToAnchorTags(str: string) {
        return str.replace(
            /\bhttps?:\/\/\S+/gi,
            '<a href="$&" target="_blank" rel="nofollow">$&</a>',
        );
    }

    setCaretToEnd(target /*: HTMLDivElement*/) {
        const range = document.createRange();
        const sel = window.getSelection();
        range.selectNodeContents(target);
        range.collapse(false);
        sel.removeAllRanges();
        sel.addRange(range);
        target.focus();
        range.detach(); // optimization

        // set scroll to the end if multiline
        target.scrollTop = target.scrollHeight;
    }

    socialShare(data: { title: string; text: string; url: string }) {
        if (navigator.share) {
            navigator
                .share({
                    title: data.title,
                    text: data.text,
                    url: data.url,
                })
                .then(() => console.log('Successful share!'))
                .catch((error) => console.log('Error sharing: ', error));
        } else {
            alert('Share not supported!');
        }
    }

    copyTextToClipboard(text: string) {
        navigator.clipboard.writeText(text);
    }

    toInbox(
        targetUser: { avatarUrl: string; id: number; name: string },
        callback?: () => void,
    ) {
        if (!localStorage.getItem('me')) {
            return;
        }
        const currentUser = JSON.parse(localStorage.getItem('me'));
        if (!currentUser) {
            this.router.navigateByUrl('/auth/login');
        }

        this.chatService
            .getUserChatRoom(
                {
                    avatarUrl: currentUser.avatar,
                    id: currentUser.id,
                    name: currentUser.account,
                },
                {
                    avatarUrl: targetUser.avatarUrl,
                    id: targetUser.id,
                    name: targetUser.name,
                },
            )
            .then(({ roomId, roomInit }) => {
                const senderId = Object.keys(roomInit.counter).find(
                    (key) => key !== currentUser.id,
                );

                callback && callback();

                this.router.navigate([`/inbox/${roomId}`], {
                    queryParams: {
                        inboxId: targetUser.id,
                        account: targetUser.name,
                        avatarUrl: targetUser.avatarUrl,
                        unreadMsgCount:
                            roomInit.counter[senderId].numberOfSentMessages,
                    },
                });
            })
            .catch((err) => alert(err));
    }

    callPhoneNumber(phoneNumber: string) {
        window.open('tel:' + phoneNumber);
    }
}
