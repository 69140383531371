import { Directive, Input, HostBinding, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';

import { ViewHtmlDialogComponent } from '@app/shared/components/view-html-dialog/view-html-dialog.component';
import { UtilsService } from '../services';

@Directive({
    selector: '[appRootImgPreview]',
})
export class ImgPreviewDirective {
    @Input()
    @HostBinding('src')
    src: string;

    @HostListener('click') onClick() {
        this.utilsService.toggleLockScroll(true);

        const dialogRef = this.dialog.open(ViewHtmlDialogComponent, {
            height: '100vh',
            width: this.isMobile ? '100vw' : '600px',
            maxWidth: this.isMobile ? '100vw' : '600px',
            data: {
                injectHtmlStr: `
        <div style="
          height: calc(100vh - 42px);
          display: flex;
          flex-direction: column;
          justify-content: center;
          overflow-y: scroll;
          overflow-x: hidden;
        ">
          <img src="${this.src}" width="${
              (this.isMobile ? window.innerWidth : 600) - 32
          }">
          </div>
        `,
            },
        });
        dialogRef
            .afterClosed()
            .subscribe(() => this.utilsService.toggleLockScroll(false));
    }

    isMobile: boolean;

    constructor(
        private deviceService: DeviceDetectorService,
        private utilsService: UtilsService,
        private dialog: MatDialog,
    ) {
        this.isMobile = this.deviceService.isMobile();
    }
}
