export const DEFAULT_SEO_SOCIAL_SHARE_METADATA = {
    title: 'Reviewty Đánh giá mỹ phẩm, thực phẩm chức năng',
    description:
        'Review mỹ phẩm, review thực phẩm chức năng đầu tiên tại Việt Nam cùng với hàng trăm quà tặng hấp dẫn hàng tuần ✓1.000.000 thành viên ✓Hơn 300.000 đánh giá khách quan ✓40.000 sản phẩm',
};

export const DEFAULT_JSON_LD_WEBSITE = {
    name: 'Reviewty',
    url: 'https://review-ty.com',
    sameAs: [
        'https://www.facebook.com/globalreviewty',
        'https://www.instagram.com/reviewty.vn',
        'https://www.youtube.com/@reviewtychannel1863',
    ],
};

export const DEFAULT_JSON_LD_ORGANIZATION = {
    name: 'Công ty TNHH Reviewty',
    url: 'https://review-ty.com',
    logo: 'https://d1ip8wajnedch4.cloudfront.net/2021/04/07/efc8136c-aaec-4d0d-9068-e2fcb2b0b883.png',
    foundingDate: '2019',
    founders: [
        {
            '@type': 'Person',
            name: 'PARK JINKAM',
        },
    ],
    address: {
        '@type': 'PostalAddress',
        addressLocality: 'Hà Nội, Việt Nam',
        postalCode: '130000',
        streetAddress: 'Tòa Sunsquare số 21 Lê Đức Thọ, Mỹ Đình 2, Nam Từ Liêm',
    },
    contactPoint: {
        '@type': 'ContactPoint',
        contactType: 'customer support',
        telephone: '(+84) 961-554-388',
        email: 'support@review-ty.com',
    },
    sameAs: [
        'https://www.facebook.com/globalreviewty',
        'https://www.instagram.com/reviewty.vn',
        'https://www.youtube.com/@reviewtychannel1863',
    ],
};
