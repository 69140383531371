<div class="live-stream-list-wrapper">
	<nz-tabset
		[nzCentered]="true"
		(nzSelectChange)="handleChangeTab($event)"
		[(nzSelectedIndex)]="selectedIndex"
	>
		<nz-tab
			nzTitle="{{ 'profile.upcomming-live' | translate }}"
			class="bg-white"
		>
			<app-root-live-stream-section
				[selectedTab]="selectedTab"
				[currentTab]="LiveStreamStatus.CREATED"
			></app-root-live-stream-section>
		</nz-tab>
		<nz-tab
			nzTitle="{{ 'profile.inprogress-live' | translate }}"
			class="bg-white"
		>
			<app-root-live-stream-section
				[selectedTab]="selectedTab"
				[currentTab]="LiveStreamStatus.RUNNING"
			></app-root-live-stream-section>
		</nz-tab>
		<nz-tab nzTitle="{{ 'profile.ended-live' | translate }}" class="bg-white">
			<app-root-live-stream-section
				[selectedTab]="selectedTab"
				[currentTab]="LiveStreamStatus.STOPPED"
			></app-root-live-stream-section>
		</nz-tab>
	</nz-tabset>

	<div class="px-4 btn-create-container">
		<button
			[routerLink]="['create']"
			class="btn btn-rectangle__primary"
			(click)="handleCreate()"
		>
			{{ 'profile.create-live' | translate }}
		</button>
	</div>
</div>
