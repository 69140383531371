<div>
	<div>
		<div
			nz-row
			*ngFor="let product of productPinned; let i = index"
			class="bg-white p-3 my-3 d-flex rounded-4 relative product-card"
		>
			<div nz-col class="product-thumbnail-wrapper">
				<img
					class="w-full product-thumbnail"
					src="{{ product.shopProduct.images.urls[0].url }}"
				/>
				<div *ngIf="!product.isCurrentLive" class="product-index">
					<span>{{ Number(product.code) }}</span>
				</div>
				<ng-container *ngIf="product.isCurrentLive">
					<div class="mark popular-mark">
						<img src="assets/icons/live-popular-mark.svg" alt="popular mark" />
						<span>{{ 'common.outstanding' | translate }}</span>
					</div>
					<div *ngIf="role === typeRole.HOST" class="mark pin-mark">
						<img src="assets/icons/live-pin-mark.svg" alt="pin mark" />
						<span>{{ 'common.pined' | translate }}</span>
					</div>
				</ng-container>
			</div>
			<div nz-col class="card-body">
				<div class="pl-2 d-flex flex-col justify-between h-full">
					<p class="ellipsis-description-1 text-131518 font-medium text-4 product-name">
						{{ product.shopProduct.name }}
					</p>

                    <div class="voucher-list">
                        <div
                            class="voucher-item {{
                                item.type === 'FREESHIP'
                                ? 'shipping-voucher'
                                : 'discount-voucher'
                            }}"
                            *ngFor="let item of product.shopProduct.promotions"
                            (click)="buyProduct(product, [{
                                voucherId: item.id,
                                voucherType: item.type,
                                voucherOrigin: item.createdBy
                            }])"
                        >
                            <ng-container *ngIf="item.type === 'FREESHIP'">
                                <img
                                    src="assets/icons/live-freeship.svg"
                                    alt="Voucher icon"
                                />
                                <span>Freeship</span>
                            </ng-container>
                            <ng-container *ngIf="item.type === 'DISCOUNT'">
                                <span>Giảm {{item.amount + '%'}}</span>
                            </ng-container>
                        </div>
                    </div>

					<img
						*ngIf="type !== 'pick'"
						class="absolute r-0 x-btn"
						src="/assets/icons/close-filter.svg"
						(click)="handleClosePinnedProduct()"
					/>

					<span
						*ngIf="product.shopProduct.flashsale?.length !== 0"
						class="highlight-price"
					>
						<img
							src="assets/icons/live-price-highlight.svg"
							alt="Live price highlight"
						/>
						{{ 'live.flashsale' | translate }}
						{{ product.shopProduct.flashsale[0]?.amount }}%
					</span>
					<span class="text-9b9b9b text-3"
						>{{ 'product.sold' | translate }}
						{{ product.shopProduct.totalSoldQuantity }}
					</span>

					<div *ngIf="type === 'pin'; else pick" class="d-flex justify-between">
						<div class="d-flex flex-row-reverse">
							<div>
								<span class="text-3 text-9b9b9b line-through"
									>{{
										product.shopProduct?.productVariants[0]?.originalPrice
											| currencyByLocale
									}} </span
								><span class="text-d73f3f text-3"
									>({{
										-Math.floor(product.discountPercent)
									}}%)</span
								>
							</div>
							<div class="text-ba92d9 font-semibold mr-2">
								{{ product.discountPrice	| currencyByLocale }}
							</div>
						</div>

						<div class="d-flex items-center">
							<div>
								<button
									type="button"
									class="btn btn-rectangle__primary user-view font-medium"
									style="padding-top: 0; padding-bottom: 0"
									(click)="buyProduct(product)"
								>
									{{ 'common.buy' | translate }}
								</button>
							</div>
						</div>
					</div>

					<ng-template #pick>
						<div class="d-flex justify-between">
							<div>
								<div>
									<span class="text-3 text-9b9b9b line-through"
										>{{
											product.shopProduct.productVariants[0].originalPrice
												| currencyByLocale
										}} </span
									><span class="text-d73f3f text-3"
										>({{
											-Math.floor(product.discountPercent)
										}}%)</span
									>
								</div>
								<div class="text-ba92d9 font-semibold">
									{{ product.discountPrice	| currencyByLocale }}
								</div>
							</div>

							<div class="d-flex items-center">
								<img
									*ngIf="role === typeRole.HOST && i > 0"
									(click)="changePosition(i, $event)"
									style="padding: 4px; border-radius: 6px"
									class="mr-2 cursor-pointer"
									src="/assets/icons/change-position-icon.svg"
								/>
								<img
									*ngIf="role === typeRole.VIEWER"
									(click)="addProductToCart(product)"
									style="padding: 4px; background: #f4f4f4; border-radius: 6px"
									class="mr-2 cursor-pointer"
									src="/assets/icons/live-stream/add-to-cart.svg"
								/>
								<img
									*ngIf="role === typeRole.HOST"
									(click)="handleEditPrice(product)"
									class="cursor-pointer mr-2"
									src="/assets/icons/live-stream/edit.svg"
								/>

								<div>
									<button
										*ngIf="role === typeRole.HOST"
										(click)="handlePinProduct(product)"
										type="button"
										class="btn btn-rectangle__primary font-medium"
										style="min-width: 84px"
									>
										{{
											product.isCurrentLive
												? ('common.pined' | translate)
												: ('common.pin' | translate)
										}}
									</button>
									<button
										*ngIf="role !== typeRole.HOST"
										(click)="buyProduct(product)"
										type="button"
										class="btn btn-rectangle__primary font-medium"
									>
										{{ 'common.buy' | translate }}
									</button>
								</div>
							</div>
						</div>
					</ng-template>
				</div>
			</div>
		</div>
	</div>
</div>

<nz-drawer
	nzWrapClassName="drawer-full-height"
	[nzClosable]="false"
	[nzVisible]="isShowCustomProduct"
	[nzPlacement]="'bottom'"
	(nzOnClose)="handleCloseShowCustomProduct()"
>
	<ng-container *nzDrawerContent>
		<div class="bg-white" *ngIf="selectedProduct?.id">
			<app-root-edit-price-product-in-live
				[product]="selectedProduct"
				[dataCreateLiveStreamer]="dataCreateLiveStreamer"
				(editSuccessHandler)="handleSuccess($event)"
			>
			</app-root-edit-price-product-in-live>
		</div>
	</ng-container>
</nz-drawer>
