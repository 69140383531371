import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
} from '@angular/core';
import * as moment from 'moment';
import { LiveService } from '../../services/live.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
    selector: 'app-root-live-vouchers-visibility-controls',
    templateUrl: './live-vouchers-visibility-controls.component.html',
    styleUrl: './live-vouchers-visibility-controls.component.scss',
})
export class LiveVouchersVisibilityControlsComponent implements OnChanges {
    @Input() liveId = null;
    @Input() vouchers = [];

    @Output() voucherPinnedEvent = new EventEmitter<boolean>();

    isLoading = false;

    shopVouchers = [];
    appVouchers = [];

    constructor(
        private liveService: LiveService,
        private nzMessageService: NzMessageService,
    ) {}

    ngOnChanges(): void {
        this.shopVouchers = [];
        this.appVouchers = [];

        this.vouchers.map((item) => {
            if (item.createdBy === 'SHOP') {
                this.shopVouchers.push(item);
            } else {
                this.appVouchers.push(item);
            }
        });
    }

    formatDate(dateStr: string, format: string) {
        return moment(dateStr).format(format);
    }

    pinVoucher(pinnedVoucherId: number) {
        this.isLoading = true;

        this.liveService
            .pinLiveVouchers(
                this.liveId,
                pinnedVoucherId,
                this.vouchers.map((item) => item.id),
            )
            .subscribe({
                complete: () => {
                    this.voucherPinnedEvent.emit(true);

                    this.isLoading = false;
                },
                error: () => {
                    this.nzMessageService.error('Có lỗi sảy ra.');
                },
            });
    }
}
