import { gql } from 'apollo-angular';

export const GET_LIVE_LIST = gql`
    query liveStreams(
        $skip: Int = 0
        $after: ID
        $before: ID
        $first: Int = 10
        $last: Int
        $where: LiveStreamWhereInput
        $orderBy: LiveStreamOrderByInput
    ) {
        liveStreams(
            skip: $skip
            after: $after
            before: $before
            first: $first
            last: $last
            where: $where
            orderBy: $orderBy
        ) {
            id
            name
            description
            roomId
            streamUrl
            liveUrl
            channelId
            status
            startedAt
            liveStreamProducts {
                id
                livedAt
                isCurrentLive
                code
                shopProduct {
                    id
                    name
                }
            }
            nbOfLiveStreamProducts
            host {
                id
                account
                avatar {
                    url
                }
                isFollowingViewer
            }
            background {
                url
            }
        }
        liveStreamConnection(where: $where) {
            aggregate {
                countLiveStream
            }
        }
    }
`;

export const PIN_VOUCHER = gql`
    mutation pinLiveVouchers(
        $data: LivePromotionPinsInput!
        $where: LiveStreamWhereUniqueInput!
    ) {
        livePromotionPins(data: $data, where: $where)
    }
`;
