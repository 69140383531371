<div
	class="voucher-list-wrapper {{
		activeTab === EActiveTab.INPROGRESS ? 'inprogress' : 'finished'
	}}"
>
	<div>
		<div class="w-full d-flex text-center cursor-pointer">
			<div
				(click)="handleChangeTab(tab.type)"
				class="w-half p-3 {{ tab.type === activeTab && 'active' }}"
				*ngFor="let tab of tabs"
			>
				{{ tab.name | translate }}
			</div>
		</div>
	</div>

	<div
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        [infiniteScrollDisabled]="loading"
        (scrolled)="onScroll()"
    >
        <app-root-voucher-card
            [type]="activeTab"
            [data]="vouchers"
            (successCallback)="handleSuccess($event)"
        ></app-root-voucher-card>
    </div>

	<div
		*ngIf="vouchers?.length === 0"
		class="d-flex flex-col items-center justify-center"
		style="height: 80vh"
	>
		<img src="assets/icons/vouchers-no-data.svg" alt="vouchers-no-data" />
		<p class="">{{ 'no_voucher' | translate }}</p>
	</div>

	<div class="px-4 btn-create-container">
		<button class="btn btn-rectangle__primary" (click)="handleCreate()">
			{{ 'profile.vouchers.create' | translate }}
		</button>
	</div>

	<nz-drawer
		nzTitle="Mã giảm giá của shop"
		nzWrapClassName="drawer-full-height"
		[nzClosable]="true"
		[nzVisible]="isShowCreateTypeVoucher"
		[nzPlacement]="'bottom'"
		(nzOnClose)="close()"
	>
		<ng-container *nzDrawerContent>
			<div class="px-6 py-4">
				<div class="py-4">
					<nz-radio-group [(ngModel)]="radioValue">
						<div class="d-flex flex-col">
							<label class="py-2" nz-radio nzValue="APPLY_FOR_ALL"
								><span class="text-4 text-404040">{{
									'profile.vouchers.voucher_for_all' | translate
								}}</span>
								<p class="text-9b9b9b">
									{{
										'profile.vouchers.voucher_for_all_description' | translate
									}}
								</p>
							</label>
							<label class="py-2" nz-radio nzValue="FLASH_SALE_ONLY"
								><span class="text-4 text-404040">{{
									'profile.vouchers.voucher_for_flash_sale' | translate
								}}</span>
								<p class="text-9b9b9b">
									{{
										'profile.vouchers.voucher_for_flash_sale_description'
											| translate
									}}
								</p>
							</label>
						</div>
					</nz-radio-group>
				</div>
				<!-- (click)="confirmTypeCreate()" -->

				<button
					(click)="handleNavigate()"
					[routerLink]="['create']"
					[queryParams]="{ type: radioValue }"
					class="btn btn-rectangle__black"
				>
					{{ 'orderInit.agree' | translate }}
				</button>
			</div>
		</ng-container>
	</nz-drawer>
</div>
