export const environment = {
    production: false,
    apiUrl: 'https://api-test.review-ty.com/api',
    apiUri: 'https://api-test.review-ty.com/api',
    pointUri: 'http://localhost:9010/api',
    graphqlUri: 'https://api-test.review-ty.com/graphql',
    ipServiceUri: 'https://api.ipify.org',
    googleOAuthClientId:
        '259730681697-2mro96f6bdt7us3rrgq2djto817equtm.apps.googleusercontent.com',
    facebookOAuthClientId: '940052570317029',
    appStoreUri:
        'https://apps.apple.com/vn/app/reviewty-m%E1%BB%B9-ph%E1%BA%A9m-l%C3%A0m-%C4%91%E1%BA%B9p/id1468579853',
    playStoreUri:
        'https://play.google.com/store/apps/details?id=com.reviewty.reviewty',
    facebookConnectUri: 'https://www.facebook.com/globalreviewty',
    instagramConnectUri: 'https://www.instagram.com/reviewty.vn',
    tokenKey: 'rvt_tk',
    facebookAppId: '413293009401544',
    APP_SECRET_KEY: '6d1ede816988e58fb6d057d9d85605e0',
    uncutUrl: 'https://r-uncut.vercel.app/',
    firebase: {
        apiKey: 'AIzaSyDiTIYQMeyEuOZ2QcwiC92mrR0u-p1cdso',
        authDomain: 'reviewty-test.firebaseapp.com',
        databaseURL: 'https://reviewty-test.firebaseio.com',
        projectId: 'reviewty-test',
        storageBucket: 'reviewty-test.appspot.com',
        messagingSenderId: '955697951013',
        appId: '1:955697951013:web:157858c63cad9f40bd6acc',
        measurementId: 'G-M1RX3KS24R',
    },
    vapidKeyFirebase:
        'BHykyggoZuSUI7xBDkVdb_8BF-sNsAGp8i1bC6GkMOZo3OQcFxmycqYOR09jmkFHbtEaARArcMQ-aHfwQKVxlyw',
    websocketUrl: 'wss://socket-test.review-ty.com',
    chatAIUrl: 'https://ai.review-ty.com'
};
