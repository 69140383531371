import {
    AfterViewInit,
    Directive,
    ElementRef,
    EventEmitter,
    Host,
    Input,
    OnDestroy,
    Output,
} from '@angular/core';

@Directive({ selector: '[appEnterTheViewportNotifier]' })
export class EnterTheViewportNotifierDirective
    implements AfterViewInit, OnDestroy
{
    @Input() notifierRootMargin: string = '0px';
    @Input() notifierThreshold: number = 0.0;

    @Output() visibilityChange = new EventEmitter();

    private _observer: IntersectionObserver;

    constructor(@Host() private _elementRef: ElementRef) {}

    ngAfterViewInit(): void {
        const options = {
            root: null,
            rootMargin: this.notifierRootMargin,
            threshold: this.notifierThreshold,
        };
        this._observer = new IntersectionObserver(this.callback, options);
        this._observer.observe(this._elementRef.nativeElement);
    }

    ngOnDestroy() {
        this._observer.disconnect();
    }

    private callback = (entries) => {
        entries.forEach((entry) =>
            entry.isIntersecting
                ? this.visibilityChange.emit(true)
                : this.visibilityChange.emit(false),
        );
    };
}
