import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';

@Pipe({ name: 'activeTime' })
export class ActiveTimePipe implements PipeTransform {
    currentLang: string;

    constructor(private translate: TranslateService) {}

    transform(time: string): string {
        const now = dayjs();
        const then = dayjs(time);

        const yearsCount = now.diff(then, 'year');
        if (yearsCount !== 0) {
            if (yearsCount === 1) {
                return `${this.translate.instant('activeTime.lastYear')}`;
            }

            return `${yearsCount} ${this.translate.instant('activeTime.passYears')}`;
        }

        const monthsCount = now.diff(then, 'month');
        if (monthsCount !== 0) {
            if (monthsCount === 1) {
                return `${this.translate.instant('activeTime.lastMonth')}`;
            }

            return `${monthsCount} ${this.translate.instant(
                'activeTime.passMonths',
            )}`;
        }

        const daysCount = now.diff(then, 'day');
        if (daysCount !== 0) {
            if (daysCount === 1) {
                return `${this.translate.instant('activeTime.lastDay')}`;
            }

            return `${daysCount} ${this.translate.instant('activeTime.passDays')}`;
        }

        const hoursCount = now.diff(then, 'hour');
        if (hoursCount !== 0) {
            if (hoursCount === 1) {
                return `${this.translate.instant('activeTime.lastHour')}`;
            }

            return `${hoursCount} ${this.translate.instant('activeTime.passHours')}`;
        }

        const minutesCount = now.diff(then, 'minute');
        if (minutesCount !== 0) {
            if (minutesCount === 1) {
                return `${this.translate.instant('activeTime.lastMinute')}`;
            }

            return `${minutesCount} ${this.translate.instant(
                'activeTime.passMinutes',
            )}`;
        }

        const secondsCount = now.diff(then, 'second');
        if (secondsCount !== 0) {
            if (secondsCount === 1) {
                return `${this.translate.instant('activeTime.lastSecond')}`;
            }

            return `${secondsCount} ${this.translate.instant(
                'activeTime.passSeconds',
            )}`;
        }

        return 'Recently';
    }
}
