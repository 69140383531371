import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
    selector: 'app-root-navbar',
    templateUrl: './navbar.component.html',
    styleUrl: './navbar.component.scss',
})
export class NavbarComponent {
    constructor(
        private location: Location,
        private router: Router,
    ) {}

    @Input() title: string = '';
    @Input() urlToBack: string = '';
    handleBack() {
        const numberOfHistoryBrowser = this.location.getState() as {
            navigationId: number;
        };
        if (numberOfHistoryBrowser.navigationId) {
            this.location.back();
        } else {
            if (this.urlToBack) this.router.navigateByUrl(this.urlToBack);
        }
    }
}
