<!-- eslint-disable @angular-eslint/template/alt-text -->
<div *ngIf="rate" class="">
	<!-- class="pb-2 pt-5" -->
	<img
		width="{{ isQuickRating ? '16' : '20' }}"
		height="{{ isQuickRating ? '16' : '20' }}"
		src="
	{{ renderFilterStatus[rate - 1].iconUrl }}
    "
	/>

	<span
		[ngClass]="{
			'text-3': isQuickRating
		}"
		[ngStyle]="{
			color: renderFilterStatus[rate - 1].color
		}"
	>
		{{ renderFilterStatus[rate - 1].label | translate }}
	</span>
</div>
