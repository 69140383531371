<!-- Header -->

<!-- Main app container -->
<div
    class="app-container
  {{
        !isUsingMobileTransparentLayout &&
            !isUsingIframeLayout &&
            'footer-append'
    }}
  {{ isUsingIframeLayout && 'iframe-layout' }} 
  {{
        (currentUrl.includes('/profile') || currentUrl.includes('/uncut')) &&
            'hidden-scrollbar'
    }} 
"
    *ngIf="!loading"
>
    <router-outlet></router-outlet>
</div>

<!-- Footer -->
<app-root-footer
    *ngIf="!isMinView && !isUsingMobileTransparentLayout"
></app-root-footer>

<!-- Background tasks -->
<app-root-background-task></app-root-background-task>

<!-- Scroll to top button -->
<app-root-scroll-to-top-button
    *ngIf="!isUsingIframeLayout"
></app-root-scroll-to-top-button>

<!-- Mobile native connect modal -->
<app-root-mobile-native-connect-modal></app-root-mobile-native-connect-modal>

<!-- Mobile native connect notification box -->
<app-root-mobile-native-connect-notification-box></app-root-mobile-native-connect-notification-box>
