import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { Subject, Subscription, throttleTime } from 'rxjs';
import { OtpInputService } from '../otp-input.service';

@Component({
    selector: 'app-root-otp-input',
    templateUrl: './otp-input.component.html',
    styleUrl: './otp-input.component.scss',
})
export class OtpInputComponent implements OnInit, OnDestroy {
    private _allowedCharacters: string | RegExp = /./;
    private _typeOfInput: 'text' | 'number' | 'password' = 'text';
    keyboardType: 'numeric' | 'text' = 'numeric';

    @Input() limit: number;
    @Input() set allowedCharacters(el: string | RegExp) {
        if (el) {
            this._allowedCharacters = el;
        } else {
            this.throwErrorForUndefinedElement(el);
        }
    }

    @Input() set typeOfInput(type: 'text' | 'number' | 'password') {
        if (type) {
            this._typeOfInput = type;
            this.keyboardType = type === 'number' ? 'numeric' : 'text';
        } else {
            this.throwErrorForUndefinedElement(type);
        }
    }

    get typeOfInput(): 'text' | 'number' | 'password' {
        return this._typeOfInput;
    }

    @Output() otpOut = new EventEmitter();

    otpForm: FormGroup;
    public limitArray = [];
    private isKeyAcceptable = true;
    changeFocus$ = new Subject();
    private subscription = new Subscription();

    constructor(
        private formBuilder: FormBuilder,
        private ngOtpService: OtpInputService,
    ) {
        this.subscription.add(
            this.changeFocus$
                .pipe(throttleTime(50))
                .subscribe((index: number) => {
                    this.changeFocus(index);
                }),
        );
    }

    ngOnInit() {
        this.limit = this.limit ? this.limit : 6;
        this.setFormBuilder();
    }

    setFormBuilder() {
        this.otpForm = this.formBuilder.group({});
        this.limitArray = Array.from(Array(this.limit).keys());
        this.limitArray.map((element) => {
            this.otpForm.addControl(
                `otp-${element}`,
                new FormControl('', Validators.required),
            );
        });
    }

    changeFocus(id: number) {
        if (!this.isKeyAcceptable) {
            this.isKeyAcceptable = true;
            return;
        }
        const currentElement: HTMLInputElement =
            this.ngOtpService.getElement(id);
        if (id && this.ngOtpService.isEmptySting(currentElement.value)) {
            this.moveBackward(id);
        } else if (this.ngOtpService.isLastInput(id, this.limit - 1)) {
            this.otpOut.emit(Object.values(this.otpForm.value).join(''));
        } else if (!this.ngOtpService.isEmptySting(currentElement.value)) {
            this.moveForward(id);
        }
    }

    private moveForward(id: number) {
        const nextElement: HTMLInputElement = this.ngOtpService.getElement(
            id + 1,
        );
        nextElement.focus();
    }

    private moveBackward(id: number) {
        const nextElement: HTMLInputElement = this.ngOtpService.getElement(
            id - 1,
        );
        nextElement.focus();
    }

    onFocus(id) {
        // const currentElement: HTMLInputElement = this.ngOtpService.getElement(id);
        // currentElement.select();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    onKeyDown(event: KeyboardEvent) {
        if (event.key && event.key !== 'Backspace' && event.key !== 'Delete') {
            if (this._allowedCharacters) {
                if (this._allowedCharacters instanceof RegExp) {
                    if (!this._allowedCharacters.test(event.key)) {
                        this.isKeyAcceptable = false;
                        event.preventDefault();
                    }
                } else {
                    if (!this._allowedCharacters.includes(event.key)) {
                        this.isKeyAcceptable = false;
                        event.preventDefault();
                    }
                }
            }
        }
    }

    private throwErrorForUndefinedElement<T>(element: T) {
        throw new Error(`Is not possibile set ${element} to this parameter`);
    }
}
