import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { from, of, EMPTY } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import Swal from 'sweetalert2';

import {
    AuthErrorCode,
    GetMeGQL,
    LoginFacebookGQL,
    MyShopsGQL,
    OauthProvider,
    SignUpNormalizeGQL,
} from '@app/common/generated-types';
import { environment } from '@environments/environment';
import { StoreService } from '@app/core/services/store.service';
import { FirebasePushNotificationService } from './firebase-push-notification.service';

declare const FB: any;

@Injectable({
    providedIn: 'root',
})
export class JwtService {
    constructor(
        private getMeGQL: GetMeGQL,
        public route: ActivatedRoute,
        private loginFB: LoginFacebookGQL,
        public router: Router,
        private myShopsGQL: MyShopsGQL,
        private signUpGQL: SignUpNormalizeGQL,
        private store: StoreService,
        private firebasePushNotificationService: FirebasePushNotificationService,
    ) {}

    login(callback?: Function) {
        // login with facebook then authenticate with the API to get a JWT auth token
        this.facebookLogin()
            .pipe(
                concatMap((accessToken) => {
                    this.apiAuthenticate(accessToken);
                    return accessToken;
                }),
            )
            .subscribe({
                next(value) {
                    callback();
                },
                error(err) {
                    console.log(err);
                    callback();
                },
            });
    }

    facebookLogin(callback?: Function) {
        // login with facebook and return observable with fb access token on success
        return from(
            new Promise((resolve) =>
                FB.login(resolve, {
                    scope: 'public_profile,email',
                    return_scopes: true,
                    auth_type: 'rerequest',
                }),
            ).catch(err => {
                console.log(err);
                callback()
            }),
        ).pipe(
            concatMap(({ authResponse }) => {
                if (!authResponse) return EMPTY;
                return of(authResponse.accessToken);
            }),
        );
    }

    getMe() {
        return this.getMeGQL
            .fetch({}, { fetchPolicy: 'no-cache', errorPolicy: 'all' })
            .subscribe((authMeResult) => {
                localStorage.setItem(
                    'me',
                    JSON.stringify({
                        id: authMeResult?.data?.me.id,
                        avatar: authMeResult?.data?.me?.avatar?.url,
                        account: authMeResult?.data?.me?.account,
                        isShopUser: !!authMeResult?.data?.me?.shop,
                    }),
                );

                this.store.setUserProfile(authMeResult?.data?.me);
            });
    }

    apiAuthenticate(accessToken: string) {
        if (accessToken) {
            this.loginFB
                .mutate({
                    facebookId: accessToken,
                })
                .subscribe((result) => {
                    if (
                        result.data?.loginByFacebookV2.__typename ===
                        'AuthError'
                    ) {
                        if (
                            result.data?.loginByFacebookV2.AECode ===
                            AuthErrorCode.FACEBOOK_NOT_EXISTS
                        ) {
                            this.signUpGQL
                                .mutate({
                                    data: {
                                        accessToken: accessToken,
                                        oauthProvider: OauthProvider.Facebook,
                                    },
                                })
                                .subscribe((result) => {
                                    if (
                                        result.data?.signUp.__typename ===
                                        'AuthPayload'
                                    ) {
                                        this.myShopsGQL
                                            .fetch()
                                            .subscribe((response) => {
                                                if (
                                                    response.errors?.[0].message.includes(
                                                        'Access denied! You need to be authorized',
                                                    ) ||
                                                    response.data.myShops
                                                        .length === 0
                                                ) {
                                                    Swal.fire(
                                                        'Không thành công!',
                                                        'Vui lòng đăng ký tài khoản trên ứng dụng Reviewty',
                                                        'error',
                                                    );
                                                } else {
                                                    this.router.navigate(['']);
                                                }
                                            });
                                    }
                                });
                        }
                    }

                    if (
                        result.data?.loginByFacebookV2.__typename ===
                        'AuthPayload'
                    ) {
                        localStorage.setItem(
                            environment.tokenKey,
                            result.data.loginByFacebookV2.token,
                        );
                        this.firebasePushNotificationService.saveRegistrationToken();

                        this.getMe();

                        this.router.navigateByUrl('');
                    } else {
                        Swal.fire(
                            'Không thành công!',
                            'Vui lòng đăng ký tài khoản Shop trên ứng dụng Reviewty',
                            'error',
                        );
                    }
                });
        }
    }

    logout() {
        // revoke app permissions to logout completely because FB.logout() doesn't remove FB cookie
        FB.api('/me/permissions', 'delete', null, () => FB.logout());
    }

    getToken(): string {
        return window.localStorage[environment.tokenKey];
    }

    saveToken(token: string): void {
        window.localStorage[environment.tokenKey] = token;
    }

    destroyToken(): void {
        window.localStorage.removeItem(environment.tokenKey);
    }
}
