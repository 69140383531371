import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { nanoid } from 'nanoid';
import { Timestamp } from '@angular/fire/firestore';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NzMessageService } from 'ng-zorro-antd/message';

import { ChatService } from '@app/shared/providers/chat.service';
import { LiveStreamsQuery } from '@app/common/generated-types';
import { Router } from '@angular/router';
import { UtilsService } from '@app/core/services';

@Component({
    selector: 'app-root-live-chat',
    templateUrl: './live-chat.component.html',
    styleUrl: './live-chat.component.scss',
})
export class LiveChatComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild('input') input: ElementRef;
    @ViewChild('messagesContainer') messagesContainer: ElementRef;

    @Input() live: LiveStreamsQuery['liveStreams'][number] & {
        buyers?: string[];
    } = null;

    @Output() commentSendEvent = new EventEmitter<number>();
    @Output() commentBoxVisibleEvent = new EventEmitter<boolean>();

    isMobile = false;
    isShowCommentBox = false;
    isMsgOptionsVisible = false;
    isCurrentShopUserOfLive = false;
    isChatAreaAtTop = true;

    currentLang: string;
    content = '';

    fetchMessageInterval = null;
    fetchPinnedMessageInterval = null;

    tempActiveMessage = null;
    messages = [];
    pinnedMessages = [];
    newBuyersMessage = '';

    currentUser = null;

    constructor(
        private translateService: TranslateService,
        private chatService: ChatService,
        private deviceService: DeviceDetectorService,
        private nzMessageService: NzMessageService,
        private router: Router,
        private utilsService: UtilsService,
    ) {
        this.isMobile = this.deviceService.isMobile();

        this.currentLang = this.translateService.currentLang;
    }

    ngOnInit(): void {
        if (localStorage.getItem('me')) {
            this.currentUser = JSON.parse(localStorage.getItem('me'));

            this.isCurrentShopUserOfLive =
                this.live.host?.id === this.currentUser.id;
        }
    }

    ngOnDestroy(): void {
        this.commentBoxVisibleEvent.emit(false);

        clearInterval(this.fetchMessageInterval);
        clearInterval(this.fetchPinnedMessageInterval);
    }

    ngOnChanges(): void {
        this.messages = [];
        this.pinnedMessages = [];
        this.newBuyersMessage = '';

        this.loadChatMsgs();
        this.loadPinnedChatMsgs();
        this.loadNewBuyersMessage();
    }

    loadChatMsgs() {
        this.chatService.getRoomLiveMessage(this.live.id);
        
        
        this.fetchMessageInterval = setInterval(() => {
            if (this.chatService.roomLiveMessages.has(this.live.id)) {
                clearInterval(this.fetchMessageInterval);
                
                this.chatService.roomLiveMessages
                    .get(this.live.id)
                    .subscribe((data) => {
                        this.messages = data;
                    });

                // this.chatService
                //     .preloadRoomLiveMessage(this.live.id)
                //     .then((messageList) => {
                //         this.messages = messageList;
                //         this.messages.shift();

                //         this.chatService.roomLiveMessages
                //             .get(this.live.id)
                //             .subscribe((data) => {
                //                 this.messages = data.concat(this.messages);
                //             });
                //     })
                //     .catch((err) => {
                //         console.error(err);
                //         this.nzMessageService.error('Có lỗi sảy ra.');
                //     });
            }
        }, 300);
    }

    focusout(event) {
        if (!event.currentTarget.textContent.replace(' ', '').length) {
            event.currentTarget.textContent = '';
        }
    }

    onTextChange(event) {
        this.content = event.currentTarget.textContent;
    }

    send() {
        if (this.content.length !== 0) {
            try {
                if (!this.currentUser) {
                    this.router.navigate(['/auth/login']);
                }

                this.chatService.commentLiveChat(this.live.id, nanoid(), {
                    content: this.content,
                    createdAt: Timestamp.fromDate(new Date()),
                    senderAvatar: this.currentUser.avatar
                        ? this.currentUser.avatar
                        : '',
                    senderID: this.currentUser.id,
                    senderName: this.currentUser.account,
                    type: 'NORMAL',
                });
            } catch (error) {
                console.error(error);

                this.nzMessageService.error('Có lỗi sảy ra.');
            }
        }

        setTimeout(() => {
            this.input.nativeElement.innerHTML = '';

            this.commentBoxVisibleEvent.emit(false);

            this.isShowCommentBox = false;
            this.input.nativeElement.blur();
        }, 1);
    }

    focus() {
        this.input.nativeElement.focus();
    }

    toggleCommentBox() {
        this.isShowCommentBox = !this.isShowCommentBox;

        if (this.isShowCommentBox) {
            this.commentBoxVisibleEvent.emit(true);

            // Prevent chrome mobile cursor problem
            this.input.nativeElement.blur();
            this.input.nativeElement.focus();
        } else {
            this.commentBoxVisibleEvent.emit(false);
        }
    }

    togglePinChatMsg(isPinned = false) {
        if (!isPinned) {
            if (this.pinnedMessages.length !== 0) {
                this.chatService.removePinLiveChat(
                    this.live.id,
                    this.pinnedMessages[0].id,
                );
            }
            this.chatService.pinLiveChat(
                this.live.id,
                this.tempActiveMessage.id,
                this.tempActiveMessage.data,
            );
        } else {
            this.chatService.removePinLiveChat(
                this.live.id,
                this.tempActiveMessage.id,
            );
        }

        this.toggleMsgOptions();
    }

    onMsgSelected(msgObj: any) {
        this.tempActiveMessage = msgObj;

        this.toggleMsgOptions();
    }

    loadPinnedChatMsgs() {
        this.chatService.getRoomLivePinnedMessage(this.live.id);
        this.fetchPinnedMessageInterval = setInterval(() => {
            if (this.chatService.roomLivePinnedMessages.has(this.live.id)) {
                clearInterval(this.fetchPinnedMessageInterval);

                this.chatService.roomLivePinnedMessages
                    .get(this.live.id)
                    .subscribe((data) => {
                        this.pinnedMessages = data;
                    });
            }
        }, 300);
    }

    toggleMsgOptions() {
        this.isMsgOptionsVisible = !this.isMsgOptionsVisible;
    }

    isPinnedMsgExist(key: string) {
        return this.pinnedMessages.find((item) => item.id === key);
    }

    onpaste(e) {
        e.preventDefault();
        const text = e.clipboardData.getData('text/plain');

        this.input.nativeElement.innerHTML += text;

        this.utilsService.setCaretToEnd(this.input.nativeElement);
    }

    loadNewBuyersMessage() {
        if (this.live.buyers && this.live.buyers.length !== 0) {
            if (this.live.buyers.length === 1) {
                this.newBuyersMessage = `${this.live.buyers[0]} đang mua`;
            } else {
                const random = Math.floor(
                    Math.random() * this.live.buyers.length,
                );
                this.newBuyersMessage = `${this.live.buyers[random]} và ${this.live.buyers.length - 1} người khác đang mua`;
            }
        }
    }

    onChatAreaScroll() {
        if (this.messagesContainer.nativeElement.scrollTop >= 0) {
            this.isChatAreaAtTop = true;
        } else {
            this.isChatAreaAtTop = false;
        }
    }
}
