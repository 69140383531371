import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { IconDefinition } from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';
import { TranslateModule } from '@ngx-translate/core';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule, NZ_ICONS } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CdkDrag, CdkDropList, DragDropModule } from '@angular/cdk/drag-drop';
import { EditorModule } from '@tinymce/tinymce-angular';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { MatMenuModule } from '@angular/material/menu';

import {
    EnterTheViewportNotifierDirective,
    FallbackImgDirective,
    FormControlValidationMsgDirective,
    FormSubmitValidationMsgDirective,
    ImgPreviewDirective,
    SwipeDirective,
} from '@app/core/directives';
import {
    ActiveTimePipe,
    CurrencyByLocalePipe,
    PercentToStrPipe,
    SafeHtml,
    StrToSlugPipe,
    VndToUsdPipe,
    ShortenCountPipe,
    trimStrPipe,
} from '@app/core/pipes';
import { BaseComponent } from './components/base/base.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { MobileNativeConnectModalComponent } from './components/mobile-native-connect-modal/mobile-native-connect-modal.component';
import { MobileNativeConnectNotificationBoxComponent } from './components/mobile-native-connect-notification-box/mobile-native-connect-notification-box.component';
import { RatingComponent } from './components/rating/rating.component';
import { FileService, ValidationMsgService } from '@app/core/services';
import { ScrollToTopButtonComponent } from './components/scroll-to-top-button/scroll-to-top-button.component';
import { TransparentHeaderComponent } from './components/transparent-header/transparent-header.component';
import { ViewHtmlComponent } from './components/view-html/view-html.component';
import { SanitizeUrlPipe } from '@app/core/pipes/sanitize-url.pipe';
import { BackgroundTaskComponent } from './components/background-task/background-task.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { ContactComponent } from './components/contact/contact.component';
import { DragScrollComponent } from './components/drag-scroll/drag-scroll.component';
import { NzImageModule } from 'ng-zorro-antd/image';
import { ReadMoreComponent } from './components/read-more/read-more.component';
import { ReviewRateComponent } from './components/review-rate/review-rate.component';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzRateModule } from 'ng-zorro-antd/rate';
import { BadgeComponent } from './components/badge/badge.component';
import { AbbrNumberPipe } from '@app/core/pipes/abbr-number.pipe';
import { ParseUrlPipe } from '@app/core/pipes/parse-url.pipe';
import { ViewHtmlDialogComponent } from './components/view-html-dialog/view-html-dialog.component';
import { CalendarTimePipe } from '@app/core/pipes/calendar-time.pipe';
import { MarkdownModule, MarkdownPipe, MarkdownService } from 'ngx-markdown';
import { DragScrollDirectivePipe } from '@app/core/pipes/drag-scroll-directive.pipe';
import { DragScrollDirectiveDirective } from '@app/core/directives/drag-scroll-directive.directive';
import { ClickOutsideDirective } from '@app/core/directives/click-outside.directive';
import { ChatService } from './providers/chat.service';
import { ProductLiveStreamCardComponent } from '@app/profile/profile-main/merchant/live-stream/product-live-stream-card/product-live-stream-card.component';
import { DrawerProductInLiveComponent } from '@app/profile/profile-main/merchant/live-stream/drawer-product-in-live/drawer-product-in-live.component';
import { LiveVideoWrapperComponent } from '@app/live/shared/components/live-video-wrapper/live-video-wrapper.component';
import { LiveChatComponent } from '@app/live/shared/components/live-chat/live-chat.component';
import { EditPriceProductInLiveComponent } from '@app/profile/profile-main/merchant/live-stream/edit-price-product-in-live/edit-price-product-in-live.component';
import { VoucherCardComponent } from '@app/profile/profile-main/merchant/vouchers/voucher-card/voucher-card.component';
import { FlashSaleCardComponent } from '@app/profile/profile-main/merchant/vouchers/voucher-card/flash-sale-card/flash-sale-card.component';
import { ParseTagUser } from '@app/core/pipes/parse-tag-user.pipe';
import { LiveVoucherListComponent } from '@app/live/shared/components/live-voucher-list/live-voucher-list.component';
import { LiveVouchersVisibilityControlsComponent } from '@app/live/shared/components/live-vouchers-visibility-controls/live-vouchers-visibility-controls.component';
import { AngularResizeEventModule } from 'angular-resize-event';

const antDesignIcons = AllIcons as {
    [key: string]: IconDefinition;
};

const icons: IconDefinition[] = Object.keys(antDesignIcons).map((key) => {
    const i = antDesignIcons[key];
    return i;
});

@NgModule({
    declarations: [
        DragScrollComponent,
        ContactComponent,
        HeaderComponent,
        FooterComponent,
        ScrollToTopButtonComponent,
        TransparentHeaderComponent,
        RatingComponent,
        MobileNativeConnectModalComponent,
        MobileNativeConnectNotificationBoxComponent,
        ViewHtmlComponent,
        BackgroundTaskComponent,
        NavbarComponent,
        SearchBarComponent,
        ReadMoreComponent,
        ReviewRateComponent,
        BadgeComponent,
        ViewHtmlDialogComponent,
        ProductLiveStreamCardComponent,
        DrawerProductInLiveComponent,
        LiveVideoWrapperComponent,
        LiveChatComponent,
        EditPriceProductInLiveComponent,
        VoucherCardComponent,
        FlashSaleCardComponent,
        LiveVoucherListComponent,
        LiveVouchersVisibilityControlsComponent,

        // Pipes
        ActiveTimePipe,
        StrToSlugPipe,
        ShortenCountPipe,
        trimStrPipe,
        SanitizeUrlPipe,
        AbbrNumberPipe,
        ParseUrlPipe,
        ParseTagUser,
        CalendarTimePipe,
        VndToUsdPipe,
        DragScrollDirectivePipe,
        CurrencyByLocalePipe,
        PercentToStrPipe,
        SafeHtml,

        // Directives
        FallbackImgDirective,
        DragScrollDirectiveDirective,
        EnterTheViewportNotifierDirective,
        SwipeDirective,
        FormControlValidationMsgDirective,
        FormSubmitValidationMsgDirective,
        BaseComponent,
        ClickOutsideDirective,
        ImgPreviewDirective,
    ],
    imports: [
        NzRateModule,
        NzTabsModule,
        CommonModule,
        RouterModule,
        NzModalModule,
        NzDrawerModule,
        NzNotificationModule,
        InfiniteScrollModule,
        NzPaginationModule,
        NzCarouselModule,
        FormsModule,
        ReactiveFormsModule,
        NzToolTipModule,
        NzGridModule,
        MatDialogModule,
        TranslateModule,
        NzProgressModule,
        NzSelectModule,
        NzIconModule,
        NzDatePickerModule,
        NzImageModule,
        MarkdownModule,
        MarkdownPipe,
        NzFormModule,
        NzInputModule,
        NzDropDownModule,
        // DragDropComponent,
        CdkDropList,
        CdkDrag,
        MatMenuModule,
    ],
    exports: [
        NzRateModule,
        NzTabsModule,
        ReviewRateComponent,
        ReadMoreComponent,
        DragScrollComponent,
        ContactComponent,
        HeaderComponent,
        FooterComponent,
        RatingComponent,
        ScrollToTopButtonComponent,
        TransparentHeaderComponent,
        RatingComponent,
        MobileNativeConnectModalComponent,
        MobileNativeConnectNotificationBoxComponent,
        ViewHtmlComponent,
        BackgroundTaskComponent,
        NavbarComponent,
        SearchBarComponent,
        BadgeComponent,
        ViewHtmlDialogComponent,
        ProductLiveStreamCardComponent,
        DrawerProductInLiveComponent,
        LiveVideoWrapperComponent,
        LiveChatComponent,
        EditPriceProductInLiveComponent,
        VoucherCardComponent,
        FlashSaleCardComponent,
        CdkDropList,
        CdkDrag,

        // Modules
        RouterModule,
        NzModalModule,
        NzDrawerModule,
        InfiniteScrollModule,
        NzPaginationModule,
        NzCarouselModule,
        FormsModule,
        ReactiveFormsModule,
        NzAlertModule,
        NzFormModule,
        NzInputModule,
        NzIconModule,
        NzBreadCrumbModule,
        NzToolTipModule,
        NzGridModule,
        NzProgressModule,
        TranslateModule,
        NzGridModule,
        MatDialogModule,
        NzProgressModule,
        NzUploadModule,
        NzSelectModule,
        NgxSkeletonLoaderModule,
        DragDropModule,
        EditorModule,
        NzRadioModule,
        NzDropDownModule,
        NzCheckboxModule,
        NzSwitchModule,
        NzDatePickerModule,
        NzImageModule,
        MarkdownModule,
        MatMenuModule,
        AngularResizeEventModule,

        // Pipes
        ActiveTimePipe,
        StrToSlugPipe,
        ShortenCountPipe,
        trimStrPipe,
        SanitizeUrlPipe,
        AbbrNumberPipe,
        ParseUrlPipe,
        ParseTagUser,
        CalendarTimePipe,
        MarkdownPipe,
        VndToUsdPipe,
        DragScrollDirectivePipe,
        CurrencyByLocalePipe,
        PercentToStrPipe,
        SafeHtml,

        // Directives
        FallbackImgDirective,
        DragScrollDirectiveDirective,
        EnterTheViewportNotifierDirective,
        SwipeDirective,
        FormControlValidationMsgDirective,
        FormSubmitValidationMsgDirective,
        ClickOutsideDirective,
        ImgPreviewDirective,
    ],
    providers: [
        {
            provide: NZ_ICONS,
            useValue: icons,
        },
        NzMessageService,
        FileService,
        ValidationMsgService,
        MarkdownService,
        ChatService,
    ],
})
export class SharedModule {}
