import { Component, OnInit } from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { AuthService } from '@app/auth/shared/services/auth.service';
import { Gender } from '@app/common/generated-types';
import { StoreService } from '@app/core/services/store.service';
import { ValidationService } from '@app/core/services/validation.service';
import { debounceTime, Subject } from 'rxjs';
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: 'app-root-tell-us-about-us',
    templateUrl: './tell-us-about-us.component.html',
    styleUrl: './tell-us-about-us.component.scss',
})
export class TellUsAboutUsComponent implements OnInit {
    verifyAccountForm: FormGroup;
    years: number[] = [];
    Gender = Gender;
    currentYear = new Date().getFullYear();
    private searchAccount = new Subject<string>();
    private readonly debounceTimeMs = 300; // Set the debounce time (in milliseconds)
    email;
    password;
    genderList = [
        {
            gender: Gender.MALE,
            src: 'assets/icons/male.svg',
        },
        {
            gender: Gender.FEMALE,
            src: 'assets/icons/gender-female.svg',
        },
        {
            gender: Gender.OTHER,
            src: 'assets/icons/gender-other.svg',
        },
    ];
    returnUrl: string;

    constructor(
        private fb: FormBuilder,
        private authService: AuthService,
        public validationService: ValidationService,
        private store: StoreService,
        private route: ActivatedRoute
    ) {
        this.verifyAccountForm = this.fb.group({
            name: ['', [Validators.required, this.matchAccountName.bind(this)]],
            yearOfbirth: [null, [Validators.required]],
            gender: [Gender.MALE, [Validators.required]],
            referralID: [''],
        });
        this.route.queryParams.subscribe(params => {
            this.returnUrl = params['returnUrl'] || '';
        });
    }
    ngOnInit() {
        this.store.bodySaveEmailPassword$.subscribe((data) => {
            if (data?.email) {
                this.email = data.email;
                this.password = data.password;
            }
        });
        this.searchAccount
            .pipe(debounceTime(this.debounceTimeMs))
            .subscribe((searchValue) => {
                this.performSearch(searchValue);
            });
        const startYear = this.currentYear - 40; // Start from 10 years ago
        const endYear = this.currentYear - 5; // End 10 years in the future
        for (let year = endYear; year >= startYear; year--) {
            this.years.push(year);
        }
    }

    matchAccountName(control: FormControl): { [s: string]: boolean | null } {
        const isCheck =
            this.validationService.noSpaceOrSpecialCharValidator(control);
        if (isCheck?.noSpaceOrSpecialChar) {
            return {
                noSpaceOrSpecialChar: true,
            };
        } else {
            if (this.verifyAccountForm && control.value?.length < 3) {
                return {
                    required: true,
                };
            } else {
                this.searchAccount.next(control.value);
            }
        }
        return null;
    }

    performSearch(accountName: string) {
        this.authService
            .checkInformationByUser({
                where: {
                    account: accountName,
                },
            })
            .subscribe(({ data }) => {
                if (data?.user?.id) {
                    this.verifyAccountForm.controls['name'].setErrors({
                        matchFoundAccount: true,
                    });
                    this.verifyAccountForm.controls['name'].markAllAsTouched();
                } else {
                    this.verifyAccountForm.controls['name'].setErrors(null);
                }
            });
    }

    handleSubmit() {
        const { name, yearOfbirth, gender, referralID } =
            this.verifyAccountForm.value;

        const age = this.currentYear - yearOfbirth;

        if (this.verifyAccountForm.invalid) {
            Object.values(this.verifyAccountForm.controls).map((control) => {
                control.markAsDirty();
                control.updateValueAndValidity();
            });
            return;
        } else {
            this.authService.signup(
                {
                    data: {
                        account: name,
                        gender: gender,
                        birthYear: yearOfbirth,
                        email: this.email,
                        password: this.password,
                        referralCode: referralID
                    },
                },
                { fetchPolicy: 'no-cache' },this.returnUrl
            );
        }
    }

    selectGender(gender: Gender) {
        this.verifyAccountForm.patchValue({
            gender: gender,
        });
    }
}
