<section class="chat">
    <div
        #messagesContainer
        class="messages {{ isChatAreaAtTop && 'focus' }}"
        (scroll)="onChatAreaScroll()"
    >
        <div class="message" *ngFor="let item of messages">
            <img
                class="avatar"
                src="{{
                    item.data?.senderAvatar ??
                        'assets/images/fallback-avatar-img.png'
                }}"
                appImgFallback="assets/images/fallback-avatar-img.png"
                alt="Avatar"
                loading="lazy"
            />
            <div class="message-body">
                <div class="leading">
                    <span class="username">{{ item.data?.senderName }}</span>
                    <span
                        class="host-mark {{
                            live.host?.id !== item.data?.senderID && 'd-none'
                        }}"
                        >Chủ phòng</span
                    >
                </div>
                <p class="content" (click)="onMsgSelected(item)">
                    {{ item.data?.content }}
                </p>
            </div>
        </div>
    </div>
    <div *ngIf="newBuyersMessage" class="messages new-buyers-messages">
        <div class="message">
            <div class="message-body">
                <p class="content">
                    {{ newBuyersMessage }}
                </p>
            </div>
        </div>
    </div>
    <div class="messages pinned-messages">
        <div class="message" *ngFor="let item of pinnedMessages">
            <div class="message-body">
                <p class="content" (click)="onMsgSelected(item)">
                    {{ item.data?.content }}
                </p>
            </div>
        </div>
    </div>
    <section class="comment-box {{ isShowCommentBox && 'active' }}">
        <div
            class="overlay {{ !isShowCommentBox && 'd-none' }}"
            (click)="toggleCommentBox()"
        ></div>
        <div
            class="form-input"
            (click)="!isShowCommentBox && toggleCommentBox()"
        >
            <div class="input-wrapper">
                <p
                    *ngIf="currentLang === 'en'"
                    #input
                    contenteditable="true"
                    spellcheck="false"
                    class="text-input"
                    id="postContent"
                    placeholder="Leave a comment..."
                    (focusout)="focusout($event)"
                    (input)="onTextChange($event)"
                    (paste)="onpaste($event)"
                ></p>
                <p
                    *ngIf="currentLang !== 'en'"
                    #input
                    contenteditable="true"
                    spellcheck="false"
                    class="text-input"
                    id="postContent"
                    placeholder="Viết bình luận..."
                    (focusout)="focusout($event)"
                    (input)="onTextChange($event)"
                    (paste)="onpaste($event)"
                ></p>
            </div>
        </div>
        <div class="send-btn" (click)="send()">
            <img src="assets/icons/comment-send.svg" alt="Send" />
        </div>
    </section>
</section>

<nz-drawer
    [nzHeight]="'74px'"
    [nzClosable]="false"
    [nzVisible]="isMsgOptionsVisible"
    [nzPlacement]="'bottom'"
    (nzOnClose)="toggleMsgOptions()"
>
    <ng-container *nzDrawerContent>
        <div class="msg-options-bottom-sheet">
            <div class="options">
                <div
                    class="option-item {{
                        !isCurrentShopUserOfLive && 'd-none'
                    }}"
                    (click)="
                        togglePinChatMsg(
                            tempActiveMessage &&
                                isPinnedMsgExist(tempActiveMessage.id)
                        )
                    "
                >
                    <p>
                        {{
                            tempActiveMessage &&
                            isPinnedMsgExist(tempActiveMessage.id)
                                ? 'Bỏ ghim tin nhắn'
                                : 'Ghim tin nhắn'
                        }}
                    </p>
                </div>
            </div>
        </div>
    </ng-container>
</nz-drawer>
