import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
    UpdatePriceLiveStreamProductV2GQL,
    UpdateShopProductGQL,
} from '@app/common/generated-types';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ILiveStream } from '../live-stream-detail-by-user/live-stream-detail-by-user.component';

@Component({
    selector: 'app-root-edit-price-product-in-live',

    templateUrl: './edit-price-product-in-live.component.html',
    styleUrl: './edit-price-product-in-live.component.scss',
})
export class EditPriceProductInLiveComponent implements OnInit {
    @Input() product?: ILiveStream['liveStreamProducts'][0];
    @Input() dataCreateLiveStreamer: ILiveStream = undefined;
    @Output() editSuccessHandler = new EventEmitter();

    formEditSalePrice: FormGroup;
    constructor(
        private fb: FormBuilder,
        private updateShopProduct: UpdateShopProductGQL,
        private updatePriceLiveStream: UpdatePriceLiveStreamProductV2GQL,
        private nzMessageService: NzMessageService,
    ) {
        this.formEditSalePrice = this.fb.group({
            salePrice: [null, [Validators.required]],
            currentPrice: [null, [Validators.required]],
            numOfProductSale: [null, [Validators.required]],
        });
    }

    ngOnInit(): void {
        if (this.product?.id) {
            this.formEditSalePrice.patchValue({
                salePrice: this.product.shopProduct.productVariants[0].price,
                currentPrice:
                    this.product.shopProduct.productVariants[0].originalPrice,
                numOfProductSale:
                    this.product.shopProduct.productVariants[0].quantity,
            });
        }
    }

    handleSubmit() {
        const { salePrice, currentPrice, numOfProductSale } =
            this.formEditSalePrice.value;

        this.updatePriceLiveStream
            .mutate({
                where: {
                    id: this.product.id,
                    liveStreamId: this.dataCreateLiveStreamer?.id,
                },
                data: {
                    product: {
                        connect: {
                            id: this.product?.shopProduct?.id,
                        },
                        data: {
                            variantId:
                                this.product.shopProduct.productVariants[0].id,
                            price: salePrice,
                            originalPrice: currentPrice,
                            quantity: numOfProductSale,
                        },
                    },
                },
            })
            .subscribe(({ data }) => {
                if (
                    data.updatePriceLiveStreamProductV2.__typename ===
                    'LiveStreamProduct'
                ) {
                    this.nzMessageService.success('Sửa giá thành công');

                    this.editSuccessHandler.emit({
                        variantId:
                            this.product.shopProduct.productVariants[0].id,
                        price: salePrice,
                        originalPrice: currentPrice,
                        quantity: numOfProductSale,
                    });
                }
            });
    }
}
