<div class="rating-wrapper">
  <div class="rating">
    <div
      class="ratingUpper"
      [ngStyle]="{'width': ((rate / 5) * 100).toString() + '%'}"
    >
      <span>★</span>
      <span>★</span>
      <span>★</span>
      <span>★</span>
      <span>★</span>
    </div>
    <div class="ratingLower">
      <span>★</span>
      <span>★</span>
      <span>★</span>
      <span>★</span>
      <span>★</span>
    </div>
  </div>
  <h3
    *ngIf="isShowRatingCount"
    class="rating-count {{isMobile ? 'caption1-regular' : 'caption2-regular'}}"
  >
    <span *ngIf="isShowRate" class="rate">{{rate  | number: '1.1-1'}}</span>
    ({{rateCount}}
  )</h3>
</div>
