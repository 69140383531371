/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '@environments/environment';

import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

import { DeviceType, StoreDeviceTokenGQL } from '@app/common/generated-types';

@Injectable({
    providedIn: 'root',
})
export class FirebasePushNotificationService {
    app = initializeApp(environment.firebase);
    messaging = getMessaging(this.app);

    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public storeDeviceTokenGQL: StoreDeviceTokenGQL,
    ) {}

    saveRegistrationToken() {
        getToken(this.messaging, { vapidKey: environment.vapidKeyFirebase })
            .then((token) => {
                this.registerSw(token);

                // navigator.serviceWorker.getRegistrations().then(function (registrations) {
                //     for (const registration of registrations) {
                //         registration.unregister();
                //     }
                // }).then(() => {
                // });
            })
            .catch((err) => console.log(err));
    }

    registerSw(token) {
        const self = this;
        const swName = 'firebase-messaging-sw.js';

        if ('serviceWorker' in navigator) {
            navigator.serviceWorker
                .register(swName)
                .then(function (registration) {
                    console.log(
                        'Registration successful, scope is:',
                        registration.scope,
                    );

                    // Send token to server
                    self.storeDeviceTokenGQL
                        .mutate({
                            data: { token: token, deviceType: DeviceType.WEB },
                        })
                        .subscribe(() => {});

                    const request = window.indexedDB.open('reviewty', 1);
                    const collectionName = 'notification-params';

                    request.onupgradeneeded = () => {
                        const db = request.result;
                        if (!db.objectStoreNames.contains(collectionName)) {
                            db.createObjectStore(collectionName);
                        }
                    };

                    request.onsuccess = function () {
                        const db = request.result;
                        const notificationParams = db
                            .transaction(collectionName, 'readwrite')
                            .objectStore(collectionName);

                        const params = {
                            token:
                                'Bearer ' +
                                localStorage.getItem(environment.tokenKey),
                            graphqlUri: environment.graphqlUri,
                            created: new Date(),
                        };

                        const result = notificationParams.put(
                            params,
                            'params' as IDBValidKey,
                        );
                        result.onsuccess = function () {
                            console.log(
                                'Notification parameters added to the store',
                                result.result,
                            );
                        };
                        result.onerror = function () {
                            console.log('Error', result.error);
                        };
                    };
                })
                .catch(function (err) {
                    console.log(
                        'Service worker registration failed, error:',
                        err,
                    );
                });
        }
    }
}
