import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs';

import { GraphQLResponse } from '@app/core/services/gql.service';
import {
    GET_CATEGORIES,
    SEARCH_CATEGORIES_BY_NAME,
} from '../gql-queries/category';
import {
    ReadAllCategoriesQuery,
    SearchCategoriesByNameQuery,
} from '@app/common/generated-types';

@Injectable({
    providedIn: 'root',
})
export class CategoryService {
    constructor(private apollo: Apollo) {}

    getCategories(idFilters = null) {
        return this.apollo
            .query<
                GraphQLResponse<
                    'categories',
                    ReadAllCategoriesQuery['categories']
                >
            >({
                query: GET_CATEGORIES,
                variables: {
                    idFilters,
                    where: {
                        status: 'ACTIVE',
                        translationsSome: { language: 'VI' },
                        parent: { id: null },
                    },
                },
            })
            .pipe(map((response) => response.data.categories));
    }

    searchCategoriesByName(name: string) {
        return this.apollo
            .query<
                GraphQLResponse<
                    'categorySearch',
                    SearchCategoriesByNameQuery['categorySearch']
                >
            >({
                query: SEARCH_CATEGORIES_BY_NAME,
                variables: {
                    text: name,
                },
            })
            .pipe(map((response) => response.data.categorySearch));
    }
}
