import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
    EcommerceFlashSaleGQL,
    EcommerceFlashSaleQuery,
} from '@app/common/generated-types';

export enum EActiveTab {
    'INPROGRESS' = 'inprogress',
    'FINISHED' = 'finished',
}

export enum ETypeCreateVoucher {
    APPLY_FOR_ALL = 'APPLY_FOR_ALL',
    FLASH_SALE_ONLY = 'FLASH_SALE_ONLY',
    LIVE_STREAM = 'LIVE_STREAM_ONLY',
}

@Component({
    selector: 'app-root-voucher-list',
    templateUrl: './voucher-list.component.html',
    styleUrl: './voucher-list.component.scss',
})
export class VoucherListComponent implements OnInit {
    loading = false;
    radioValue = 'APPLY_FOR_ALL';
    activeTab: EActiveTab =
        (sessionStorage.getItem('tab') as EActiveTab) || EActiveTab.INPROGRESS;
    EActiveTab = EActiveTab;
    tabs = [
        {
            type: EActiveTab.INPROGRESS,
            name: 'profile.vouchers.inprogress',
            url: '',
        },
        {
            type: EActiveTab.FINISHED,

            name: 'common.ended',
            url: '',
        },
    ];
    vouchers: EcommerceFlashSaleQuery['ecommerceFlashSale'] = [];

    isShowCreateTypeVoucher = false;

    constructor(
        private router: Router,
        private ecommerceFlashSaleGQL: EcommerceFlashSaleGQL,
    ) {}

    ngOnInit(): void {
        this.fetchEcommerceFlashSale(
            this.activeTab === EActiveTab.INPROGRESS ? false : true,
        );
    }

    fetchEcommerceFlashSale(isExpired: boolean, first = 10, skip = 0) {
        this.loading = true;

        this.ecommerceFlashSaleGQL
            .fetch(
                {
                    where: {
                        isExpired: isExpired,
                    },
                    first,
                    skip,
                },
                { fetchPolicy: 'no-cache' },
            )
            .subscribe(({ data, loading }) => {
                this.vouchers = this.vouchers.concat(data.ecommerceFlashSale);

                this.loading = false;
            });
    }

    onScroll() {
        this.loading = true;

        this.fetchEcommerceFlashSale(
            this.activeTab === EActiveTab.FINISHED ? true : false,
            10,
            this.vouchers.length,
        );
    }

    handleChangeTab(tab: EActiveTab) {
        this.vouchers = [];
        const isExpired = tab === EActiveTab.FINISHED ? true : false;
        sessionStorage.setItem('tab', tab);
        if (this.activeTab === tab) return;

        this.activeTab = tab;
        this.fetchEcommerceFlashSale(isExpired);
    }

    handleCreate() {
        this.isShowCreateTypeVoucher = true;
    }

    close() {
        this.isShowCreateTypeVoucher = false;
    }

    confirmTypeCreate() {
        this.router.navigate(['/profile/merchant/vouchers/create'], {
            queryParams: {
                type: this.radioValue,
            },
        });
    }

    handleNavigate() {
        if (this.radioValue === ETypeCreateVoucher.APPLY_FOR_ALL) {
            this.router.navigate(['/profile/merchant/vouchers/create'], {
                queryParams: {
                    type: ETypeCreateVoucher.APPLY_FOR_ALL,
                },
            });
        } else if (this.radioValue === ETypeCreateVoucher.FLASH_SALE_ONLY) {
            this.router.navigate(
                ['/profile/merchant/vouchers/create-flash-sale'],
                {
                    queryParams: {
                        type: ETypeCreateVoucher.FLASH_SALE_ONLY,
                    },
                },
            );
        } else if (this.radioValue === ETypeCreateVoucher.LIVE_STREAM) {
            this.router.navigate(
                ['/profile/merchant/vouchers/create-flash-sale'],
                {
                    queryParams: {
                        type: ETypeCreateVoucher.LIVE_STREAM,
                    },
                },
            );
        }
    }

    handleSuccess(id) {
        this.fetchEcommerceFlashSale(
            this.activeTab === EActiveTab.FINISHED ? true : false,
        );
    }
}
