import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'currencyByLocale' })
export class CurrencyByLocalePipe
    extends CurrencyPipe
    implements PipeTransform
{
    constructor(private translateService: TranslateService) {
        super('en-US', 'USD');
    }

    transform(value: any, args?: any): any {
        const currentLang = this.translateService.currentLang;

        return currentLang !== 'en'
            ? super.transform(Math.abs(value), 'VND', '') + '₫'
            : '$' +
                  super.transform(
                      Math.abs(value / 24515).toFixed(2),
                      'USD',
                      '',
                  );
    }
}
