import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs';

import { GraphQLResponse } from '@app/core/services';
import {
    BrandProductsQuery,
    BrandTypeValue,
    BrandsWithConnectionQuery,
    GetCategoriesQuery,
    ProfileByUsernameQuery,
    ShopStatus,
    ShopsQuery,
    TopHighlightShopsQuery,
} from '@app/common/generated-types';
import {
    BRANDS_WITH_CONNECTIONS,
    BRAND_PRODUCTS,
    GET_CATEGORIES,
    PROFILE_BY_USERNAME,
    SHOP_INFO_BY_ID,
    TOP_HIGHLIGHT_SHOPS,
} from '../gql-queries/nav';

@Injectable({
    providedIn: 'root',
})
export class NavService {
    constructor(private apollo: Apollo) {}

    getCategories() {
        return this.apollo
            .query<
                GraphQLResponse<'categories', GetCategoriesQuery['categories']>
            >({
                query: GET_CATEGORIES,
            })
            .pipe(map((response) => response.data.categories));
    }

    getBrands(type: BrandTypeValue | undefined, first = 20, skip = 0) {
        return this.apollo
            .query<
                GraphQLResponse<'brands', BrandsWithConnectionQuery['brands']>
            >({
                query: BRANDS_WITH_CONNECTIONS,
                variables: {
                    where: {
                        status: 'ACTIVE',
                        type: type !== undefined ? { value: type } : undefined,
                    },
                    orderBy: { id: 'ASC_NULLS_LAST' },
                    skip,
                    first,
                },
            })
            .pipe(map((response) => response.data.brands));
    }

    getShops(first = 20, skip = 0) {
        return this.apollo
            .query<
                GraphQLResponse<
                    'topHighlightShopsV2',
                    TopHighlightShopsQuery['topHighlightShopsV2']
                >
            >({
                query: TOP_HIGHLIGHT_SHOPS,
                variables: {
                    skip,
                    first,
                },
            })
            .pipe(
                map((response) =>
                    response.data.topHighlightShopsV2.filter(
                        (item) => item.shop.status === ShopStatus.APPROVED,
                    ),
                ),
            );
    }

    getShopById(id: number, first = 1, skip = 0) {
        return this.apollo
            .query<GraphQLResponse<'shops', ShopsQuery['shops']>>({
                query: SHOP_INFO_BY_ID,
                variables: {
                    where: {
                        id,
                        first,
                        skip,
                    },
                },
            })
            .pipe(map((response) => response.data.shops[0] ?? null));
    }

    getBrandProducts(brandId: number, first = 20, skip = 0) {
        return this.apollo
            .query<BrandProductsQuery>({
                query: BRAND_PRODUCTS,
                variables: {
                    where: { status: 'ACTIVE', brand: { id: brandId } },
                    orderBy: [{ reviewsConnection: { count: 'DESC' } }],
                    first,
                    skip,
                },
            })
            .pipe(map((response) => response.data));
    }

    getProfileByUsername(account: string) {
        return this.apollo
            .query<GraphQLResponse<'user', ProfileByUsernameQuery['user']>>({
                query: PROFILE_BY_USERNAME,
                variables: {
                    where: { account },
                },
            })
            .pipe(map((response) => response.data.user));
    }
}
