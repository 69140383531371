<div
	#dragScroll
	(mousedown)="handleMouseDown($event)"
	(mouseup)="handleMouseUp()"
	(mousemove)="handleMouseMove($event)"
	style="overflow-x: scroll"
	class="sticky {{ className }} t-0 bg-white hidden-scrollbar"
>
	<ng-content></ng-content>
</div>
