import { Component, Input } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
    selector: 'app-root-rating',
    templateUrl: './rating.component.html',
    styleUrls: ['./rating.component.scss'],
})
export class RatingComponent {
    @Input() rate = 0;
    @Input() rateCount = 0;
    @Input() isShowRatingCount = true;
    @Input() isShowRate = false;

    isMobile: boolean;

    constructor(private deviceService: DeviceDetectorService) {
        this.isMobile = this.deviceService.isMobile();
    }
}
