import { Injectable } from '@angular/core';


@Injectable({ providedIn: 'root' })
export class OpenAppService {

    openApp() {
        const url = 'com.review-ty.reviewty://';
        const fallback = 'itms-appss://apps.apple.com/app/id1468579853';

        let isBlurred = false;
        let didFallback = false;

        window.addEventListener('blur', function () {
            isBlurred = true;
        });

        window.addEventListener('focus', function () {
            isBlurred = false;
            appStoreFallback();
        });

        function appStoreFallback() {
            if (isBlurred || didFallback) return;

            // Since we are using the native deep link, we wrap it in a custom
            // confirm prompt to prevent it from opening immediately.
            if (window.confirm('Open in "App Store"?')) {
                window.top.location = fallback;
            }

            didFallback = true;
        }
        window.top.location = url;
        // setTimeout(function () {
        //     appStoreFallback();
        // }, 500);

    }
}