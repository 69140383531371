import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import {
    AuthErrorCode,
    ChangePasswordGQL,
    CheckInforUserGQL,
    CheckInforUserQuery,
    Exact,
    ForgetPasswordGQL,
    ForgetPasswordMutation,
    GenerateOtpGQL,
    GenerateOtpMutation,
    GetActivityUserGQL,
    GetMeGQL,
    LoginAppleGQL,
    LoginGQL,
    LogoutGQL,
    SignUpInput,
    SignUpNormalizeGQL,
    SignUpNormalizeMutation,
    UserWhereUniqueInput,
    VerifyOtpGQL,
    VerifyOtpQuery,
    ViewProfileUserGQL,
} from '@app/common/generated-types';
import { StoreService } from '@app/core/services/store.service';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { MutationOptionsAlone, QueryOptionsAlone } from 'apollo-angular/types';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { of, switchMap, take } from 'rxjs';
import { JwtService } from './jwt.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(
        private viewProfileUserGQL: ViewProfileUserGQL,
        private loginGQL: LoginGQL,
        private jwtService: JwtService,
        private loginAppleGQL: LoginAppleGQL,
        private getActivityUserGQL: GetActivityUserGQL,
        private getMeGQL: GetMeGQL,
        private logoutGQL: LogoutGQL,
        private router: Router,
        private route: ActivatedRoute,
        private changePasswordGQL: ChangePasswordGQL,
        private msgService: NzMessageService,
        private translateService: TranslateService,
        private store: StoreService,
        private signupGQL: SignUpNormalizeGQL,
        private checkInforUserGQL: CheckInforUserGQL,
        private verifyOtpGQL: VerifyOtpGQL,
        private generateOTPGQL: GenerateOtpGQL,
        private http: HttpClient,
        private forgetPasswordGQL: ForgetPasswordGQL,
        private modalService: NzModalService,
    ) {}

    login(email: string, password: string) {
        return this.loginGQL.mutate({
            email: email.trim(),
            password: password.trim(),
        });
    }

    forgetPassword(
        variables: Exact<{ email: string }>,
        options?: MutationOptionsAlone<
            ForgetPasswordMutation,
            Exact<{ email: string }>
        >,
    ) {
        this.forgetPasswordGQL
            .mutate(variables, options)
            .subscribe(({ data }) => {
                if (data.forgetPassword) {
                    this.modalService.success({
                        nzClosable: false,
                        nzClassName: 'normal-modal',
                        nzTitle: 'Thành công',
                        nzContent: `Mật khẩu mới đã được gửi về email: ${variables.email} của bạn. Hãy kiểm tra hòm thư của mình nhé.`,
                        nzCentered: true,
                        nzOkType: 'primary',
                        nzOkText: 'Trở về trang đăng nhập',
                        nzOnOk: () => {
                            this.router.navigateByUrl('/auth/login');
                        },
                        nzCancelText: 'Hủy',
                    });
                }
            });
    }

    loginWithFB() {
        this.jwtService.login();
    }

    loginWithApple(appleContext) {
        return this.loginAppleGQL.mutate({
            token: appleContext.authorization.id_token,
        });
    }

    getMe() {
        return this.getMeGQL
            .fetch({}, { fetchPolicy: 'no-cache', errorPolicy: 'all' })
            .pipe(
                switchMap((result) => {
                    if (!result?.data?.me) {
                        localStorage.removeItem(environment.tokenKey);

                        return of(null);
                    }

                    localStorage.setItem(
                        'me',
                        JSON.stringify({
                            id: result?.data?.me.id,
                            avatar: result?.data?.me?.avatar?.url,
                            account: result?.data?.me?.account,
                            isShopUser: !!result?.data?.me?.shop,
                        }),
                    );

                    // Handle the result here, e.g., store it in this.store.setUserProfile(result.data.me)
                    this.store.setUserProfile(result?.data?.me);

                    // Return the result if needed
                    return of(result);
                }),
            );
    }
    getActivityUser(id: number) {
        if (!id) return;
        return this.getActivityUserGQL.fetch(
            { userId: id },
            { fetchPolicy: 'no-cache' },
        );
    }

    viewProfileUser(id: number) {
        if (!id) return;
        return this.viewProfileUserGQL.fetch(
            { userId: id },
            { fetchPolicy: 'no-cache' },
        );
    }

    logout() {
        this.store.clear();
        this.logoutGQL
            .mutate({
                token: localStorage.getItem(environment.tokenKey),
            })
            .subscribe(() => {
                localStorage.clear();

                window.location.href = '/profile';
            });

        // this.router.navigateByUrl('/profile');
    }
    changesPassword(
        currentPassword: string,
        newPassword: string,
        callback: () => void,
    ) {
        this.changePasswordGQL
            .mutate({
                newPassword,
                currentPassword,
            })
            .subscribe(({ data, ...results }) => {
                if (data) {
                    this.msgService.success(
                        this.translateService.instant(
                            'notification.create_success',
                        ),
                    );

                    if (callback) {
                        callback();
                    }
                }

                if (results.errors?.[0]?.message) {
                    this.msgService.error(
                        this.translateService.instant(
                            results.errors?.[0].message || 'common.error_500',
                        ),
                    );
                }
            });
    }

    signup(
        variables: Exact<{ data: SignUpInput }>,
        options?: MutationOptionsAlone<SignUpNormalizeMutation, Exact<{ data: SignUpInput }>>,
        returnUrl?: string
    ) {
        return this.signupGQL
            .mutate(variables, options)
            .subscribe(({ data, ...results }) => {
                if (data.signUp.__typename === 'AuthPayload') {
                    localStorage.setItem(
                        environment.tokenKey,
                        data.signUp.token,
                    );
                    this.getMe().subscribe((authMeResult) =>
                        this.store.setUserProfile(authMeResult.data.me),
                    );
                    this.msgService.success(
                        this.translateService.instant(
                            'Tạo tài khoản thành công',
                        ),
                    );
                    this.router.navigateByUrl(returnUrl);
                    // this.router.navigateByUrl('');
                }

                if (results.errors?.[0]?.message) {
                    this.msgService.error(
                        this.translateService.instant(
                            results.errors?.[0].message || 'common.error_500',
                        ),
                    );
                }
            });
    }

    checkInformationByUser(
        variables?: Exact<{ where: UserWhereUniqueInput }>,
        options?: QueryOptionsAlone<
            Exact<{ where: UserWhereUniqueInput }>,
            CheckInforUserQuery
        >,
    ) {
        return this.checkInforUserGQL.fetch(variables, options);
    }
    verifyOTP(
        variables: Exact<{ otp: number; email: string }>,
        callback: () => void,
        options?: QueryOptionsAlone<
            Exact<{ otp: number; email: string }>,
            VerifyOtpQuery
        >,
    ) {
        return this.verifyOtpGQL
            .fetch(variables, options)
            .subscribe(({ data, ...results }) => {
                if (data.verifyOTP) {
                    if (callback) callback();
                } else {
                    this.msgService.error(
                        this.translateService.instant(
                            'Mã OTP không đúng hoặc đã hết hạn',
                        ),
                    );
                }

                if (results.errors?.[0]?.message) {
                    this.msgService.error(
                        this.translateService.instant(
                            results.errors?.[0].message ||
                                'notification.verify_fail',
                        ),
                    );
                }
            });
    }

    generateOTP(
        variables?: Exact<{ email: string }>,
        options?: MutationOptionsAlone<
            GenerateOtpMutation,
            Exact<{ email: string }>
        >,
    ) {
        return this.generateOTPGQL.mutate(variables, options);
    }
}
