import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { InternalNotificationService, RouterService } from '@app/core/services';
import { ContentType } from '@app/home/shared/enums/content-type';
import { Location } from '@angular/common';

@Component({
    selector: 'app-root-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
    isMainContentLoadedChange = false;
    isPostCreateOptionVisible = false;
    isShopUser: boolean = false;

    navItem = NavItem;
    mainPath = '';
    currentLang: string;

    constructor(
        private internalNotificationService: InternalNotificationService,
        private routerService: RouterService,
        private router: Router,
        private translate: TranslateService,
        private _location: Location
    ) {

        this.currentLang = this.translate.currentLang;

        if (localStorage.getItem('me')) {
            this.isShopUser = JSON.parse(localStorage.getItem('me')).isShopUser;
        }

        this.routerService.mainContentLoadedChange.subscribe((value) => {
            this.isMainContentLoadedChange = value;
        });

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.mainPath = this.getCurrentPath();
            }
        });
    }

    ngOnInit(): void {
        this.mainPath = this.getCurrentPath();
    }

    getCurrentPath() {
        return (
            this.router.url.split('?')[0] === '/' ? '/home' : this.router.url
        ).split('/')[1];
    }

    isNavItemActive(navItem: NavItem) {
        const map = {
            home: NavItem.HOME,
            explore: NavItem.EXPLORE,
            uncut: NavItem.SHOP,
            profile: NavItem.ACCOUNT,
        };

        return map[this.mainPath] === navItem;
    }

    showMobileNativeConnectModal() {
        this.internalNotificationService.showMobileConnectDialog(true);
    }

    triggerPostCreateOption(state: boolean): void {
        this.isPostCreateOptionVisible = state;
    }

    renderHomeLink() {
        let homeLink = '/?contentType=ALL';
        const saved = window.sessionStorage.getItem('home-tab');
        if (saved) {
            const key = ContentType[Number(saved)];
            homeLink = key ? '/?contentType=' + key : '/?contentType=ALL';
        }
        this.router.navigateByUrl(homeLink);
    }
}

enum NavItem {
    HOME,
    EXPLORE,
    SHOP,
    ACCOUNT,
}
