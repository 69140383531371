import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';

@Injectable({
    providedIn: 'root',
})
export class IpService {
    constructor(private http: HttpClient) {}

    getIpClient() {
        // return this.http
        // 	.get<{ ip: string }>(`${environment.ipServiceUri}/?format=json`)
        // 	.subscribe((data) => {
        // 		this.callApi(data.ip);
        // 	});
    }

    callApi(ip: string) {
        // call api
    }
}
