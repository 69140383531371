import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { ProfileService } from '@app/profile/services/profile.service';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { map, takeWhile, timer } from 'rxjs';

@Component({
    selector: 'app-root-verify-otp',
    // standalone: true,
    // imports: [OtpInputComponent],
    templateUrl: './verify-otp.component.html',
    styleUrl: './verify-otp.component.scss',
})
export class VerifyOtpComponent implements OnChanges {
    constructor(
        private profileService: ProfileService,
        private router: Router,
        private msgService: NzMessageService,
        private translateService: TranslateService,
    ) {}
    seconds = 120;
    otp;

    // @Input:

    @Input() phoneNumber?: string;

    @Output() isBack = new EventEmitter();

    @Output() successCallback = new EventEmitter();

    timeRemaining$ = timer(0, 1000).pipe(
        map((n) => (this.seconds - n) * 1000),
        takeWhile((n) => n >= 0),
    );
    handleOtpOut(otp: Event) {
        this.otp = otp;
    }

    backClicked() {
        this.isBack.emit();
        // window.history.go(-1);
    }

    tryingToSentOTP() {
        this.profileService.generateSMSOTP(
            {
                phone: this.phoneNumber,
            },
            () => {
                this.msgService.success(
                    this.translateService.instant('Gửi lại mã thành công'),
                );
            },
        );
    }

    verificationOTP() {
        this.successCallback.emit();

        if (this.otp?.length === 0) return;

        this.profileService.verificationOTP(
            {
                type: 'PHONE',
                otp: Number(this.otp),
                value: this.phoneNumber,
            },
            () => {
                this.successCallback.emit();
                // this.router.navigateByUrl(
                // 	'profile/merchant/signup/create-shop-profile',
                // );
            },
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.phoneNumber !== changes.phoneNumber.previousValue) {
            this.timeRemaining$ = timer(0, 1000).pipe(
                map((n) => (120 - n) * 1000),
                takeWhile((n) => n >= 0),
            );
        }
    }
}
