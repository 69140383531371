import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-root-contact',

    templateUrl: './contact.component.html',
    styleUrl: './contact.component.scss',
})
export class ContactComponent {
    bodyContactBlack;
    markdownContent;
    isShowDrawer = false;
    titleDrawer;
    constructor(
        private translateService: TranslateService,
        private sanitizer: DomSanitizer,
        private http: HttpClient,
    ) {
        this.bodyContactBlack = [
            {
                nzSpanPerItem: 12,
                children: [
                    {
                        text: `${this.translateService.instant(
                            'contact.representative_name',
                        )}: PARK JIN KAM`,
                        url: '',
                    },
                    {
                        text: `${this.translateService.instant(
                            'contact.registration_number',
                        )}: 1438601538`,
                        url: '',
                    },
                    // {
                    // 	text: `${this.translateService.instant(
                    // 		'contact.mail',
                    // 	)}: 2023-Seoul Seongdong-1975`,
                    // 	url: '',
                    // 	// Mail order business report number
                    // },
                ],
            },
            {
                nzSpanPerItem: 24,

                children: [
                    {
                        text: 'Room 1301, Myeonseong Square Knowledge Industry Center, 111 Seongsuil-ro, Seongdong-gu, Seoul, 04791',
                        url: '',
                    },
                ],
            },
            {
                nzSpanPerItem: 12,

                children: [
                    {
                        text: `${this.translateService.instant(
                            'contact.ecommerce_permit',
                        )}: 2023-Seoul Seongdong-1975`,
                        url: '',
                        // Mail order business report number
                    },
                    {
                        class: 'link',
                        text: 'Click here for business information',
                        url: '',
                    },
                ],
            },
            {
                nzSpanPerItem: 8,
                children: [
                    {
                        text: 'Tel:070-7997-0124',
                        url: '',
                    },
                    {
                        text: 'Email:cs@vibey.co.kr',
                        url: '',
                    },
                ],
            },
        ];
    }

    // bodyContact = [
    // 	{
    // 		label: 'Track Orders',
    // 		url: '',
    // 	},
    // 	{
    // 		label: 'About Us',
    // 		url: '',
    // 	},
    // 	{
    // 		label: 'Customer Service',
    // 		url: '',
    // 	},
    // 	{
    // 		label: 'Terms of Use',
    // 		url: 'terms-of-use',
    // 	},
    // 	{
    // 		label: 'Wish List',
    // 		url: '',
    // 	},
    // 	{
    // 		label: 'Contact Us',
    // 		url: '',
    // 	},
    // 	{
    // 		label: 'FAQ',
    // 		url: '',
    // 	},
    // 	{
    // 		label: 'Privacy Policy',
    // 		url: '',
    // 	},
    // 	{
    // 		label: 'Reviews',
    // 		url: '',
    // 	},
    // 	{
    // 		label: 'Business Information',
    // 		url: '',
    // 	},
    // 	{
    // 		label: 'Shipping',
    // 		url: '',
    // 	},
    // 	{
    // 		label: '',
    // 		url: '',
    // 	},
    // 	{
    // 		label: '',
    // 		url: '',
    // 	},
    // 	{
    // 		label: 'Hotline',
    // 		url: '',
    // 	},
    // 	{
    // 		label: 'Returns',
    // 		url: '',
    // 	},
    // ];
    bodyContactGrey = [
        {
            title: 'My Account',
            children: [
                {
                    text: 'Track Orders',
                    url: '',
                },
                {
                    text: 'Wish List',
                    url: '',
                },
                {
                    text: 'Reviews',
                    url: '',
                },
            ],
        },
        {
            title: 'Corporate Information',
            children: [
                {
                    text: 'About Us',
                    url: '',
                },
                {
                    text: 'Contact Us',
                    url: '',
                },
                {
                    text: 'Business Information',
                    url: '',
                },
                {
                    text: 'Hotline',
                    url: '',
                },
            ],
        },
        {
            title: 'Help',
            children: [
                {
                    text: 'Customer Service',
                    url: '',
                },
                {
                    text: 'FAQ',
                    url: '',
                },
                {
                    text: 'Shipping',
                    url: '',
                },
                {
                    text: 'Returns',
                    url: '',
                },
            ],
        },
        {
            title: 'Terms & Policies',
            children: [
                {
                    text: 'Terms of Use',
                    url: 'terms-of-use',
                },
                {
                    text: 'Privacy Policy',
                    url: 'privacy-policy',
                },
                {
                    text: 'Return Policy',
                    url: 'return-policy',
                },
            ],
        },
    ];

    handleClickShowPopup(url?: string) {
        if (!url) return;
        this.http
            .get(`/assets/md/${url}.md`, { responseType: 'text' })
            .subscribe((data) => {
                this.markdownContent = data;
            });

        this.titleDrawer = url.replaceAll('-', '_');

        this.isShowDrawer = !this.isShowDrawer;
    }

    getSafeUrl() {
        return this.sanitizer.bypassSecurityTrustResourceUrl(
            'https://sponge-squash-c7f.notion.site/Reviewty-c0f4f01897194d68a9f49cf8ec22d431',
        );
    }
}
