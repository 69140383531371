import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
} from '@angular/common/http';
import { catchError, map, Observable, of } from 'rxjs';

import { environment } from '@environments/environment';
import { Router } from '@angular/router';
import { StoreService } from '../services/store.service';
// import { AuthenticationService } from '@app/core/services';

@Injectable({
    providedIn: 'root',
})
export class JwtInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private store: StoreService,
    ) {
        // private authenticationService: AuthenticationService
        //
    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        // const currentUser = this.authenticationService.currentUserValue;
        // const isLoggedIn = currentUser && currentUser.token;
        // const isApiUrl = request.url.startsWith(environment.apiUrl);
        // if (isLoggedIn && isApiUrl) {
        // 	request = request.clone({
        // 		setHeaders: {
        // 			Authorization: `Bearer ${currentUser.token}`,
        // 		},
        // 	});
        // }
        const token = localStorage.getItem(environment.tokenKey);
        if (
            token &&
            !request.url.includes('amazonaws') &&
            !request.url.includes('/api/')
        ) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`,
                },
            });
        }

        return next.handle(request).pipe(
            map((event: any) => {
                if (event instanceof HttpResponse && event.body.errors) {
                    if (event.body.data) {
                        //handle 200 || 201
                    }

                    if (event.body.errors) {
                        if (
                            event.body.errors?.[0]?.message ===
                            'Access denied! You need to be authorized to perform this action!'
                        ) {
                            // when call api code 401 show popup login
                            this.router.navigateByUrl('/auth/login');
                            // this.store.setShowLogin(true);
                            return event.clone({
                                body: {
                                    data: null,
                                    errors: [
                                        {
                                            code: 'AUTHORIZATION',
                                            message:
                                                'Bạn cần đăng nhập để sử dụng chức năng này',
                                        },
                                    ],
                                },
                            });
                        }

                        return event.clone({
                            body: {
                                data: null,
                                errors: event.body.errors,
                            },
                        });
                    }
                }
                return event;
            }),
            catchError((error) => {
                return of(
                    new HttpResponse({
                        body: {
                            data: null,
                            errors: [
                                {
                                    message:
                                        'An error occurred processing your request',
                                },
                            ],
                        },
                    }),
                );
            }),
        );
    }
}
