import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import {
    DeleteLiveStreamProductGQL,
    EcommercePromotion,
    GetActivityUserQuery,
    IsPinLiveStreamProductGQL,
    LiveStreamGQL,
} from '@app/common/generated-types';
import { StoreService } from '@app/core/services/store.service';
import { CartService } from '@app/home/shared/services/cart.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import {
    ERole,
    ILiveStream,
} from '../live-stream-detail-by-user/live-stream-detail-by-user.component';

@Component({
    selector: 'app-root-drawer-product-in-live',
    templateUrl: './drawer-product-in-live.component.html',
    styleUrl: './drawer-product-in-live.component.scss',
})
export class DrawerProductInLiveComponent implements OnChanges {
    @Input() liveStreamId: number;
    @Input() isShowProductLive: boolean;
    @Input() type: 'pin' | 'pick';

    @Output() toggleShowProductLive = new EventEmitter();
    @Output() showPinnedVoucherEvent = new EventEmitter<EcommercePromotion>();

    renderNavigationMenuInDrawer = [];

    isCurrentShopUserOfLive = false;
    isVoucherListDrawerVisible = false;

    ngOnChanges(changes: SimpleChanges) {
        this.getLiveStream();
    }

    totalProductsInCart = 0;

    role: ERole;
    activeUser: GetActivityUserQuery;
    dataCreateLiveStreamer?: ILiveStream;
    interval;
    productPinned;
    isShowPinned = false;
    timeShowPinnedProduct = 20;

    vouchers: EcommercePromotion[] = [];

    constructor(
        private store: StoreService,
        private getLiveStreamer: LiveStreamGQL,
        private pinLiveStreamProductGQL: IsPinLiveStreamProductGQL,
        private cartService: CartService,
        private nzMessageService: NzMessageService,
        private deleteProduct: DeleteLiveStreamProductGQL,
    ) {}

    // ngOnInit(): void {
    // 	this.getLiveStream();
    // }

    getLiveStream() {
        if (!this?.activeUser?.user?.id) {
            this.store.activeUser$.subscribe((data) => {
                if (data?.user?.id) {
                    this.activeUser = data;
                }
            });
        }
        this.getLiveStreamer
            .fetch(
                {
                    where: {
                        id: this.liveStreamId,
                    },
                },
                {
                    fetchPolicy: 'no-cache',
                },
            )
            .subscribe(({ data }) => {
                // this.dataCreateLiveStreamer = data.liveStream;

                if (data.liveStream.__typename === 'LiveStream') {
                    if (data.liveStream.host.id === this.activeUser?.user?.id) {
                        this.role = ERole.HOST;
                    } else {
                        this.role = ERole.VIEWER;
                    }

                    this.isCurrentShopUserOfLive =
                        data.liveStream?.host?.id === this.activeUser?.user?.id;

                    this.setRenderNavigationMenuInDrawer();

                    this.dataCreateLiveStreamer = data.liveStream;
                    this.handleShowPinProduct();

                    this.getTotalProductsInCart();

                    this.mapVouchers(data);
                }
            });
    }

    mapVouchers(data: any) {
        const vouchersObj = {};
        data.liveStream.liveStreamProducts?.map((liveProduct) => {
            liveProduct.shopProduct?.promotions?.map((promotion) => {
                if (!vouchersObj[promotion.id]) {
                    vouchersObj[promotion.id] = promotion;
                }

                if (!vouchersObj[promotion.id]['productObj']) {
                    vouchersObj[promotion.id]['productObj'] = {};
                }

                vouchersObj[promotion.id]['productObj'][liveProduct.id] =
                    liveProduct;
            });
        });
        this.vouchers = Object.values(vouchersObj).map((item: any) => {
            if (item.pinOnLivestream === true) {
                this.showPinnedVoucherEvent.emit(item);
            }

            return {
                ...item,
                productList: Object.values(item.productObj),
            };
        });
    }

    setRenderNavigationMenuInDrawer() {
        this.renderNavigationMenuInDrawer = [
            {
                text: 'profile.vouchers.voucher_management',
                src: 'assets/icons/live-stream/voucher-management.svg',
                routerLink: this.isCurrentShopUserOfLive
                    ? `/profile/merchant/live-stream-management/demo/${this.liveStreamId}/vouchers-live-stream`
                    : '/profile/merchant/vouchers',
            },
        ];
    }

    handleShowPinProduct() {
        clearInterval(this.interval);
        if (this.dataCreateLiveStreamer.liveStreamProducts?.length === 0) {
            return;
        }

        const productPinned =
            this.dataCreateLiveStreamer.liveStreamProducts.filter(
                (liveStreamProduct) => liveStreamProduct.isCurrentLive === true,
            )[0];

        if (!productPinned?.id) {
            this.productPinned =
                this.dataCreateLiveStreamer.liveStreamProducts[0];
            this.isShowPinned = true;

            this.interval = setInterval(() => {
                this.isShowPinned = false;
                setTimeout(() => {
                    this.isShowPinned = true;
                }, 3 * 1000);
            }, this.timeShowPinnedProduct * 1000);

            setTimeout(() => {
                clearInterval(this.interval);
                // clearInterval(interval);
                this.timeShowPinnedProduct = 10;
                this.interval = setInterval(() => {
                    this.isShowPinned = false;
                    setTimeout(() => {
                        this.isShowPinned = true;
                    }, 3 * 1000);
                }, this.timeShowPinnedProduct * 1000);
            }, this.timeShowPinnedProduct * 1000);
        } else {
            this.isShowPinned = true;
            this.productPinned = productPinned;
        }
    }

    close() {
        this.toggleShowProductLive.emit();
        // this.isShowProductLive = false;
    }

    handleClosePinnedProduct() {
        this.isShowPinned = false;
        clearInterval(this.interval);
    }

    handlePinProduct(product: ILiveStream['liveStreamProducts'][0]) {
        if (product.id && !product.isCurrentLive && this.role === ERole.HOST) {
            this.productPinned = product;
            clearInterval(this.interval);

            this.pinLiveStreamProductGQL
                .mutate({
                    where: {
                        id: product.id,
                        liveStreamId: this.liveStreamId,
                    },
                })
                .subscribe(({ data }) => {
                    // if (data.pinLiveStreamProduct.__typename === 'LiveStreamProduct') {
                    // 	this.dataCreateLiveStreamer = {
                    // 		...this.dataCreateLiveStreamer,
                    // 		liveStreamProducts:
                    // 			this.dataCreateLiveStreamer.liveStreamProducts.map(
                    // 				(liveStreamProduct) => {
                    // 					if (liveStreamProduct.id === product.id) {
                    // 						return {
                    // 							...liveStreamProduct,
                    // 							isCurrentLive: false,
                    // 						};
                    // 					}

                    // 					return {
                    // 						...liveStreamProduct,
                    // 						isCurrentLive: false,
                    // 					};
                    // 				},
                    // 			),
                    // 	};
                    // }

                    this.reloadProducts();
                });
        } else {
            // handle buy now
        }
    }

    reloadProducts() {
        this.getLiveStreamer
            .fetch(
                {
                    where: {
                        id: this.liveStreamId,
                    },
                },
                {
                    fetchPolicy: 'no-cache',
                },
            )
            .subscribe(({ data }) => {
                if (data.liveStream.__typename === 'LiveStream') {
                    this.dataCreateLiveStreamer = data.liveStream;
                    this.handleShowPinProduct();

                    this.mapVouchers(data);
                }
            });
    }

    onVoucherPinned() {
        this.reloadProducts();
    }

    addProductToCart(product: ILiveStream['liveStreamProducts'][0]) {
        this.cartService
            .addProductToCard({
                shopId: product.shopProduct.shop.id,
                variantId: product.shopProduct.productVariants[0].id,
                quantity: 1,
            })
            .subscribe({
                error: () => this.nzMessageService.error('Có lỗi sảy ra.'),
                complete: () => {
                    this.getTotalProductsInCart();

                    this.nzMessageService.success('Đã thêm vào giỏ.');
                },
            });
    }

    getTotalProductsInCart() {
        if (!this.activeUser?.user?.id) {
            return;
        }
        this.cartService.getTotalProductsInCart().subscribe((data) => {
            this.totalProductsInCart = data;
        });
    }

    handleDeleteProduct(product: ILiveStream['liveStreamProducts'][0]) {
        this.deleteProduct
            .mutate({
                where: {
                    id: product.id,
                },
            })
            .subscribe(({ data }) => {
                if (
                    data.deleteLiveStreamProduct.__typename ===
                    'LiveStreamProduct'
                ) {
                    this.dataCreateLiveStreamer = {
                        ...this.dataCreateLiveStreamer,
                        liveStreamProducts:
                            this.dataCreateLiveStreamer.liveStreamProducts.filter(
                                (liveStreamProduct) =>
                                    liveStreamProduct.id !== product.id,
                            ),
                    };
                    this.handleShowPinProduct();

                    this.nzMessageService.success('Xóa thành công');
                }
            });
    }

    toggleVoucherList(isShow: boolean) {
        this.isVoucherListDrawerVisible = isShow;
    }
}
