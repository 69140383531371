import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
    DeleteEcommercePromotionGQL,
    EcommerceFlashSaleQuery,
    TerminateEcommercePromotionGQL,
} from '@app/common/generated-types';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { EActiveTab } from '../voucher-list/voucher-list.component';

@Component({
    selector: 'app-root-voucher-card',

    templateUrl: './voucher-card.component.html',
    styleUrl: './voucher-card.component.scss',
})
export class VoucherCardComponent {
    @Input() data: EcommerceFlashSaleQuery['ecommerceFlashSale'];
    @Input() type: EActiveTab;
    EActiveTab = EActiveTab;
    currentDate = new Date();

    @Output() successCallback = new EventEmitter();
    constructor(
        private deleteEcommercePromotionGQL: DeleteEcommercePromotionGQL,
        private msgService: NzMessageService,
        private translateService: TranslateService,
        private terminatePromotionGQL: TerminateEcommercePromotionGQL,
    ) {}

    handleEdit() {}

    handleTerminatePromotion(id: number) {
        this.terminatePromotionGQL
            .mutate({
                where: {
                    id,
                },
            })
            .subscribe(({ data }) => {
                if (data.terminateEcommercePromotion) {
                    this.msgService.success(
                        this.translateService.instant(
                            'notification.create_success',
                        ),
                    );
                    this.successCallback.emit(id);
                }
            });
    }

    handleDelete(code: string) {
        if (!code) return;
        this.deleteEcommercePromotionGQL
            .mutate({
                where: {
                    code,
                },
            })
            .subscribe(({ data }) => {
                if (data.deleteEcommercePromotion) {
                    this.msgService.success(
                        this.translateService.instant(
                            'notification.create_success',
                        ),
                    );
                    this.successCallback.emit(code);
                    this.data = this.data.filter(
                        (voucher) => voucher.code !== code,
                    );
                }
            });
    }

    handleCheckUpcoming(date) {
        return new Date(date) < new Date() ? true : false;
    }

    handleRouterLink(
        voucher: EcommerceFlashSaleQuery['ecommerceFlashSale'][0],
    ) {
        if (new Date() > new Date(voucher.promotions?.[0]?.fromTime)) {
            return;
        }
        return [voucher.promotions?.[0]?.id];
    }

    isFlashSaleInprogress(
        voucher: EcommerceFlashSaleQuery['ecommerceFlashSale'][0],
    ) {
        const newDate = new Date();
        if (
            new Date(voucher.fromTime) <= newDate &&
            new Date(voucher.toTime) > newDate
        ) {
            return 'inprogress';
        } else if (
            new Date(voucher.fromTime) > newDate &&
            newDate < new Date(voucher.toTime)
        ) {
            return 'upcomming';
        } else {
            return 'ended';
        }
    }

    handleDeleteSuccess(code: string) {
        this.data = this.data.filter((d) => d.code !== code);
    }
}
