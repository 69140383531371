import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ScrollDetectService {
    windowFrameChange: Subject<boolean> = new Subject<boolean>();
    windowFrameStopChange: Subject<boolean> = new Subject<boolean>();

    triggerWindowFrameChange(isChange: boolean) {
        this.windowFrameChange.next(isChange);
    }

    triggerWindowFrameStopChange(isChange: boolean) {
        this.windowFrameStopChange.next(isChange);
    }
}
