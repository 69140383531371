import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'shortenCount' })
export class ShortenCountPipe implements PipeTransform {
    transform(val: number): string {
        if (val >= 1000) {
            return `${(val / 1000).toFixed(1)}K`;
        }
        if (val >= 1000000) {
            return `${(val / 1000000).toFixed(1)}TR`;
        }
        return `${val}`;
    }
}
