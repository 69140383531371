import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class WebsocketService {
    isConnectionEstablished: BehaviorSubject<boolean> = new BehaviorSubject(
        false,
    );
    isEnableReconnect: BehaviorSubject<boolean> = new BehaviorSubject(false);
    messageReceived: Subject<string> = new Subject<string>();

    private socket: WebSocket;

    reconnectTimeout = null;

    constructor() {}

    connect(url: string): void {
        this.socket = new WebSocket(url);

        this.socket.onopen = () => {
            this.isConnectionEstablished.next(true);

            console.log('WebSocket connection established.');
        };

        this.socket.onmessage = (event) => {
            this.messageReceived.next(event.data);
        };

        this.socket.onclose = () => {
            this.isConnectionEstablished.next(false);

            if (this.isEnableReconnect.value) {
                this.reconnectTimeout = setTimeout(function () {
                    this.connect();
                }, 1000);
            } else {
                clearTimeout(this.reconnectTimeout);
            }
            console.log('WebSocket connection closed.');
        };

        this.socket.onerror = (error) => {
            this.closeConnection();
            console.error('WebSocket error:', error);
        };
    }

    sendMessage(message: string): void {
        this.socket.send(message);
    }

    closeConnection(): void {
        this.socket.close();
    }
}
