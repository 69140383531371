import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { SigninComponent } from './signin/signin.component';
import { SharedModule } from '@app/shared/shared.module';
import { AppleCallbackComponent } from './apple-callback/apple-callback.component';
import { SignupComponent } from './signup/signup.component';
import { VerifyOtpComponent } from '@app/profile/profile-main/merchant/signup/verify-otp/verify-otp.component';
import { OtpInputComponent } from '@app/profile/profile-main/merchant/signup/otp-input/otp-input.component';
import { VerifyAccountComponent } from './verify-account/verify-account.component';
import { TellUsAboutUsComponent } from './verify-account/tell-us-about-us/tell-us-about-us.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
@NgModule({
    declarations: [
        SigninComponent,
        SignupComponent,
        AppleCallbackComponent,
        VerifyOtpComponent,
        OtpInputComponent,
        VerifyAccountComponent,
        TellUsAboutUsComponent,
        ForgotPasswordComponent,
    ],
    exports: [SigninComponent, VerifyOtpComponent, OtpInputComponent],
    imports: [
        CommonModule,
        AuthRoutingModule,
        SharedModule,
        MatProgressSpinnerModule,
    ],
})
export class AuthModule {}
