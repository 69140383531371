import { gql } from 'apollo-angular';

export const GET_CATEGORIES = gql`
    query GetCategories(
        $where: CategoryWhereInput
        $orderBy: CategoryOrderByInput
        $offset: Int
        $limit: Int
        $language: LanguageCode
    ) {
        categories(
            where: $where
            orderBy: $orderBy
            first: $limit
            skip: $offset
        ) {
            id
            translations(where: { language: $language }) {
                name
            }
            parent {
                id
            }
            activeLogo {
                url
            }

            inactiveLogo {
                url
            }
        }
    }
`;

export const BRANDS_WITH_CONNECTIONS = gql`
    query brandsWithConnection(
        $where: BrandWhereInput
        $orderBy: BrandOrderByInput
        $skip: Int
        $first: Int
    ) {
        brands(where: $where, orderBy: $orderBy, skip: $skip, first: $first) {
            id
            translations(where: { language: VI }) {
                name
                description
            }
            logoUrl
            isFollowed
            brandAdmins(where: { status: APPROVED }) {
                id
                name
                slogan
                status
                logoUrl
                bannerUrl
                facebookUrl
                coverUrl
                instagramUrl
                webSite
                storyUrl
                brandId
                ownerId
                promotionalProducts {
                    productId
                    brandAdminId
                    productName
                    product {
                        id
                        translations(where: { language: VI }) {
                            name
                        }
                        brand {
                            id
                            translations {
                                name
                            }
                            logoUrl
                        }
                        price
                        priceUnit
                        measure
                        amount
                        amountUnit
                        thumbnail {
                            url
                        }
                        reviewsConnection(
                            where: {
                                isDeleted: false
                                statusIn: [CREATED, APPROVED]
                            }
                        ) {
                            aggregate {
                                count
                                avg {
                                    rate
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const TOP_HIGHLIGHT_SHOPS = gql`
    query topHighlightShops($first: Int, $skip: Int) {
        topHighlightShopsV2(first: $first, skip: $skip) {
            ranking
            rankingChange
            shop {
                id
                name
                cover {
                    url
                }
                user {
                    id
                    avatar {
                        url
                    }
                    followersConnection {
                        aggregate {
                            count
                        }
                    }
                    isOfficial
                }
                status
            }
        }
    }
`;

export const BRAND_PRODUCTS = gql`
    query brandProducts(
        $where: ProductWhereInput
        $orderBy: [ProductOrderByInput!]
        $first: Int
        $skip: Int
    ) {
        products(where: $where, orderBy: $orderBy, first: $first, skip: $skip) {
            id
            translations(where: { language: VI }) {
                name
            }
            thumbnail {
                url
            }
            categories {
                id
            }
            brand {
                id
                logoUrl
                translations(where: { language: VI }) {
                    name
                }
            }
            reviewsConnection(
                where: { statusIn: [APPROVED, CREATED], isDeleted: false }
            ) {
                aggregate {
                    avg {
                        rate
                    }
                    count
                }
            }
            price
            priceUnit
            measure
            amount
            amountUnit
            updatedAt
            bookmarkedByUser
        }
        productsConnection(where: $where) {
            aggregate {
                count
            }
        }
    }
`;

export const SHOP_INFO_BY_ID = gql`
    query shops($where: ShopWhereInput, $first: Int, $skip: Int) {
        shops(where: $where, first: $first, skip: $skip) {
            id
            name
            user {
                id
                account
            }
        }
    }
`;

export const PROFILE_BY_USERNAME = gql`
    query profileByUsername($where: UserWhereUniqueInput!) {
        user(where: $where) {
            id
        }
    }
`;
