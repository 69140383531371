import { Pipe, PipeTransform } from '@angular/core';

import { RouterService } from '../services';

@Pipe({ name: 'strToSlug' })
export class StrToSlugPipe implements PipeTransform {
    constructor(private routerService: RouterService) {}

    transform(str: string): string {
        return this.routerService.parseSlug(str);
    }
}
