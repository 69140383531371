<app-root-product-live-stream-card
    *ngIf="isShowPinned && productPinned"
    [type]="'pin'"
    [productPinned]="[productPinned]"
    (toggleShowProductPinned)="handleClosePinnedProduct()"
>
</app-root-product-live-stream-card>

<nz-drawer
    nzWrapClassName="drawer-full-height"
    [nzHeight]="'513px'"
    [nzClosable]="false"
    [nzVisible]="isShowProductLive"
    [nzPlacement]="'bottom'"
    (nzOnClose)="close()"
>
    <ng-container *nzDrawerContent>
        <div class="bg-white drawer-content-wrapper">
            <div class="relative">
                <div
                    class="p-3 pt-8 pb-5 text-left text-131518 font-semibold"
                    style="font-size: 18px"
                >
                    {{ 'profile.product-live-list' | translate }}
                </div>

                <div class="absolute d-flex">
                    <img
                        src="/assets/icons/live-stream/voucher.svg"
                        class="mr-2"
                        (click)="toggleVoucherList(true)"
                    />
                    <a routerLink="/cart" class="relative cart">
                        <img src="/assets/icons/live-stream/cart-black.svg" />
                        <div class="text-3 cart-count">
                            {{ totalProductsInCart }}
                        </div>
                    </a>
                </div>
            </div>

            <div class="drawer-content">
                <div>
                    <div>
                        <div
                            nz-col
                            nzSpan="8"
                            class="nav-list {{
                                !isCurrentShopUserOfLive && 'd-none'
                            }}"
                            *ngFor="let menu of renderNavigationMenuInDrawer"
                        >
                            <div
                                [routerLink]="menu.routerLink"
                                class="p-3 text-center d-flex flex-col items-center justify-center navigation-menu-item"
                            >
                                <div>
                                    <img src="{{ menu.src }}" />
                                </div>
                                <div>
                                    {{ menu.text | translate }}
                                </div>
                            </div>
                        </div>
                        <div class="voucher-list-wrapper">
                            <div class="voucher-list">
                                <dl
                                    class="voucher-item {{
                                        item.type === 'FREESHIP'
                                            ? 'shipping-voucher'
                                            : 'discount-voucher'
                                    }}"
                                    *ngFor="let item of vouchers"
                                >
                                    <dt>
                                        {{
                                            item.type === 'FREESHIP'
                                                ? 'Giảm' +
                                                  (item.maximumPrice
                                                      | currencyByLocale) +
                                                  ' phí vận chuyển'
                                                : 'Giảm ' + item.amount + '%'
                                        }}
                                    </dt>
                                    <dd>
                                        <span
                                            >Đơn tối thiểu
                                            {{
                                                item.minimumPrice
                                                    | currencyByLocale
                                            }}</span
                                        ><span
                                            >Giảm tối đa
                                            {{
                                                item.maximumPrice
                                                    | currencyByLocale
                                            }}</span
                                        >
                                    </dd>
                                </dl>
                            </div>
                        </div>

                        <div class="w-full">
                            <app-root-product-live-stream-card
                                *ngIf="
                                    dataCreateLiveStreamer?.liveStreamProducts
                                "
                                [type]="'pick'"
                                [role]="role"
                                [productPinned]="
                                    dataCreateLiveStreamer?.liveStreamProducts
                                "
                                (toggleShowProductPinned)="
                                    handleClosePinnedProduct()
                                "
                                (addProductToCartEvent)="
                                    addProductToCart($event)
                                "
                                (handleDeleteProductEvent)="
                                    handleDeleteProduct($event)
                                "
                                (handlePinProductEvent)="
                                    handlePinProduct($event)
                                "
                                [dataCreateLiveStreamer]="
                                    dataCreateLiveStreamer
                                "
                            ></app-root-product-live-stream-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</nz-drawer>

<nz-drawer
    [nzHeight]="'513px'"
    [nzClosable]="false"
    [nzVisible]="isVoucherListDrawerVisible"
    [nzPlacement]="'bottom'"
    (nzOnClose)="toggleVoucherList(false)"
>
    <ng-container *nzDrawerContent>
        <ng-container *ngIf="isCurrentShopUserOfLive">
            <app-root-live-vouchers-visibility-controls
                [liveId]="liveStreamId"
                [vouchers]="vouchers"
                (voucherPinnedEvent)="onVoucherPinned()"
            ></app-root-live-vouchers-visibility-controls>
        </ng-container>
        <ng-container *ngIf="!isCurrentShopUserOfLive">
            <app-root-live-voucher-list
                [vouchers]="vouchers"
            ></app-root-live-voucher-list>
        </ng-container>
    </ng-container>
</nz-drawer>
