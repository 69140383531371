<div class="about-us-wrapper card-3">
	<div class="about-us-wrapper__nav">
		<img
			width="30"
			height="30"
			class="btn-back mr-2"
			src="assets/icons/back-to-home.svg"
			alt="Back to home"
		/>

		Xác thực tài khoản
	</div>
	<div class="about-us-wrapper__content pt-6">
		<form
			class="form-swap-label-required"
			nz-form
			[nzLayout]="'vertical'"
			[formGroup]="verifyAccountForm"
			(ngSubmit)="handleSubmit()"
		>
			<nz-form-item>
				<nz-form-label nzRequired
					>1.How would you like to be called ?
				</nz-form-label>
				<!-- matchFoundAccount -->
				<nz-form-control
					[nzErrorTip]="
						(!verifyAccountForm.controls['name'].untouched &&
						verifyAccountForm.controls['name'].invalid &&
						verifyAccountForm.controls['name']?.getError('required')
							? 'Trường này là bắt buộc nhập và có tối thiểu 3 - 20 ký tự'
							: verifyAccountForm.controls['name']?.getError(
									'noSpaceOrSpecialChar'
							  )
							? 'Không được chứa ký tự đặc biệt và khoảng trắng'
							: verifyAccountForm.get('name')?.getError('matchFoundAccount')
							? 'Tài khoản đã có người sử dụng'
							: ''
						) | translate
					"
				>
					<input formControlName="name" nz-input class="w-full" />
				</nz-form-control>
			</nz-form-item>
			<nz-form-item>
				<nz-form-label nzRequired>2.How old are you ? </nz-form-label>
				<nz-form-control nzErrorTip="Trường này là bắt buộc">
					<div class="rounded-5">
						<nz-select
							nzAllowClear
							style="outline: none"
							formControlName="yearOfbirth"
							[nzShowSearch]="false"
							placeholder="Select a year"
						>
							<nz-option
								*ngFor="let year of years"
								[nzLabel]="year"
								[nzValue]="year"
							></nz-option>
						</nz-select>
					</div>
				</nz-form-control>
			</nz-form-item>
			<nz-form-item>
				<nz-form-label>3.You are ? </nz-form-label>
				<nz-form-control>
					<div nz-row [nzGutter]="[30, 0]">
						<div
							nz-col
							class="cursor-pointer"
							*ngFor="let item of genderList"
							nzSpan="8"
						>
							<div
								(click)="selectGender(item.gender)"
								class="py-4 d-flex flex-col items-center justify-center rounded-3  {{
									verifyAccountForm.get('gender').value === item.gender
										? 'active'
										: 'inactive'
								}}"
							>
								<div class="pb-2">
									<img [src]="item.src" [alt]="item + '-icon'" />
								</div>
								<span>
									{{ 'common.' + item.gender?.toLowerCase() | translate }}
								</span>
							</div>
						</div>
					</div>
				</nz-form-control>
			</nz-form-item>

			<div
				class="d-flex flex-col items-center justify-center form-control-refferal"
			>
				<span
					>Referral ID
					<span
						nzTooltipOverlayClassName="about-us-wrapper-overlay"
						[nzTooltipTrigger]="'click'"
						nzTooltipTitle="Nhập mã giới thiệu để nhận điểm thưởng lần đàu tiên"
						nzTooltipPlacement="topLeft"
						nz-tooltip
					>
						<img src="assets/icons/more-information.svg"
					/></span>
				</span>

				<input
					class="py-2 px-2"
					placeholder="Optionsnal"
					formControlName="referralID"
				/>
			</div>

			<div class="pt-12">
				<button class="btn btn-rectangle__primary">Tiếp theo</button>
			</div>
		</form>
	</div>
</div>
