import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'abbrNumber',
})
export class AbbrNumberPipe implements PipeTransform {
    transform(value: number, ...args: unknown[]): unknown {
        if (!value) return value;
        const SUFFIXES = 'KMBTqQsSOND'; // or whatever you'd like them to be
        const power = Math.floor(Math.log10(value));
        const index = Math.floor(power / 3);
        value = value / Math.pow(10, index * 3); // first 3 digits of the number

        if (value > 100100) {
            return value.toFixed(1) + (SUFFIXES[index - 1] || ''); // default to no suffix if we get an out of bounds index
        }
        return value + (SUFFIXES[index - 1] || ''); // default to no suffix if we get an out of bounds index
    }
}
