<div class="sticky t-0 d-flex items-end p-4">
	<div (click)="handleBack()" class="arrow-left cursor-pointer">
		<img src="assets/icons/left-arrow1.svg" alt="" />
	</div>
	<div class="setting-title text-center text-4 w-full">
		{{ 'auth.forgot-password' | translate }}
	</div>
</div>

<div class="forgot-password-main card-3">
	<form
		nz-form
		nzLayout="vertical"
		[formGroup]="forgotPasswordForm"
		(ngSubmit)="handleSubmit()"
	>
		<!-- [nzErrorTip]="
					(!forgotPasswordForm.controls['email']?.untouched &&
					forgotPasswordForm.controls['email']?.invalid &&
					forgotPasswordForm.controls['email']?.getError('required')
						? 'Trường này là bắt buộc nhập và có tối thiểu 3 - 20 ký tự'
						: forgotPasswordForm.controls['email']?.getError(
								'noSpaceOrSpecialChar'
						  )
						? 'Không được chứa ký tự đặc biệt và khoảng trắng'
						: forgotPasswordForm.get('email')?.getError('matchFoundAccount')
						? 'Tài khoản đã có người sử dụng'
						: ''
					) | translate
				" -->
		<nz-form-item>
			<nz-form-label nzRequired> Email </nz-form-label>
			<!-- matchFoundAccount -->
			<nz-form-control
				[nzErrorTip]="
					validationService.getErrorMessage(forgotPasswordForm.get('email'))
				"
			>
				<nz-input-group [nzPrefix]="suffixTemplate">
					<input formControlName="email" nz-input class="w-full normal-input" />
				</nz-input-group>
				<ng-template #suffixTemplate>
					<img src="assets/icons/mail.svg" />
				</ng-template>
			</nz-form-control>
		</nz-form-item>

		<div class="pt-12">
			<button class="btn btn-rectangle__primary">Gửi</button>
		</div>
	</form>
</div>
