<div class="card-4">
	<div
		class="p-4"
		style="
			box-shadow: 0px 0px 10px 0px rgba(80, 97, 146, 0.09);
			border-radius: 1rem;
		"
	>
		<div class="d-flex justify-between">
			<div>
				<img src="assets/images/flash-sale.png" />
			</div>

			<div>
				{{ voucher.fromTime | date : 'HH:mm dd/MM/YYYY' }} -
				{{ voucher.toTime | date : 'HH:mm dd/MM/YYYY' }}
			</div>
		</div>

		<div class="d-flex py-3 flex-wrap">
			<div *ngFor="let promotion of voucher?.promotions; let index = index">
				<div
					[attr.lengthLeftOfItems]="
						'+' + (voucher?.promotions?.length - 6).toString()
					"
					*ngIf="index < 6"
					class="mr-2 promotion-image {{ index === 6 - 1 ? 'last-item' : '' }}"
					[ngStyle]="{
						'background-image':
							'url(' +
							(promotion.entityObject.__typename === 'ShopProduct'
								? promotion.entityObject.images.urls[0].url
								: '') +
							')'
					}"
				></div>
			</div>
		</div>

		<div class="d-flex justify-between items-center">
			<div class="promotion-sale-percent">
				{{ handleDisplayAmountHighest(voucher) }}
			</div>

			<div class="d-flex items-center">
				<div class="p-2 text-{{ isFlashSaleInprogress(voucher) }}">
					{{ 'common.' + isFlashSaleInprogress(voucher) | translate }}
				</div>

				<div>
					<a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
						<img
							class="actions-btn p-1"
							src="assets/icons/more-actions-horizontal.svg"
							alt="more-actions-btn"
						/>
					</a>
					<nz-dropdown-menu #menu="nzDropdownMenu">
						<ul nz-menu>
							<li
								nz-menu-item
								(click)="handleDelete(voucher.code)"
							>
								<div class="d-flex items-center justify-center">
									<img class="mr-2" src="assets/icons/trash-icon.svg" />
									<span>{{'cart.delete' | translate}}</span>
								</div>
							</li>
						</ul>
					</nz-dropdown-menu>
				</div>
			</div>
		</div>
	</div>
</div>
