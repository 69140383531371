<!-- <div class="card-6 wrapper-contact">
	<div class="wrapper-contact-body-white">
		<div nz-row [nzGutter]="[6, 15]" class="pb-4">
			<div nz-col nzSpan="6">
				<div>
					<span class="title">My Account</span>
				</div>
			</div>
			<div nz-col nzSpan="6" class="title">Corporate Information</div>
			<div nz-col nzSpan="6" class="title">Help</div>
			<div nz-col nzSpan="6" class="title">Terms & Policies</div>
		</div>

		<div nz-row [nzGutter]="[6, 15]" class="">
			<div nz-col nzSpan="6" *ngFor="let item of bodyContact">
				<span class="content">{{ item.label }}</span>
			</div>
		</div>
	</div>
</div> -->

<div class="wrapper-contact-body-white">
    <div class="card-6">
        <div nz-row [nzGutter]="[6, 15]" class="row">
            <div
                nz-col
                [nzXs]="12"
                [nzSm]="
                    parentItem.title === 'My Account'
                        ? 5
                        : parentItem.title === 'Corporate Information'
                          ? 7
                          : 6
                "
                [nzMd]="
                    parentItem.title === 'My Account'
                        ? 5
                        : parentItem.title === 'Corporate Information'
                          ? 7
                          : 6
                "
                [nzLg]="
                    parentItem.title === 'My Account'
                        ? 5
                        : parentItem.title === 'Corporate Information'
                          ? 7
                          : 6
                "
                [nzXl]="
                    parentItem.title === 'My Account'
                        ? 5
                        : parentItem.title === 'Corporate Information'
                          ? 7
                          : 6
                "
                [nzXXl]="
                    parentItem.title === 'My Account'
                        ? 5
                        : parentItem.title === 'Corporate Information'
                          ? 7
                          : 6
                "
                *ngFor="let parentItem of bodyContactGrey"
            >
                <div class="wrapper-contact-body-white__title pt-6">
                    {{ parentItem.title }}
                </div>
                <div nz-row [nzGutter]="[16, 16]">
                    <div
                        nz-col
                        nzSpan="24"
                        *ngFor="let item of parentItem.children"
                    >
                        <span
                            (click)="handleClickShowPopup(item?.url)"
                            class="wrapper-contact-body-white__content cursor-pointer"
                            >{{ item.text }}</span
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="wrapper-contact-body-black">
    <div class="card-6">
        <div nz-row [nzGutter]="[6, 15]" class="">
            <div nz-col nzSpan="24" class="d-flex justify-between">
                <div class="text-4 text-white font-semibold">Vibey Inc.</div>

                <div>
                    <img
                        class="mr-4"
                        src="assets/icons/facebook-logo-dark.svg"
                    />
                    <img src="assets/icons/insta-logo-dark.svg" />
                </div>
            </div>

            <div class="w-full" *ngFor="let parentItem of bodyContactBlack">
                <!-- <span class="wrapper-contact-body-black__title pt-6">
					{{ 'contact.' + parentItem.title | translate }}
				</span> -->
                <div nz-row [nzGutter]="[7]">
                    <div
                        nz-col
                        class="py-2"
                        nzSpan="{{ parentItem?.nzSpanPerItem }}"
                        *ngFor="let item of parentItem.children"
                    >
                        <span
                            class="wrapper-contact-body-black__content {{
                                item?.class
                            }}"
                        >
                            <span *ngIf="item?.class == 'link'">
                                <a href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=1438601538">{{ item.text }}</a>
                            </span>
                            <span *ngIf="item?.class != 'link'">
                                {{ item.text }}
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div [innerHTML]="markdownContent | markdown"></div> -->
<!-- (click)="isDrawerHelper = !isDrawerHelper" -->

<nz-drawer
    nzWrapClassName="drawer-full-height"
    [nzClosable]="false"
    [nzVisible]="isShowDrawer"
    [nzPlacement]="'bottom'"
>
    <ng-container *nzDrawerContent>
        <div class="bg-white relative">
            <div
                class="p-4 d-flex items-center justify-between sticky t-0"
                style="background: white"
            >
                <div class="text-4040 text-6 font-bold">
                    {{ 'common.' + titleDrawer | translate }}
                </div>
                <div class="cursor-pointer" (click)="isShowDrawer = false">
                    <img src="assets/icons/close-filter.svg" />
                </div>
            </div>

            <div class="card-4">
                <markdown [data]="markdownContent"> </markdown>
            </div>
        </div>
    </ng-container>
</nz-drawer>
