import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
    selector: 'app-root-drag-scroll',

    templateUrl: './drag-scroll.component.html',
    styleUrl: './drag-scroll.component.scss',
})
export class DragScrollComponent {
    @ViewChild('dragScroll', { static: false }) dragScroll: ElementRef;

    @Input() className: string = '';
    isDragging = false;
    startX: number;
    startY: number;
    scrollLeft: number;
    scrollTop: number;
    handleMouseDown(event: MouseEvent) {
        this.isDragging = true;
        this.startX = event.clientX;
        this.scrollLeft = this.dragScroll.nativeElement.scrollLeft;
        this.dragScroll.nativeElement.style.cursor = 'grabbing';
        event.preventDefault();
    }

    handleMouseMove(event: MouseEvent) {
        if (!this.isDragging) return;
        const deltaX = event.clientX - this.startX;
        this.dragScroll.nativeElement.scrollLeft = this.scrollLeft - deltaX;
    }

    handleMouseUp() {
        this.isDragging = false;
        this.dragScroll.nativeElement.style.cursor = 'grab';
    }
}
