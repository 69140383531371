import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
    CreateShopV2GQL,
    CreateShopV2Mutation,
    Exact,
    GenerateSmsOtpGQL,
    GenerateSmsOtpMutation,
    ShopCreateInput,
    ShopMutationErrorCode,
    ShopUpdateInput,
    ShopWhereUniqueInput,
    UpdateShopV2GQL,
    UpdateShopV2Mutation,
    UpdateUserInformationGQL,
    UpdateUserInformationMutation,
    UserUpdateInput,
    UserWhereUniqueInput,
    VerifyShopOtpGQL,
    VerifyShopOtpQuery,
} from '@app/common/generated-types';
import { TranslateService } from '@ngx-translate/core';
import { MutationOptionsAlone, QueryOptionsAlone } from 'apollo-angular/types';
import { NzMessageService } from 'ng-zorro-antd/message';

@Injectable({
    providedIn: 'root',
})
export class ProfileService {
    constructor(
        private generateOTP: GenerateSmsOtpGQL,
        private router: Router,
        private msgService: NzMessageService,
        private translateService: TranslateService,
        private verifyOTP: VerifyShopOtpGQL,
        private createShopProfileGQL: CreateShopV2GQL,
        private updateShopProfileGQL: UpdateShopV2GQL,
        private updateUserInformationGQL: UpdateUserInformationGQL,
    ) {}

    updateUserInformation(
        variables: Exact<{
            where: UserWhereUniqueInput;
            data: UserUpdateInput;
        }>,
        callback: () => void,
        options?: MutationOptionsAlone<
            UpdateUserInformationMutation,
            Exact<{ where: UserWhereUniqueInput; data: UserUpdateInput }>
        >,
    ) {
        return this.updateUserInformationGQL.mutate(variables, options);
        // .subscribe(({ data, ...results }) => {
        // 	if (data) {
        // 		this.msgService.success(
        // 			this.translateService.instant('Sửa thông tin thành công'),
        // 		);

        // 		if (callback) {
        // 			callback();
        // 		}
        // 	}

        // 	if (results.errors?.[0].message) {
        // 		this.msgService.error(
        // 			this.translateService.instant(
        // 				results.errors?.[0].message || 'common.error_500',
        // 			),
        // 		);
        // 	}
        // });
    }
    generateSMSOTP(
        variables: Exact<{ phone: string }>,
        callback: (phone: string) => void,
        options?: MutationOptionsAlone<
            GenerateSmsOtpMutation,
            Exact<{ phone: string }>
        >,
    ) {
        return this.generateOTP
            .mutate(variables, options)
            .subscribe((results) => {
                if (results.data) {
                    if (callback) callback(variables.phone);
                }

                if (results.errors?.[0].message) {
                    this.msgService.error(
                        this.translateService.instant(
                            results.errors?.[0].message || 'common.error_500',
                        ),
                    );
                }
            });
    }

    verificationOTP(
        variables: Exact<{ type: string; value: string; otp: number }>,
        callback: () => void,
        options?: QueryOptionsAlone<
            Exact<{ type: string; value: string; otp: number }>,
            VerifyShopOtpQuery
        >,
    ) {
        this.verifyOTP.fetch(variables, options).subscribe((results) => {
            if (results.data.verifyShopOTP) {
                this.msgService.success(
                    this.translateService.instant(
                        'notification.create_success',
                    ),
                );

                if (callback) callback();
            } else {
                this.msgService.error(
                    this.translateService.instant(
                        'Mã OTP không đúng hoặc đã hết hạn',
                    ),
                );
            }

            if (results.errors?.[0].message) {
                this.msgService.error(
                    this.translateService.instant(
                        results.errors?.[0].message ||
                            'notification.verify_fail',
                    ),
                );
            }
        });
    }

    createShopProfile(
        variables: Exact<{
            data: ShopCreateInput;
        }>,
        callback: () => void,
        options?: MutationOptionsAlone<
            CreateShopV2Mutation,
            Exact<{
                data: ShopCreateInput;
            }>
        >,
    ) {
        this.createShopProfileGQL
            .mutate(variables, options)
            .subscribe(({ data, ...results }) => {
                if (data) {
                    if (data.createShopV2.__typename === 'ShopMutationError') {
                        if (
                            data.createShopV2.code ===
                            ShopMutationErrorCode.NAME_ALREADY_IN_USE
                        ) {
                            this.msgService.error(
                                this.translateService.instant(
                                    'Tên này đã có người sử dụng',
                                ),
                            );
                        }
                        return;
                    }
                    this.msgService.success(
                        this.translateService.instant(
                            'Gửi yêu cầu thành công, vui lòng liên hệ với admin để được duyệt',
                        ),
                    );

                    if (callback) {
                        callback();
                    }
                }

                if (results.errors?.[0].message) {
                    this.msgService.error(
                        this.translateService.instant(
                            results.errors?.[0].message || 'common.error_500',
                        ),
                    );
                }
            });
    }

    updateShopProfile(
        variables: Exact<{
            data: ShopUpdateInput;
            where: ShopWhereUniqueInput;
        }>,
        callback: () => void,
        options?: MutationOptionsAlone<
            UpdateShopV2Mutation,
            Exact<{ data: ShopUpdateInput; where: ShopWhereUniqueInput }>
        >,
    ) {
        this.updateShopProfileGQL
            .mutate(variables, options)
            .subscribe(({ data, ...results }) => {
                if (data) {
                    if (data.updateShopV2.__typename === 'ShopMutationError') {
                        if (
                            data.updateShopV2.code ===
                            ShopMutationErrorCode.NAME_ALREADY_IN_USE
                        ) {
                            this.msgService.error(
                                this.translateService.instant(
                                    'Tên này đã có người sử dụng',
                                ),
                            );
                        }
                        return;
                    }
                    this.msgService.success(
                        this.translateService.instant(
                            'Sửa thông tin shop thành công',
                        ),
                    );

                    if (callback) {
                        callback();
                    }
                }

                if (results.errors?.[0].message) {
                    this.msgService.error(
                        this.translateService.instant(
                            results.errors?.[0].message || 'common.error_500',
                        ),
                    );
                }
            });
    }
}
