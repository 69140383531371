import { Component } from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { ForgetPasswordGQL } from '@app/common/generated-types';
import { ValidationService } from '@app/core/services/validation.service';
import { debounceTime, distinctUntilChanged, map } from 'rxjs';
import { AuthService } from '../shared/services/auth.service';

@Component({
    selector: 'app-root-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrl: './forgot-password.component.scss',
})
export class ForgotPasswordComponent {
    forgotPasswordForm: FormGroup;
    constructor(
        private fb: FormBuilder,
        private authService: AuthService,
        public validationService: ValidationService,
    ) {
        this.forgotPasswordForm = this.fb.group({
            email: [
                '',
                [
                    Validators.email,
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(20),
                    this.matchEmail.bind(this),
                ],
            ],
        });
    }

    matchEmail(control: FormControl) {
        if (this.forgotPasswordForm && control.value) {
            control.valueChanges
                .pipe(debounceTime(500), distinctUntilChanged())
                .subscribe(() => {
                    if (control.value) {
                        this.authService
                            .checkInformationByUser({
                                where: {
                                    email: control.value,
                                },
                            })
                            .subscribe(({ data }) => {
                                if (!data?.user?.id) {
                                    control.setErrors({
                                        notFoundEmail: true,
                                    });
                                }
                            });
                    }
                });
        }
    }
    handleBack() {}

    handleSubmit() {
        const { email } = this.forgotPasswordForm.value;

        if (this.forgotPasswordForm.invalid) {
            Object.values(this.forgotPasswordForm.controls).forEach(
                (control) => {
                    control.markAsDirty();
                    control.updateValueAndValidity();
                },
            );

            return;
        }

        this.authService.forgetPassword({
            email,
        });
    }
}
