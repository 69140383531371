<div class="content" [class.collapsed]="isCollapsed">
	<ng-content></ng-content>
</div>
<div
	*ngIf="showReadMore"
	class="cursor-pointer text-404040 font-medium"
	(click)="isCollapsed = !isCollapsed"
>
	{{ isCollapsed ? 'Xem' : 'Đóng' }}
</div>
