import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable, of } from 'rxjs';

interface UploadImageParams {
    url?: string;
    file: File;
}

type UploadImageObservable = Observable<{
    id?: string;
    name?: string;
    url: string;
}>;

@Injectable()
export class FileService {
    constructor(private httpClient: HttpClient) {}

    getBase64(
        file: File,
        callback: (
            base64: string,
            width: number,
            height: number,
            name: string,
            type?: string,
        ) => void,
    ): void {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            const image = new Image();
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            image.src = reader.result!.toString();
            image.onload = () => {
                callback(
                    reader.result!.toString(),
                    image.width,
                    image.height,
                    file.name,
                );
            };
        });

        if (file.type.includes('video')) {
            reader.onload = (event) => {
                callback(
                    (<FileReader>event.target).result!.toString(),
                    0,
                    0,
                    file.name,
                );
            };
        }

        reader.readAsDataURL(file);
    }

    uploadImage({
        url,
        file,
        ...rest
    }: UploadImageParams): UploadImageObservable {
        const clientUrl =
            url || `${environment.apiUri}/single-upload?useWatermark=false`;

        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            return this.httpClient.post<{ url: string }>(clientUrl, formData);
        } else {
            return of({
                id: '',
                name: '',
                url: '',
            });
        }
    }

    uploadVideo(url: string, file: File) {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/octet-stream');

        const params = new HttpParams();

        return this.httpClient.put(url, file, {
            headers,
            params,
        });
    }

    uploadMultipleImage(data: NzUploadFile[]) {
        const clientUrl = `${environment.apiUri}/multiple-upload`;
        const formData = new FormData();

        if (data.length) {
            data.forEach((file) => {
                formData.append('file', file as any);
            });
            return this.httpClient.post(clientUrl, formData);
        } else {
            return of([]);
        }
    }
}
